<template>
    <div id="EmailEditorApp">
      <div class="container min-h-screen">
        <div id="bar" class="my-4">
          <h1>Vue Email Editor</h1>
          <div class="flex justify-end gap-2">
            <button v-on:click="saveDesign" class="px-3 py-2 border bg-primary rounded-md">Save Design</button>
            <button v-on:click="exportHtml" class="px-3 py-2 border bg-primary rounded-md">Export HTML</button>
          </div>
        </div>
  
        <EmailEditor
          ref="emailEditor"
          v-on:load="editorLoaded"
          v-on:ready="editorReady"
        />
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios"
  import { EmailEditor } from 'vue-email-editor';
  
    export default {
      name: 'EmailEditorApp',
      components: {
        EmailEditor,
      },
      mounted(){
        $('iframe').addClass('min-h-[100vh]')
      
      },
      methods: {
        // called when the editor is created
        editorLoaded() {
          console.log('editorLoaded');
          this.$refs.emailEditor.editor.registerCallback('image', function(file, done) {
              
            var data = new FormData()
            data.append('file_uploaded', file.attachments[0]);
              axios.post("https://demo.api.maggana.com/mdfile_image_upload/?Key=aa0d43ff5ffcd4aeba204e7c494a0294e6bbae82",data)
              .then(resp=>{
                  done({url:[resp.data.file_url]})
                 
              })
              setTimeout(() => {
              console.log(document.querySelector('.form-control'))
            }, 3500)
          });

          // Pass the template JSON here
          // this.$refs.emailEditor.editor.loadDesign({});
        },
        // called when the editor has finished loading
        editorReady() {
          console.log('editorReady');

          document.querySelector('iframe').style.height="100vh";
        //   
        // document.querySelector('iframe .blockbuilder-branding').style.display="none";
        },
        saveDesign() {
          this.$refs.emailEditor.editor.saveDesign((design) => {
            console.log('saveDesign', design);
          });
        },
        exportHtml() {
          this.$refs.emailEditor.editor.exportHtml((data) => {
            console.log('exportHtml', data);
          });
        },
      },
    };
  </script>