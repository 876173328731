<template lang="">
  <div class="w-full h-full bg-white">
    <div class="maxwidthscreen w-full h-full flex mx-auto">
      <!-- left Navigation -->
      <LeftNavigation currentTap="home" />

      <!-- Dashboard -->
      <div
        class="containerHead w-full flex flex-col gap-3 max-h-screen overflow-y-auto"
      >
        <!-- 1 -->
        <div
          class="w-full h-[80px] bg-white p-4 shadow-lg flex justify-between items-center gap-5 sticky top-0 z-50 block md:hidden"
        >
          <div class="visible md:invisible">
            <img src="../../assets/logo-black.png" class="w-[100px]" />
          </div>
          <div class="flex items-center justify-end">
            <button
              class="block md:hidden px-3 text-black"
              type="button"
              id="navBarIcon"
            >
              <i class="fa-solid fa-bars text-3xl"></i>
            </button>
          </div>
        </div>
        <!-- 2 -->
        <div class="flex flex-col xl:flex-row gap-4 mt-6">
          <div
            v-if="this.test_status != true"
            class="w-full xl:w-8/12 px-4 flex flex-col justify-center"
          >
            <div class="block sm:hidden w-full relative">
              <div class="flex items-center justify-center">
                <img
                  src="../../assets/banner4Mobile.png"
                  class="rounded-xl shadow-xl h-full object-center xl:object-left object-cover"
                />
                <div class="absolute top-[4%]">
                  <div class="text-center font-semibold text-lg">
                    <h2 class="text-5xl font-bold text-white">
                      <span class="text-primary">LO</span>TS
                    </h2>
                    <h4 class="text-primary text-sm">Learn.Online</h4>
                    <p class="text-white text-base">Test for Scholarship</p>
                    <h3 class="text-white text-base">
                      Choose what's right for you,
                    </h3>
                    <p class="text-primary text-base">Right here!</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="relative w-full hidden sm:block">
              <img
                src="../../assets/studentDashboardBg_new.jpg"
                class="rounded-xl shadow-xl h-full object-center xl:object-left object-cover"
              />
              <div class="">
                <router-link :to="{ name: 'MCQ' }">
                  <button
                    class="bg-primary px-4 py-2 text-xs md:text-sm lg:text-base md:px-1 md:py-1 lg:px-3 lg:py-1.5 rounded-md font-semibold absolute left-[62%] top-[73%]"
                  >
                    TAKE A TEST
                  </button>
                </router-link>
              </div>
            </div>
          </div>

          <div v-else class="w-full xl:w-8/12 px-4 relative min-h-[350px]">
            <div
              v-if="this.percentage_val != ''"
              class="w-full h-full rounded-xl shadow-xl flex flex-col gap-y-10 sm:flex-row p-4 border"
            >
              <div
                class="w-full sm:w-6/12 flex flex-col justify-center gap-5 z-10"
              >
                <h1
                  class="text-primary text-3xl sm:text-4xl md:text-3xl lg:text-5xl xl:text-4xl font-black"
                >
                  Congratulations
                </h1>
                <p
                  class="text-center text-xl sm:text-2xl md:text-xl lg:text-3xl xl:text-2xl"
                >
                  Just One more step... <br />
                  <strong class="text-base">
                    Select University & Program
                  </strong>
                </p>
                <div>
                  <router-link :to="{ name: 'programs' }">
                    <button
                      class="px-3 py-2 text-gray-700 font-semibold bg-primary rounded-md text-sm"
                    >
                      Select Now
                    </button>
                  </router-link>
                </div>
              </div>

              <div
                class="w-full sm:w-6/12 relative flex flex-col justify-center"
              >
                <img src="../../assets/scholarship-bg.png" />
                <strong
                  class="absolute top-[45%] -translate-y-[45%] left-1/2 -translate-x-1/2 font-black text-6xl sm:text-5xl md:text-4xl lg:text-6xl xl:text-5xl"
                  >{{ this.percentage_val }}%</strong
                >
                <!-- <router-link :to="{ name: 'programs' }">
                  <button
                    v-if="this.percentage_val >= 10"
                    class="text-black bg-primary text-sm px-5 py-2 rounded-md absolute right-0 bottom-0"
                  >
                    Next
                  </button>
                </router-link> -->
              </div>
            </div>
            <!-- else -->
            <div
              v-else
              class="w-full h-full rounded-xl shadow-xl flex flex-col gap-y-10 sm:flex-row p-4 border"
            >
              <div
                v-if="this.reapplyUser.length == 0"
                class="w-full flex flex-col justify-center gap-5 z-10"
              >
                <p
                  class="text-center text-xl sm:text-2xl md:text-xl lg:text-3xl xl:text-2xl lg:w-[80%] mx-auto"
                >
                  <strong>
                    You were so close to earning a scholarship! Don't worry! You
                    can reapply for the scholarship now,
                  </strong>
                </p>

                <div>
                  <button
                    v-if="this.reapplyUser.is_completed != true"
                    @click="reapply"
                    class="px-3 py-2 text-gray-700 font-semibold bg-primary rounded-md text-sm"
                  >
                    Apply now
                  </button>
                </div>
              </div>

              <!-- Else -->
              <div v-else class="w-full flex flex-col justify-center">
                <div class="block sm:hidden w-full relative h-full">
                  <div class="flex items-center justify-center">
                    <img
                      src="../../assets/banner4Mobile.png"
                      class="rounded-xl shadow-xl h-full object-center xl:object-left object-cover"
                    />
                    <div class="absolute top-[4%]">
                      <div class="text-center font-semibold text-lg">
                        <h2 class="text-5xl font-bold text-white">
                          <span class="text-primary">LO</span>TS
                        </h2>
                        <h4 class="text-primary text-sm">Learn.Online</h4>
                        <p class="text-white text-base">Test for Scholarship</p>
                        <h3 class="text-white text-base">
                          Choose what's right for you,
                        </h3>
                        <p class="text-primary text-base">Right here!</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="relative w-full hidden sm:block">
                  <img
                    src="../../assets/studentDashboardBg_new.jpg"
                    class="rounded-xl shadow-xl h-full object-center xl:object-left object-cover"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Pop Up for Retest Student -->
          <div
            v-if="this.retestPopUp"
            class="fixed top-0 left-0 bg-black bg-opacity-75 w-full h-full z-[1000] flex items-center justify-center"
          >
            <div
              class="max-w-xl w-full h-[280px] bg-white mx-auto p-6 rounded-lg flex flex-col justify-center relative"
            >
              <button
                class="absolute top-4 right-6 font-semibold"
                @click="this.getReapply"
              >
                X
              </button>
              <h1
                class="text-primary text-3xl sm:text-4xl md:text-3xl lg:text-5xl xl:text-4xl font-black"
              >
                Great!
              </h1>
              <p
                class="text-sm sm:text-2xl md:text-lg lg:w-[80%] mx-auto font-bold"
              >
                We're glad to see that you're interested in applying again. our
                counselor will get in touch with you shortly.
              </p>
            </div>
          </div>

          <div
            class="w-full xl:w-4/12 pr-2 flex flex-col justify-between gap-4"
          >
            <div
              class="px-3 py-3 rounded-xl text-center shadow-xl mx-2"
              :class="[
                test_status == true ? 'bg-primary' : 'bg-red-700 text-white',
              ]"
            >
              <h3>
                <span class="font-semibold text-base">Current Status :</span>
                <span class="text-sm" v-if="this.test_status">
                  Test Taken
                </span>
                <span class="text-sm" v-else> Test Not Taken </span>
              </h3>
            </div>

            <div class="p-5 bg-white rounded-xl shadow-xl flex flex-col gap-4">
              <h3 class="text-start font-semibold text-base">Next Step :</h3>

              <div class="flex flex-col gap-3">
                <router-link :to="{ name: 'MCQ' }">
                  <div
                    class="relative flex justify-start items-center gap-4 border rounded-xl pr-7"
                  >
                    <div
                      class="flex items-center justify-center bg-primary p-2 rounded-xl"
                    >
                      <img src="../../assets/code.png" class="w-[30px]" />
                    </div>
                    <h4
                      class="font-semibold text-sm text-start whitespace-nowrap text-ellipsis overflow-hidden"
                    >
                      Take the test now
                    </h4>
                    <img
                      v-if="this.test_status"
                      src="../../assets/correct.png"
                      class="w-4 absolute top-1/2 -translate-y-1/2 right-[7px]"
                    />
                  </div>
                </router-link>
                <div
                  class="relative flex justify-start items-center gap-4 border rounded-xl pr-7"
                >
                  <div
                    class="flex items-center justify-center bg-primary p-2 rounded-xl"
                  >
                    <img src="../../assets/scholarship.png" class="w-[30px]" />
                  </div>
                  <h4
                    class="font-semibold text-sm text-start whitespace-nowrap text-ellipsis overflow-hidden"
                  >
                    Avail the scholarship
                  </h4>
                  <img
                    v-if="this.test_status"
                    src="../../assets/correct.png"
                    class="w-4 absolute top-1/2 -translate-y-1/2 right-[7px]"
                  />
                </div>

                <router-link :to="{ name: 'programs' }">
                  <div
                    class="relative flex justify-start items-center gap-4 border rounded-xl pr-7"
                  >
                    <div
                      class="flex items-center justify-center bg-primary p-2 rounded-xl"
                    >
                      <img src="../../assets/school.png" class="w-[30px]" />
                    </div>
                    <h4
                      class="font-semibold text-sm text-start whitespace-nowrap text-ellipsis overflow-hidden"
                    >
                      Choose University & Program
                    </h4>
                  </div>
                </router-link>

                <div
                  class="relative flex justify-start items-center gap-4 border rounded-xl pr-7"
                >
                  <div
                    class="flex items-center justify-center bg-primary p-2 rounded-xl"
                  >
                    <img src="../../assets/entroll.png" class="w-[30px]" />
                  </div>
                  <h4
                    class="font-semibold text-sm text-start whitespace-nowrap text-ellipsis overflow-hidden"
                  >
                    Complete your enrolment
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 3 -->
        <div class="mx-4 bg-white rounded-xl shadow-xl relative">
          <h2 class="text-lg font-semibold p-4 text-start">
            Recommended <span class="text-primary">Universities</span>
          </h2>
          <div :class="this.slick_var" class="w-full px-12 mb-5">
            <div
              v-for="university in universitiesList"
              :key="university"
              class="relative border-2 rounded-lg shadow-lg mx-2 !flex items-center justify-center"
            >
              <img
                :src="university.logo"
                width="200"
                class="h-24 object-contain py-2"
              />
            </div>
          </div>
          <!-- <div v-if="this.test_status != true" class="absolute bg-white top-0 left-0 w-full h-full bg-opacity-70">

                    </div> -->
        </div>
        <!-- 4 -->
        <div class="flex flex-col xl:flex-row gap-4 relative">
          <!-- <div v-if="this.test_status != true" class="absolute bg-white top-0 left-0 w-full h-full bg-opacity-70">
                        <div class="border px-4 py-2 bg-red-700 text-white inline-block mt-4">
                            Take a test to activate the dashboard
                        </div>
                    </div> -->
          <!-- 1 -->
          <div class="w-full shadow-xl mx-2 rounded-md border border-gray-100">
            <h2 class="text-lg font-semibold p-4 text-start">
              Trending <span class="text-primary">Programs</span>
            </h2>
            <div class="containerHead overflow-auto h-[475px]">
              <table class="min-w-full">
                <thead class="bg-white px-2">
                  <tr class="">
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-2 text-start py-4"
                    >
                      University
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-2 text-start py-4"
                    >
                      Program
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-2 text-start py-4"
                    >
                      Elective
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-2 text-start py-4"
                    >
                      Duration
                    </th>
                  </tr>
                </thead>
                <tbody class="">
                  <tr
                    v-for="(el, index) in this.electiveList"
                    :key="el"
                    class="border-b border-gray-100"
                  >
                    <td
                      class="text-sm text-gray-900 font-light px-2 text-start py-4"
                    >
                      <img
                        :src="el.university.logo"
                        class="object-contain h-16"
                      />
                    </td>
                    <td
                      class="text-sm text-gray-900 font-light px-2 text-start py-4 capitalize"
                    >
                      {{ el.program.name }}
                    </td>
                    <td
                      class="text-sm text-gray-900 font-light px-2 text-start py-4 capitalize"
                    >
                      {{ el.elective.electives }}
                    </td>
                    <td
                      class="text-sm text-gray-900 font-light px-2 text-start py-4"
                    >
                      {{ el.elective.duration }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- 2 -->
          <div
            class="w-full xl:w-1/2 shadow-xl mx-2 rounded-md border border-gray-100 h-full hidden"
          >
            <div class="flex justify-between items-center px-4">
              <h2 class="text-lg font-semibold p-4 text-start">
                Your <span class="text-primary">Webinars</span>
              </h2>
              <!-- <router-link :to="{ name: 'Webinars' }"> -->
              <button class="text-sm text-sky-700">See All</button>
              <!-- </router-link> -->
            </div>
            <div
              class="containerHead h-[475px] overflow-y-auto flex flex-col gap-2 px-2"
            >
              <template v-for="i in 5" :key="i">
                <div
                  class="flex justify-center items-center gap-4 h-[180px] border border-gray-100 shadow-md"
                >
                  <div
                    class="w-[100px] rounded-md border border-gray-200 h-full py-4"
                  >
                    <img
                      src="../../assets/man.png"
                      class="w-[80px] rounded-md"
                    />
                  </div>
                  <div class="w-full text-start flex flex-col gap-1">
                    <h3 class="">
                      Topic :
                      <span class="font-medium text-sm">
                        Creating Digital Biomarkers
                      </span>
                    </h3>
                    <p class="">
                      Speaker :
                      <span class="font-medium text-sm"> Manorajan </span>
                    </p>
                    <p>
                      Status :
                      <span class="font-medium text-sm"> Up coming </span>
                    </p>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import LeftNavigation from "@/components/leftNavigation.vue";
export default {
  data() {
    return {
      test_status: false,
      slick_var: "responsiveSilk",
      percentage_val: "",
      electiveList: [],
      universitiesList: [],
      retestPopUp: false,
      reapplyUser: [],
    };
  },
  components: {
    LeftNavigation,
  },
  computed: mapGetters(["userInfo", "isAuthenticated", "userProfiileInfo"]),
  async created() {
    this.collectMcqInfo();
    this.programCall();
    await this.universityList();
    this.slickCall();
    this.getReapply();
  },
  mounted() {},
  methods: {
    getReapply() {
      this.retestPopUp = false;
      axios
        .get(
          `${process.env.VUE_APP_API}/re-apply/list/?user_id=${this.userInfo.data.user.id}`
        )
        .then((resp) => {
          console.log(resp);
          if (resp.data.status == 200) {
            this.reapplyUser = resp.data.data;
          } else {
            console.log("No details");
          }
        });
    },
    reapply() {
      let dataJson = {
        user: this.userInfo.data.user.id,
      };
      axios
        .post(`${process.env.VUE_APP_API}/re-apply/post/`, dataJson)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.retestPopUp = true;
          } else {
            console.log("try again");
          }
        });
    },
    slickCall() {
      $("#navBarIcon").on("click", function () {
        $("#navbarContainer").toggleClass("open");
      });

      $(`.${this.slick_var}`).slick({
        infinite: true,
        autoplay: true,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    },
    async universityList() {
      await axios
        .get(`${process.env.VUE_APP_API}/student/universities_all/list/`)
        .then((resp) => {
          this.universitiesList = resp.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async programCall() {
      await axios
        .get(`${process.env.VUE_APP_API}/student/universities/test/list/`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.electiveList = resp.data.data;
            this.electiveList = this.electiveList.sort(
              () => Math.random() - 0.5
            );
          } else {
            this.electiveList = "";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async collectMcqInfo() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/quiz-taker-details/list/?user=${this.userInfo.data.user.id}`
        )
        .then((resp) => {
          if (resp.data.status == 200) {
            this.test_status = true;
            if (resp.data.data[0].completed) {
              this.collectPercentageInfo();
            }
          } else {
            this.test_status = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async collectPercentageInfo() {
      await axios
        .get(
          // `${process.env.VUE_APP_API}/assessment-quiztaker/list/${this.userInfo.data.user.id}/`
          `${process.env.VUE_APP_API}/reservation-booking/list/?user__id=${this.userInfo.data.user.id}`
        )
        .then((resp) => {
          if (resp.data.status == 200) {
            this.percentage_val = resp.data.data[0].reservation.scholarship;
          } else {
            this.percentage_val = "";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style>
.responsiveSilk .slick-prev:before {
  content: url(../../assets/slide-left.png);
}

.responsiveSilk .slick-next:before {
  content: url(../../assets/slide-right.png);
}

#navbarContainer.open {
  transform: translateX(0) !important;
}
</style>
