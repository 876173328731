<template>
  <div class="w-full h-full bg-white  overflow-y-auto containerHead">
    <Navigation />
    <div class="w-full maxwidthscreen mx-auto mt-6" style="min-height: calc(100vh - 80px)">
        <div class="flex justify-between">
            <h1 class="text-start text-2xl text-primary font-semibold"> <span class="text-black">Batch</span> List</h1>
            <router-link :to="{name:'adminDashboard'}">
                <button class="bg-primary  py-2 text-sm font-normal text-gray-700 bg-clip-padding rounded shadow-md w-40">
                    Dashboard
                </button>
            </router-link>
        </div>
        <div class="mt-6">
            <div v-if="this.messages.message != ''" class="text-end p-4 font-semibold">
                <p :class="[this.messages.status == '200' ? 'text-green-600' : 'text-red-400']" id="flashmessage"> {{ this.messages.message }} </p>
            </div>
                <div class="border border-gray-200 bg-white shadow-xl p-6 rounded-md">
                    <table class="min-w-full bg-white">
                        <thead class="bg-primary">
                            <th scope="col" class="text-start text-base font-medium text-gray-900 px-6 py-4">S.No</th>
                            <th scope="col" class="text-start text-base font-medium text-gray-900 px-6 py-4">Batch</th>
                            <th scope="col" class="text-start text-base font-medium text-gray-900 px-6 py-4">Email</th>
                            <th scope="col" class="text-base font-medium text-gray-900 px-6 py-4">Overall lead</th>
                            <th scope="col" class="text-base font-medium text-gray-900 px-6 py-4">Accounts created</th>
                            <th scope="col" class="text-base font-medium text-gray-900 px-6 py-4">Existing lead</th>
                            <th scope="col" class="text-start text-base font-medium text-gray-900 px-6 py-4">Source</th>
                            <th scope="col" class="text-start text-base font-medium text-gray-900 px-6 py-4">Uploaded Date</th>
                            <th scope="col" class="text-start text-base font-medium text-gray-900 px-6 py-4">Email status</th>
                        </thead>
                        <tbody>
                            <tr v-for="(el, index) in this.batch_list" :key="el" class="border-b">
                                <td class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap text-start">
                                    {{ index + 1 }}
                                </td>
                                <td v-if="el.batchname" class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap text-start relative pr-10">
                                        {{ el.batchname }}
                                    <div class="popUp hidden absolute w-[450px] h-[160px] bg-gray-100 left-[100%] rounded-md border border-gray-300 shadow-lg flex flex-col items-center justify-center">
                                        <div class="w-full h-full flex flex-col gap-2 justify-center items-center relative">
                                            <span @click="closePopup" class="absolute top-4 right-4 font-semibold text-lg hover:text-red-700 cursor-pointer">x</span>
                                            <form @submit.prevent="editBatchName($event,el.id)" class="flex flex-col gap-2 justify-center">
                                            <label for="batchName" class="text-base font-semibold">Batch Name</label>
                                            <input id="batchName" type="text" :value="el.batchname" class="border border-gray-300 rounded-md h-8 md:h-10 w-[320px] text-sm md:text-base pr-6 focus:outline-none focus:border-0 focus:ring-1 focus:ring-[#ffd300]">
                                            <div class="mx-auto">
                                                <button type="submit" class="px-3 py-1 border bg-primary rounded-md">Submit</button>
                                            </div>
                                            </form>
                                        </div>
                                    </div>
                                    <img src="../../assets/edit-icon.png" alt="" class="absolute top-[22px] right-3 cursor-pointer" @click="editFocus">
                                </td>
                                <td v-else class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap text-start">
                                    empty
                                </td>
                                <td class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap text-start">
                                    {{ el.user.email }}
                                </td>
                                <td class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                    {{ el.total_count }}
                                </td>
                                <td class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                    {{ el.created_count }}
                                </td>
                                <td class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                    {{ el.exist_count }}
                                </td>
                                <td class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap text-start">
                                    <a :href="`https`+el.student.slice(4)"  download>
                                        <button class="px-4 py-1 bg-[#ffd300] rounded-md">Download</button>
                                    </a>
                                </td>
                                <td class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap text-start">
                                    {{ dateFormat(el.created_at) }}
                                </td>
                                <td v-if="el.triggerd != true" class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap text-center">
                                    <button @click="mannualTrigger(el.id,$event)" class="px-4 py-1 bg-[#ffd300] rounded-md">Send</button>
                                    <button class="px-4 py-1 bg-[#ffd300] rounded-md hidden">Loading</button>
                                </td>
                                <td v-else class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap text-center">
                                    <button class="px-4 py-1 bg-green-700 text-white rounded-md">Sent</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
        </div>
    </div>
    </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import axios from "axios";
import convertdate from '@/services/DateConvert.js';

import {
    mapActions,
    mapGetters
} from "vuex";
export default {
    name : "mailTriggerView",
    data(){
        return{
            batch_list : [],
            messages : {
                        message : '',
                        status : ''
                        },
        }
    },
    components: {
        Navigation
    },
    computed:mapGetters(["userInfo", "isAuthenticated"]),
    created(){
        this.batchListGet()
    },
    methods:{
        editFocus(el){
            if($(el.target).siblings('.popUp').hasClass('hidden')){
                $(el.target).siblings('.popUp').removeClass('hidden')
            }
        },
        closePopup(el){
            if(! $(el.target).parents('.popUp').hasClass('hidden')){
                $(el.target).parents('.popUp').addClass('hidden')
            }
        },
        editBatchName(el,id){
            let jsonInput = {
                        "batchname": el.target.elements.batchName.value
                        }
            axios.put(`${process.env.VUE_APP_API}/batch/${id}/?Key=${this.userInfo.data.token}`,jsonInput)
            .then(resp=>{
                if(resp.data.status==200){
                    this.messages.message = 'Batch Name Updated'
                    this.messages.status = '200'
                    if(! $(el.target).parents('.popUp').hasClass('hidden')){
                   $(el.target).parents('.popUp').addClass('hidden')
            }
                    this.batchListGet()
                }else{
                    this.messages.message = 'Batch Name updation field'
                    this.messages.status = '400'
                }
                setTimeout(()=>{
                    this.messages.message = ''
                    this.messages.status = ''
                },3000)
            })
        },
        dateFormat(el){
             return convertdate.dateFormatDDMMYYYY(el);
        },
        batchListGet(){
            axios.get(`${process.env.VUE_APP_API}/batch_load/?Key=${this.userInfo.data.token}`)
            .then(resp=>{
                if(resp.data.status==200){
                    this.batch_list = resp.data.data
                }
            })
        },
        mannualTrigger(batch_id,el){
            let currentElement = el.target
            let sibling = el.target.nextSibling
            currentElement.classList.add('hidden')
            sibling.classList.replace('hidden','block')
            
            axios.put(`${process.env.VUE_APP_API}/manual_trigger_mail/${batch_id}/?Key=${this.userInfo.data.token}`)
            .then(resp=>{
                console.log(resp,'batch')
                if(resp.status ==200){
                    this.messages.message = 'Mail Triggered successfully'
                    this.messages.status = '200'
                    currentElement.classList.remove('hidden')
                    sibling.classList.replace('block','hidden')
                }else{
                    this.messages.message = 'Mail Trigger Failed'
                    this.messages.status = '400'
                }
                setTimeout(()=>{
                    this.batchListGet()
                    this.messages.message = ''
                    this.messages.status = ''
                },3000)
            })
        }
    }

}
</script>

<style>

</style>