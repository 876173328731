<template>
<div class="w-full h-full bg-gray-100">
    <Navigation />
    <div class="w-full max-w-7xl mx-auto" style="height: calc(100vh - 80px)">
        <div class="mt-4 flex justify-end">
            <div class="border px-4 py-1 border-gray-400 rounded-md" @click="this.is_popup = true">
            <button>Upload user list</button>
            </div>
        </div>
        <div v-if="this.is_popup == true" class="fixed z-50 bg-white min-h-screen w-full top-0 left-0 bg-opacity-70 flex justify-center items-center">
            <div class="max-w-2xl w-full border h-[300px] rounded-md shadow-xl flex flex-col items-start justify-center p-10 bg-white relative border-gray-300">
                <div class="absolute top-4 right-4 cursor-pointer" @click="this.is_popup = false">
                    <img src="../../assets/close.png" alt="" class="h-6">
                </div>
                <form ref="fileUploadFiled" @submit.prevent="fileUpload" class="flex flex-col gap-5 items-start w-full">
                    <div class="flex flex-col items-start w-[80%] gap-2">
                        <label for="batch_name" class="text-base font-medium">Batch Name</label>
                        <input type="text" id="batch_name" class="border border-gray-300 w-full rounded-md h-7 focus:border-0 focus:ring-[#FEC520]" required>
                    </div>
                    <div class="flex flex-col items-start gap-2">
                        <label for="fileUpload_csv" class="text-base font-medium">File Upload </label>
                        <input type="file" id="fileUpload_csv" class="border w-full rounded-md" accept=".csv" required>
                    <div class="flex gap-2">
                        <p class="text-xs text-gray-400">Input field format with sample data</p>
                        <a href="https://api.lots.learn.online/media/lesson_mdfile/student_example.csv" target="_blank">
                        <img src="../../assets/download.png" alt="download" class="h-5 cursor-pointer">
                        </a>
                    </div>
                    </div>
                    <div class="flex gap-4 items-center">
                        <button v-if="this.ld_btn != true" class="border px-2 py-1 text-sm font-medium rounded-md bg-[#FEC52D] hover:text-white" type="submit">Submit</button>
                        <button v-else class="border px-2 py-1 text-sm rounded-md bg-[#FEC52D]">Loading..</button>
                        <div v-if="this.message.text != ''">
                            <p class="text-sm font-medium text-white" :class="this.message.type=='error' ? ' text-red-600' : ' text-green-700' ">{{ this.message.text }}</p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="overflow-scroll border rounded-lg shadow-md mt-4">
            <div class="flex items-center justify-between px-4">
                <h1 class="py-4 text-start px-4">User Info</h1>
                <div class="relative flex gap-6 items-center">
                    <p>Reference ID : <b> {{ this.userInfo.data.user.staff_profile.slug}} </b> </p>
                    <!-- <ShareView /> -->
                    <button @click="copyClipboard(this.inviteLink,$event)" class="bg-primary border px-2 py-1 rounded-md text-sm relative">
                         Invite Link
                        <span class="absolute notify hidden -bottom-8 left-0 bg-white w-full py-1 text-gray-500 border flex gap-2 px-2 rounded-md">Copied <span> &check;</span></span>
                    </button>
                </div>
            </div>
            <table class="min-w-full">
                <thead class="bg-white border-b">
                    <tr class="">
                        <th scope="col" class="text-base font-medium text-start text-gray-900 px-6 py-4">
                            #
                        </th>
                        <th scope="col" class="text-base font-medium text-start text-gray-900 px-6 py-4">
                            Name
                        </th>
                        <th scope="col" class="text-base font-medium text-start text-gray-900 px-6 py-4">
                            Email
                        </th>
                        <th scope="col" class="text-base font-medium text-start text-gray-900 px-6 py-4">
                            Phone No
                        </th>
                        <th scope="col" class="text-base font-medium text-start text-gray-900 px-6 py-4">
                            Universities
                        </th>
                        <th scope="col" class="text-base font-medium text-start text-gray-900 px-6 py-4">
                            Qualification
                        </th>
                        <th scope="col" class="text-base font-medium text-start text-gray-900 px-6 py-4">
                            City
                        </th>
                        <th scope="col" class="text-base font-medium text-start text-gray-900 px-6 py-4">
                            Joined date 
                        </th>
                    </tr>
                </thead>
                <tbody v-if="this.userRegisterList">
                    <tr v-for="(el, index) in this.userRegisterList" :key="el" class="bg-white border-b">
                        <td class="text-sm text-gray-900 font-normal text-start px-6 py-4 whitespace-nowrap">
                            {{ index + 1 }}
                        </td>
                        <td class="text-sm text-gray-900 font-normal text-start px-6 py-4 whitespace-nowrap">
                            {{ el.display_name }}
                        </td>
                        <td class="text-sm text-gray-900 font-normal text-start px-6 py-4 whitespace-nowrap">
                            {{ el.email }}
                        </td>
                        <td v-if="el.phone" class="text-sm text-gray-900 font-normal text-start px-6 py-4 whitespace-nowrap">
                            {{ el.phone }}
                        </td>
                        <td v-else class="text-sm text-gray-900 font-normal text-start px-6 py-4 whitespace-nowrap">
                            Null
                        </td>
                        <td v-if="el.user_data_filed[0].Universities_list_data" class="text-sm text-gray-900 font-normal text-start px-6 py-4 whitespace-nowrap">
                            {{ el.user_data_filed[0].Universities_list_data.name }}
                        </td>
                        <td v-else class="text-sm text-gray-900 font-normal text-start px-6 py-4 whitespace-nowrap">
                            Null
                        </td>
                        <td v-if="el.user_data_filed[0].Highest_qualification_data" class="text-sm text-gray-900 font-normal text-start px-6 py-4 whitespace-nowrap">
                            {{ el.user_data_filed[0].Highest_qualification_data.name}}
                        </td>
                        <td v-else class="text-sm text-gray-900 font-normal text-start px-6 py-4 whitespace-nowrap">
                            Null
                        </td>
                        <td v-if="el.user_data_filed[0].city_data" class="text-sm text-gray-900 font-normal text-start px-6 py-4 whitespace-nowrap">
                            {{ el.user_data_filed[0].city_data.name }}
                        </td>
                        <td v-else class="text-sm text-gray-900 font-normal text-start px-6 py-4 whitespace-nowrap">
                            Null
                        </td>
                        <td class="text-sm text-gray-900 font-normal text-start px-6 py-4 whitespace-nowrap">
                            {{ this.dateFormat(el.date_joined) }}
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr class="bg-white border-b">
                        <td class="text-sm text-gray-900 font-normal text-start px-6 py-4 whitespace-nowrap">
                            empty
                        </td>
                        <td class="text-sm text-gray-900 font-normal text-start px-6 py-4 whitespace-nowrap">
                            empty
                        </td>
                        <td class="text-sm text-gray-900 font-normal text-start px-6 py-4 whitespace-nowrap">
                            empty
                        </td>
                        <td class="text-sm text-gray-900 font-normal text-start px-6 py-4 whitespace-nowrap">
                            empty
                        </td>
                        <td class="text-sm text-gray-900 font-normal text-start px-6 py-4 whitespace-nowrap">
                            empty
                        </td>
                        <td class="text-sm text-gray-900 font-normal text-start px-6 py-4 whitespace-nowrap">
                            empty
                        </td>
                        <td class="text-sm text-gray-900 font-normal text-start px-6 py-4 whitespace-nowrap">
                            empty
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
import ShareView from "@/components/shareView.vue";
import convertdate from '@/services/DateConvert.js';
import axios from "axios";
import {
    mapActions,
    mapGetters
} from "vuex";
export default {
    data() {
        return {
            userRegisterList: [],
            ld_btn : false,
            is_popup : false,
            inviteLink : '',
            message : {
                text : '',
                type : ''
            }
        };
    },
    computed: mapGetters(["userInfo", "isAuthenticated"]),
    components: {
        Navigation,
        ShareView
    },
    created() {
        this.inviteLink = `${process.env.VUE_APP_SITE}/${this.userInfo.data.user.staff_profile.slug}`                            
        this.userInfoGet()
    },
    methods: {
        copyClipboard(text,event){
            event.target.classList.replace('bg-primary','bg-gray-300')
            navigator.clipboard?.writeText && navigator.clipboard.writeText(text);
            $('.notify').show('slow')
            setTimeout(()=>{
                event.target.classList.replace('bg-gray-300','bg-primary')
                $('.notify').hide('slow')
            },2000)
        },
        dateFormat(el){
             return convertdate.dateFormatDDMMYYYY(el);
        },
        async userInfoGet(){
           await axios.get(`${process.env.VUE_APP_API}/profile_all/?user_data_filed__referens__user=${this.userInfo.data.user.id}&Key=${this.userInfo.data.token}`)
            .then(resp=>{
                console.log(resp)
                if(resp.status==200){
                    this.userRegisterList = resp.data.data
                }else{
                    this.userRegisterList = []
                }
            })
        },
        fileUpload(el){
            this.ld_btn = true
            let bodyVal = new FormData()
            bodyVal.append('batchname',el.target.batch_name.value)
            bodyVal.append("my_file", el.target.fileUpload_csv.files[0])
            axios.post(`${process.env.VUE_APP_API}/student/data_upload/?Key=${this.userInfo.data.token}`,bodyVal)
            .then(resp=>{
                if(resp.status==200){
                    this.message.text = 'Data uploaded successfully'
                    this.message.type='success'
                    this.ld_btn = false
                    this.$refs.fileUploadFiled.reset()
                }else{
                    this.message.text = 'check the fields'
                    this.message.type='error'
                    this.ld_btn = false
                }
            }).catch(error =>{
                console.log(error)
            })
            setTimeout(()=>{
                this.ld_btn = false
                this.message.text=''
                this.message.type=''
            },3000)
        }
    },
};
</script>
