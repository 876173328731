import { createStore } from "vuex";
import axios from 'axios';
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });


export default createStore({
    state: {
        userInfo: null,
        error: null,
        isAuthenticated: false,
        userProfileInfo: null,
    },
    getters: {
        userInfo: state => state.userInfo,
        loginError: state => state.error,
        isAuthenticated: state => state.isAuthenticated,
        userProfiileInfo: state => state.userProfileInfo
    },
    actions: {
        async loginUser({ commit }, credentials) {
            await axios.post(`${process.env.VUE_APP_API}/login/check_otp/`, credentials)
                .then(response => {
                    if (response.data.status == 200) {
                        commit("setUserInfo", response.data)
                    } else {
                        commit("errMessage", response.data.message)
                        setTimeout(() => {
                            commit("errMessage", "")
                        }, 3000)
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async userDetailsGet({commit},tokenKey){
            await axios.get(`${process.env.VUE_APP_API}/profile/?Key=${tokenKey}`)
            .then(response => {
                console.log(response)
                if (response.data.status == 200) {
                    commit("collectProfileInfo", response.data.data)
                } else {
                    commit("collectProfileInfo", null)
                }
            })
            .catch(error => {
                console.log(error)
            })
        },                                       
        async logOutUser({ commit }) {
            await axios.get(`${process.env.VUE_APP_API}/logout/`)
            .then(resp=>{
                console.log(resp)
                commit("logout", resp.data)
            })
            .catch(error => {
                console.log(error)
            })
        },
        async register({commit},jsonVal){
            await axios.post(`${process.env.VUE_APP_API}/register/`, jsonVal)
            .then(response => {
                if (response.data.status == 200) {
                    commit("setUserInfo", response.data)
                } else {
                    commit("errMessage", "Account already exists")
                    setTimeout(() => {
                        commit("errMessage", "")
                    }, 3000)
                }
            })
            .catch(error => {
                console.log(error)
            })
        }

    },
    mutations: {
        setUserInfo: (state, data) => {
            state.userInfo = data
            state.isAuthenticated = true;
            state.error = null;
        },
        errMessage: (state, data) => {
            state.error = data
        },
        logout: (state) => {
            state.userInfo = null;
            state.userProfileInfo = null
            state.isAuthenticated = false;
        },
        collectProfileInfo: (state,data)=>{
            state.userProfileInfo = data
        }
    },
    modules: {},
    plugins: [createPersistedState(
        {
            storage: {
              getItem: (key) => ls.get(key),
              setItem: (key, value) => ls.set(key, value),
              removeItem: (key) => ls.remove(key)
            }
          }
    )],
});