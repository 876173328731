<template>
<div class="w-full h-full">
    <Navigation />
    <div class="w-full maxwidthscreen mx-auto mt-6" style="min-height: calc(100vh - 80px)">
    <div class="flex justify-between">
            <h1 class="text-start text-2xl text-primary font-semibold">Enrollments</h1>
            <router-link :to="{name:'adminDashboard'}">
                <button class="bg-primary  py-2 text-sm font-normal text-gray-700 bg-clip-padding rounded shadow-md w-40">
                    Dashboard
                </button>
            </router-link>
        </div>
    <div class="w-full border shadow-md text-start bg-white mt-6" style="height: calc(100vh - 160px)">
            <table class="min-w-full bg-white">
                <thead class="bg-primary border-b text-start sticky top-0">
                    <tr class="">
                        <th scope="col" class="text-start text-base font-medium text-gray-900 px-6 py-4">
                            S.No
                        </th>
                        <th scope="col" class="text-start text-base font-medium text-gray-900 px-6 py-4">
                            Name
                        </th>
                        <th scope="col" class="text-start text-base font-medium text-gray-900 px-6 py-4">
                            Email
                        </th>
                        <th scope="col" class="text-start text-base font-medium text-gray-900 px-6 py-4">
                            University
                        </th>
                        <th scope="col" class="text-start text-base font-medium text-gray-900 px-6 py-4">
                            Category
                        </th>
                        <th scope="col" class="text-start text-base font-medium text-gray-900 px-6 py-4">
                            Program
                        </th>
                        <th scope="col" class="text-start text-base font-medium text-gray-900 px-6 py-4">
                            Elective
                        </th>
                        <th scope="col" class="text-start text-base font-medium text-gray-900 px-6 py-4">
                            Staff Name
                        </th>
                        <th scope="col" class="text-start text-base font-medium text-gray-900 px-6 py-4">
                            Staff Mail
                        </th>
                        <!-- <th  scope="col" class="text-start text-base font-medium text-gray-900 px-6 py-4">
                            Date
                        </th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(el, index) in this.enrollment_list" :key="el" class="border-b">
                        <td class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                            {{ index + 1 }}
                        </td>
                        <td class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                            {{ el.student_user.display_name }}
                        </td>
                        <td class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                            {{ el.student_user.email }}
                        </td>
                        <td class="text-sm text-gray-900 font-normal px-6 py-4">
                           {{ el.university_id.name }}
                        </td>
                        <td class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                            {{ el.elective_id.category.category }}
                        </td>
                        <td class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                            {{ el.elective_id.program.name }}
                        </td>
                        <td class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                            {{ el.elective_id.elective.electives }}
                        </td>
                        <td class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap capitalize">
                            {{ el.staff_user.display_name }}
                        </td>
                        <td class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap capitalize">
                            {{ el.staff_user.email }}
                        </td>
                        <!-- <td class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                            {{ el.enrolment_at }}
                        </td> -->
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import { mapActions, mapGetters } from "vuex";
 import axios from "axios"
export default {
    name:'EnrollmentView',
    data(){
        return{
           enrollment_list : [] 
        }
    },
    computed: mapGetters(["userInfo", "isAuthenticated"]),
    components: {
        Navigation,
    },
    created(){
        this.enrollmentCall()
    },
    methods:{
        ...mapActions(["userDetailsGet"]),
        
        enrollmentCall(el){
            axios
            .get(
              `${process.env.VUE_APP_API}/enrolment/get/`
            )
            .then((resp) => {
                console.log(resp)
                this.enrollment_list = resp.data.data
            })
            .catch((error)=>{
                console.log(error)
            })
        }
    }
}
</script>

<style>

</style>