import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./index.css";
import { library } from '@fortawesome/fontawesome-svg-core'

import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faCircleInfo)

const app = createApp(App)

app.use(store).use(router).component('font-awesome-icon', FontAwesomeIcon).mount("#app");

app.config.globalProperties.apiHost = `${process.env.VUE_APP_API}`