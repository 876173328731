<template>
    <div class="w-full  h-full bg-white">
        <Navigation />
        <div class="w-full maxwidthscreen mx-auto" style="min-height: calc(100vh - 110px)">
            <!-- <div class="mt-4">
                <ul class="flex justify-evenly border border-gray-200 shadow-lg rounded-t-2xl font-medium cursor-pointer">
                    <li class="p-2 border-r w-[25%] relative rounded-tl-2xl" :class="[this.selectOption=='user' ? 'bg-primary selectOptions' : '']">Based User</li>
                    <li class="p-2 border-r w-[25%] relative rounded-tl-2xl" :class="[this.selectOption=='batch' ? 'bg-primary selectOptions' : '']">Based on Batch</li>
                    <li class="p-2 border-r w-[25%] relative rounded-tl-2xl" :class="[this.selectOption=='event' ? 'bg-primary selectOptions' : '']">Based on Event</li>
                    <li class="p-2 border-r w-[25%] relative rounded-tl-2xl" :class="[this.selectOption=='raw' ? 'bg-primary selectOptions' : '']">Raw User</li>
                </ul>
            </div> -->
                <!-- 2 preview -->
                <div class="flex flex-col">
                    <select name="" @change="selectedTemp" id="" class="form-select appearance-none block  py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat focus:ring-0 focus:border-primary border-[#e5e7eb] rounded shadow-md focus:outline-none w-64 m-4">
                        <option selected default value="temp1">Template 1</option>
                        <option value="temp2">Template 2</option>
                        <option value="temp3">Template 3</option>
                        <option value="temp4">Template 4</option>
                        <option value="temp5">Template 5</option>
                    </select>
                    <div v-if="selected_template == 'temp1'" class="overflow-scroll" style="height: calc(100vh - 10z0px)" >
                        <Template1 />
                    </div>
                    <div v-else-if="selected_template == 'temp2'" class="h-full">
                        <Template2 />
                    </div>
                    <div v-else-if="selected_template == 'temp3'" class="h-full">
                        <Template3 />
                    </div>
                    <div v-else-if="selected_template == 'temp4'" class="h-full">
                        <Template4 />
                    </div>
                    <div v-else-if="selected_template == 'temp5'" class="h-full">
                        <Template5 />
                    </div>
                </div>
        </div>
    </div>
    </template>
    
    <script>
    // @ is an alias to /src
    import Navigation from "@/components/Navigation.vue";
    import Template1 from "@/components/MailTemplates/Template1View.vue"
    import Template2 from "@/components/MailTemplates/Template2View.vue"
    import Template3 from "@/components/MailTemplates/Template3View.vue"
    import Template4 from "@/components/MailTemplates/Template4View.vue"
    import Template5 from "@/components/MailTemplates/Template5View.vue"
    
    import {
        mapActions,
        mapGetters
    } from "vuex";
    export default {
        name: "CommunicationView",
        data(){
            return{
                selectOption : "user",
                selected_template : 'temp1',
                }
        },
        components: {
        Navigation,
        Template1,
        Template2,
        Template3,
        Template4,
        Template5,
        },
        methods : {
            selectedTemp(el){
                this.selected_template = $(el)[0].target.value

            }
        }
    };
    </script>
    <style scoped>
    
    </style>