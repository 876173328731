<template lang="">
    <div class="w-full h-full bg-white">
        <div class="maxwidthscreen w-full h-full flex mx-auto">
            <!-- Left Navigation -->
            <LeftNavigation currentTap="Universities" />
            <div class="containerHead w-full flex flex-col gap-4 max-h-screen overflow-y-auto">
                <!-- 1 -->
                <div class="w-full h-[80px] bg-white p-4 shadow-lg flex justify-between items-center gap-5 sticky top-0 z-50 block md:hidden">
                    <div class="visible md:invisible">
                        <img src="../../assets/logo-black.png" class="w-[100px]" />
                    </div>
                    <div class="flex items-center justify-end">        
                        <button class="block md:hidden px-3 text-black" type="button" id="navBarIcon">
                            <i class="fa-solid fa-bars text-3xl"></i>
                        </button>
                    </div>
                  </div>
                <h1 class="text-start text-2xl text-primary font-semibold mt-6 px-8"> <span class="text-black">Our</span> Universities</h1>
                <div class="flex flex-wrap gap-4 justify-evenly">
                <template v-for="university in this.universitiesList" :key="university">
                    <div class="w-[270px] min-h-[270px] h-[460px] border flex flex-col justify-between rounded-md bg-white">
                        <div class="h-[65%] flex flex-col gap-4  border-b-2 border-gray-300 ">
                            <div v-if="university.picture==null">
                                <img src="../../assets/university.png" />
                            </div>
                            <div v-else>
                                <img :src="university.picture" class="" />
                            </div>
                            <div class="w-full h-auto flex justify-center items-center">
                                <img :src="university.logo" class="w-[120px] object-cover" />
                            </div>
                            <div class="">
                                <p class="font-semibold"> {{ university.name }} </p>
                            </div>
                        </div>
                        <div class="flex flex-col items-center justify-center py-4 gap-3 h-[35%]">
                          <div>
                             <button v-if="this.applieduniversity.includes(university.id) == false" class="px-4 py-1 border bg-primary rounded-md font-medium" @click="appliedUniversity(university.id,university.name)">Apply Now</button>
                             <button v-else class="px-4 py-1 border bg-primary rounded-md font-medium cursor-not-allowed" >Already Applied</button>
                          </div>
                          <div>
                            <button v-if="university.brochure" class="px-4 py-1 border bg-primary rounded-md font-medium">
                                <div @click="downloadBrochure($event,university.id,university.brochure.slice(4),university.name)">
                                  <p> Download Brochure </p> 
                                  <p class="hidden"> Loading... </p> 
                                </div>
                               </button>
                          </div>
                        </div>
                    </div>
                </template>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import LeftNavigation from "@/components/leftNavigation.vue";
import axios from "axios";
export default {
  data() {
    return {
      select_val: "Universities",
      universitiesList: "",
      applieduniversity: [],
    };
  },
  components: {
    LeftNavigation,
  },
  computed: mapGetters(["userInfo", "isAuthenticated"]),
  async created() {
    await this.universityList();
    await this.checkApplyuniversity();
  },
  mounted() {
    $("#navBarIcon").on("click", function () {
      $("#navbarContainer").toggleClass("open");
    });
  },
  methods: {
    ...mapActions(["logOutUser"]),

    sessionStoreUn(val){
      sessionStorage.setItem('slt_uni',val)
    },
    async checkApplyuniversity() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/selected-university/list/?user__id=${this.userInfo.data.user.id}`
        )
        .then((resp) => {
          if (resp.data.status == 200) {
            let universityList = resp.data.data;
            universityList.forEach((element) => {
              this.applieduniversity.push(element.university.id);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async logOut() {
      await this.logOutUser();
      if (this.isAuthenticated == false) {
        this.$router.push({ name: "Login" });
      }
    },
    async downloadBrochure(el, universityId, url, name) {
      el.target.classList.toggle("hidden");
      el.target.nextSibling.classList.toggle("hidden");
      let bodyVal = {
        user: this.userInfo.data.user.id,
        university: universityId,
      };
      await fetch(`https${url}`)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const a = document.createElement("a");
          a.href = url;
          a.download = `${name}.pdf`;
          a.click();
        });

      await axios
        .post(
          `${process.env.VUE_APP_API}/selected-university-brochure/post/`,
          bodyVal
        )
        .then((resp) => {
          this.$router.push({
            name: "Thankyou",
            params: { options: "download-brochure" },
          });
          el.target.classList.toggle("hidden");
          el.target.nextSibling.classList.toggle("hidden");
        })
        .catch((error) => {
          console.log(error);
          el.target.classList.toggle("hidden");
          el.target.nextSibling.classList.toggle("hidden");
        });
    },
    appliedUniversity(universityId,name) {
      let bodyVal = {
        user: this.userInfo.data.user.id,
        university: universityId,
      };
      axios
        .post(`${process.env.VUE_APP_API}/selected-university/post/`, bodyVal)
        .then((resp) => {
          if (resp.status == 200) {
            this.sessionStoreUn(name)
            this.$router.push({
              name: "Thankyou",
              params: { options: "applied-elective" },
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async universityList() {
      await axios
        .get(`${process.env.VUE_APP_API}/student/universities_all/list/`)
        .then((resp) => {
          this.universitiesList = resp.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
</style>