<template>
<div class="w-full h-full">
    <Navigation />
    <div class="w-full font-bold max-w-7xl mx-auto flex flex-col mt-10 gap-4" style="height:calc(100vh - 80px);">
        
        <div class="border shadow-lg p-2 py-4 rounded-md">
            <template v-if="this.is_acs">
                <h3 class="text-2xl text-start px-2">The Rules</h3>
                <div>
                    <ol class="text-start px-6 py-4 flex flex-col gap-2">
                        <li>1. This contest is for individuals; teams are not allowed.</li>
                        <li>2. Any competitor found cheating will be disqualified and banned from future coding contests.</li>
                        <li>3. By participating and selecting "I agree to share my contest results and profile information with sponsor companies," you are granting HackerRank permission to share your contest results with DTCC.</li>
                        <li>4. By participating and selecting "I'm interested in new job opportunities", you are indicating that you are willing to be considered for employment by DTCC.</li>
                        <li>5. Residents of the following countries and territories are not eligible to participate or win prizes due to legal restrictions: Antarctica, Afghanistan, Bangladesh,
                            Belarus, Bouvet Island, British Indian Ocean Territory, Burundi, Cameroon, Central African Republic, Christmas Island, Cote D'ivoire, Cuba, Equatorial Guinea, Haiti,
                            Heard Island and Mcdonald Islands, Islamic Republic of Iran, Republic of Iraq, Democratic People's Republic of Korea (North Korea), Kazhakasthan, Lao People's Democratic Republic (Laos),
                            Lebanon, Liberia, Libyan Arab Jamahiriya, Myanmar, Nigeria, Pakistan, Papua New Guinea, Serbia and Montenegro, Somalia, Sudan, Syrian Arab Republic, Yemen, Zimbabwe.</li>
                    </ol>
                    <div>
                        <h3 class="text-2xl text-start px-2">Scoring</h3>
                        <p class="text-start px-6 py-4">
                            Participants are ranked by score. Your score is determined by the number of test cases your code submission successfully passes.
                            If two participants have the same score, the tie is broken by the contestant who took the least amount of time to solve the challenges.
                        </p>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="px-6 py-4 flex flex-col gap-2 text-center">
                    {{ this.message}}
                </div>
            </template>
        </div>
        <!-- button Start -->
        <div class="my-4">
            <template v-if="this.is_acs">
                <router-link :to="{name:'MCQ'}">
                    <button class="gradient-btn outline-none rounded"> Start</button>
                </router-link>
            </template>
            <template v-else>
                <router-link :to="{name:'TestInfo'}">
                    <button class="gradient-btn outline-none rounded"> Home</button>
                </router-link>
            </template>
        </div>
    </div>
</div>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
import axios from "axios";

export default {
    name: "InformationView.vue",
    data() {
        return {
            is_acs: true,
            message: ''
        }
    },
    components: {
        Navigation,
    },
    async created() {
        
    }
};
</script>

<style src="@/assets/CSS/ProgressTracking.css">

    
</style>
