<template>
  <div class="w-full">
    <Navigation />
    <div class="2xl:max-w-7xl w-full mx-auto flex">
      <LeftNavigation />
      <div class="w-full">
        <div class="p-10 bg-gray-200 w-auto m-6 max-w-[560px] mx-auto">
          <form
            @submit.prevent=""
            class="flex flex-col gap-4 p-10 border bg-white drop-shadow-xl"
          >
            <div class="flex flex-col items-start gap-2">
              <label for="user-name" class="font-semibold">Name</label>
              <input
                id="user-name"
                type="text"
                class="rounded-md border border-gray-200 shadow-md h-10 max-w-[360px] w-full"
              />
            </div>
            <div class="flex flex-col items-start gap-2">
              <label for="email-address" class="font-semibold"
                >Email Address</label
              >
              <input
                id="email-address"
                type="email"
                class="rounded-md border border-gray-200 shadow-md h-10 max-w-[360px] w-full"
              />
            </div>
            <div class="flex flex-col items-start gap-2">
              <label for="contact-number" class="font-semibold"
                >Contact Number</label
              >
              <input
                id="contact-number"
                type="tel"
                maxlength="10"
                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                class="rounded-md border border-gray-200 shadow-md h-10 max-w-[360px] w-full"
              />
            </div>
            <div class="flex flex-col gap-2 items-start">
              <label for="handler-type" class="font-semibold"
                >Handler Type</label
              >
              <select
                name=""
                id="handler-type"
                class="max-w-[360px] w-full rounded-md border border-gray-200 shadow-md h-10"
              >
                <option value="cp_admin">CP-admin</option>
                <option value="cp_super_admin">CP-super-admin</option>
                <option value="cp_partner">CP-partner</option>
                <option value="raw_data_handler">Raw-data-handler</option>
                <option value="tpo_admin">TPO-admin</option>
              </select>
            </div>
            <div class="mt-2">
              <button
                type="submit"
                class="text-center px-4 py-1 rounded-md bg-primary"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <flashMessage v-if="this.messages.message" :message="this.messages.message" :status="this.messages.status" />
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import LeftNavigation from "@/components/LeftNavigationAdminView.vue";
import FlashMessage from "@/components/FlashMessageView.vue";
export default {
  data() {
    return {
        messages : {
            message : '',
            status : '200'
        }
    };
  },
  components: {
    Navigation,
    LeftNavigation,
    FlashMessage
  },
};
</script>

<style></style>
