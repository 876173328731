<template>
  <div class="flex gap-2 h-full">
    <div class="w-1/2 flex flex-col gap-2 px-2">
      <div class="flex flex-col text-start h-full">
        <textarea
          name=""
          id=""
          cols="30"
          rows="20"
          v-model="contentInput"
          class="form-control block px-3 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding focus:ring-0 focus:border-primary border-[#e5e7eb] rounded shadow-md focus:outline-none w-full h-full"
        ></textarea>
      </div>
    </div>
    <div class="h-full">
      <div v-html="contentInput"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contentInput: `<table align="center" style="margin:auto; width:600px; border:solid 1px #D1D5DB;">
        <tr>
            <td>
                <img src="https://demo.api.maggana.com/media/email/email01/header.png" style="width: 600px;" usemap="#image-map">

                <map name="image-map">
                    <area target="_blank" href="https://lots.learn.online/" coords="25,280,157,316" shape="rect">
                </map>


            </td>
        </tr>

        <tr>
            <td style="background-color: white;">
                <div style="padding:10px 0px 16px 20px;font-size:13px;font-weight: 500;">
                    <p style="text-align:start">Hello {{ name }} ,</p>
                    <div style='text-align: justify;padding:1.25rem 0px'>
                    <p>Here is a fantastic opportunity for you to balance work and study stress-free.</p>
                    <p>Learn.Online is platform that helps you choose the right online degree program from a host of
                        world
                        renowned universities.</p>
                    <p>And guess what? We're offering you an exclusive opportunity where you stand a chance to get up to
                        50%
                        scholarship on all degree programs.</p>
                    <p>All you have to do is test your knowledge by taking the Learn.Online Test for scholarship
                        (LO.T.S),
                        answer a few questions, and you'll be well on your way to win big!
                    </p>
                    <p>To activate your account and take the test, Click the button below.</p>
                    </div>
                    <p style="text-align: center;"><a href="https://lots.learn.online/" target="_blank"><button style="padding:8px 15px; color:white;font-weight:bold;
                                background-color:#231f20;border-radius: 20px;font-size: 14px;border: 0px; ">REGISTER
                                NOW</button></a></p>
                </div>
            </td>

        </tr>

        <tr>
            <td>
                <table style="border-collapse: collapse;width: 100%; background-color: white;" align="center">
                    <tr>
                        <td style="width: 200px;">
                            <img src="https://demo.api.maggana.com/media/email/email01/left.png" style="height:8px;width: 200px;">
                        </td>
                        <td style="width:200px;">
                            <p style="text-align: center;font-weight: bold;font-size: 15px;">PARTNER UNIVERSITIES</p>
                        </td>
                        <td style="width:200px;">
                            <img src="https://demo.api.maggana.com/media/email/email01/right.png" style="height:8px;width: 200px;">
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td>
                <table style="border-collapse: collapse;width: 100%;background-color: white;" align="center">
                    <tr>
                        <td>
                            <div
                                style="display: flex;justify-content:space-between;align-items: center;padding: 0px 10px;">

                                <img src="https://demo.api.maggana.com/media/email/email01/Jain.png"
                                    style="width: 120px; border-right: 1px solid #cecece;min-height: 50px;">
                                <img src="https://demo.api.maggana.com/media/email/email01/hindusthan-logo.png"
                                    style="width:120px;border-right: 1px solid #cecece;min-height: 50px">

                                <img src="https://demo.api.maggana.com/media/email/email01/vignan_university.png"
                                    style="width:120px;border-right: 1px solid #cecece;min-height: 50px">
                                <img src="https://demo.api.maggana.com/media/email/email01/manavrachna.png" style="width:120px;min-height: 50px">
                                <p style="margin-left: 20px ;font-size: 13px;">...and more</p>
                            </div>
                        </td>
                    </tr>

                </table>
            </td>
        </tr>

        <tr style="background-color: #face4b">
            <td style="padding-top:30px;">
                <table style="border-collapse: collapse;width: 100%; " align="center">
                    <tr>
                        <td style="width:170px;">
                            <img src="https://demo.api.maggana.com/media/email/email01/leftlogo.png" style="height:8px;width: 170px;">
                        </td>
                        <td style="width:260px;">
                            <p style="font-weight: bold;font-size: 18px;text-align: center;">TOP PROGRAMS
                                OFFERED</p>
                        </td>
                        <td style="width:170px;">
                            <img src="https://demo.api.maggana.com/media/email/email01/Rightlogo.png" style="height:8px;width: 170px;">
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr style="background-color: #face4b">
            <td>
                <p style="text-align: center;font-weight:500;font-size:20px">MBA | MCOM | MCA | MA ... <sub>and
                        more</sub></p>
            </td>
        </tr>

        <tr style="border-top: 1px solid #876c20;">
            <td>
                <table style="border-collapse: collapse;width: 100%;">
                    <tr style="background-color: #face4b;padding: 0px 10px;text-align: center;">
                        <td style=" width: calc(600px / 3);font-size:15px;padding: 5px 0px;">
                            <div style="vertical-align: middle;border-right:1px solid black;font-weight: 500;"><a
                                    href="tel:+917005997005" style="text-decoration:none;color:black"><img
                                        src="https://demo.api.maggana.com/media/email/email01/call.png" style="width: 20px;vertical-align: middle;padding:5px;">+91 70059
                                    97005</a>
                            </div>
                        </td>
                        <td style=" width: calc(600px / 3);font-size:15px;padding: 5px 0px;">
                            <div style="vertical-align: middle;border-right:1px solid black;font-weight: 500;"><a
                                    href="https://lots.learn.online/" style="text-decoration:none;color:black"><img
                                        src="https://demo.api.maggana.com/media/email/email01/globe.png"
                                        style="width: 20px;vertical-align: middle;padding:5px;">www.lots.learn.online</a>
                            </div>
                        </td>
                        <td style=" width: calc(600px / 3);font-size:15px;padding: 5px 0px;font-weight: 500;">
                            <div style="vertical-align: middle;"><a href="mailto:info@learn.online"
                                    style="text-decoration:none;color:black"><img src="https://demo.api.maggana.com/media/email/email01/email.png"
                                        style="width: 20px;vertical-align: middle;padding:5px;">info@learn.online</a>
                            </div>
                        </td>

                    </tr>
                </table>
            </td>
        </tr>
    </table>`,
    };
  },
};
</script>

<style></style>
