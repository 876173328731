<template>
    <div class="flex gap-2 h-full">
    <div class="w-1/2 flex flex-col gap-2 px-2">
      <div class="flex flex-col text-start h-full">
        <textarea
          name=""
          id=""
          cols="30"
          rows="20"
          v-model="content"
          class="form-control block px-3 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding focus:ring-0 focus:border-primary border-[#e5e7eb] rounded shadow-md focus:outline-none w-full h-full"
        ></textarea>
      </div>
    </div>
    <div class="h-full">
      <div v-html="content"></div>
    </div>
  </div>
  
</template>

<script>
export default {
    data(){
        return {
            content : `<table align="center" style="margin:auto; width:600px;background-color: white; border:solid 1px #D1D5DB;">
        <tr>
            <td style="padding: 10px 5px;">
                <img src="https://demo.api.maggana.com/media/email/email02/headlogo.png" style="width:200px;">
            </td>
        </tr>

        <tr>
            <td>
                <img src="https://demo.api.maggana.com/media/email/email02/image1.png" usemap="#image_map" style="width:600px">
                <map name="image_map">

                    <area target="_blank" title="" href="https://lots.learn.online/" coords="200,150,380,280" shape="rect">

                </map>
            </td>
        </tr>

        <tr>
            <td style="padding:5px 30px;font-size: 14px;font-weight: 500;">
                <p>Hello {{ name }}</p>
                <p>Time to take the test and make your learning journey easier!</p>
                <div style="display:flex;align-content:center; width:400px;
                        padding: 15px;align-items: center; ">

                    <a href="https://lots.learn.online/" target="_blank">
                        <button style="padding: 10px 10px;background-color:#231f20;border-radius: 10px;color:white;
                        text-align: center;font-weight: bold;">GET STARTED NOW</button>
                    </a>

                    <div style="margin-left: 55px;">
                        <div style="background-image: linear-gradient(0deg, gray, white);padding: 8px;margin-right: 5px;
                                border-radius: 8px;font-size: 30px;font-weight: bold;">04</div>
                        <div style="font-size: 13px;text-align: center;">Days</div>
                    </div>


                    <div>
                        <div style="background-image: linear-gradient(0deg, gray, white);padding: 8px;margin-right: 5px;
                                border-radius: 8px;font-size: 30px;font-weight: bold;">00</div>
                        <div style="font-size: 13px;text-align: center;">Hours</div>
                    </div>

                    <div>
                        <div style="background-image: linear-gradient(0deg, gray, white);padding: 8px;margin-right: 5px;
                                border-radius: 8px;font-size: 30px;font-weight: bold;">00</div>
                        <div style="font-size: 13px;text-align: center;">Minutes</div>
                    </div>

                </div>
                <p style="font-weight:bold;">Test format:</p>
                <p>The scholarship test will be online,in MCQ format and proctored by the Learn.Online team.</p>
                <p>The test will be conducted in english and will contain Verbal and Aptitude sections.</p>
                <p>There are a total of 40 questions that need to answered in 40 minutes. Any discreapancies and unfair
                    means
                    will lead to the disqualification of the candidates.
                </p>
            </td>
        </tr>

        <tr style="border-top: 1px solid #876c20;">
            <td>
                <table style="border-collapse: collapse;width: 100%;">
                    <tr style="background-color: #face4b;padding: 0px 10px;text-align: center;">
                        <td style=" width: calc(600px / 3);font-size:15px;padding: 5px 0px;">
                            <div style="vertical-align: middle;border-right:1px solid black;font-weight: 500;"><a
                                    href="tel:+917005997005" style="text-decoration:none;color:black"><img
                                        src="https://demo.api.maggana.com/media/email/email02/call.png" style="width: 20px;vertical-align: middle;padding:5px;">+91 70059
                                    97005</a>
                            </div>
                        </td>
                        <td style=" width: calc(600px / 3);font-size:15px;padding: 5px 0px;">
                            <div style="vertical-align: middle;border-right:1px solid black;font-weight: 500;"><a
                                    href="https://lots.learn.online/" style="text-decoration:none;color:black"><img
                                        src="https://demo.api.maggana.com/media/email/email02/globe.png"
                                        style="width: 20px;vertical-align: middle;padding:5px;">www.lots.learn.online</a>
                            </div>
                        </td>
                        <td style=" width: calc(600px / 3);font-size:15px;padding: 5px 0px;font-weight: 500;">
                            <div style="vertical-align: middle;"><a href="mailto:info@learn.online"
                                    style="text-decoration:none;color:black"><img src="https://demo.api.maggana.com/media/email/email02/email.png"
                                        style="width: 20px;vertical-align: middle;padding:5px;">info@learn.online</a>
                            </div>
                        </td>

                    </tr>
                </table>
            </td>
        </tr>
    </table>`
        }
    }
}
</script>

<style>

</style>