import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

import TestInfo from "../views/AssessmentTest/InfomationView.vue";
import MCQ from "../views/AssessmentTest/McqView.vue";
import AdminDashboard from "../views/LeadManagement/LeadDashboardView.vue";
import EnrollmentDashboard from "../views/LeadManagement/EnrollmentView.vue"
import assesmentMcq from "../views/LeadManagement/assessmentDashboardView.vue";
import StudentDashboard from "../views/LeadManagement/StudentDashboard.vue";
import UniversityView from "../views/LeadManagement/UniversityView.vue";
import ProgramView from "../views/LeadManagement/ProgramView.vue";
import CPdashboardView from "../views/Dashboards/CPdashboardView.vue";
import ISTdashboardView from "../views/Dashboards/ISTdashboardView.vue";
import TPOdashboardView from "../views/Dashboards/TPOdashboardView.vue";
import LoginView from "../views/LoginView.vue";
import EnquiryView from "../views/Enquiry.vue";
import WebinarsView from "../views/LeadManagement/WebinarsView.vue";
import ProfileEdit from "../views/LeadManagement/EditProfile.vue";
import UserBatchView from "../views/LeadManagement/UserBatchView.vue";
import ThankyouView from "../views/LeadManagement/ThankyouView.vue";
import StaffEnrollment from "../views/LeadManagement/StaffEnrollmentView.vue";

//Communication

import Communication from "../views/Mail/CommunicationView.vue";
import EmailEditor from "../views/Mail/EmailEditorView.vue"




const routes = [
    {
        path: "/:slug?",
        name: "Register",
        component: EnquiryView,
        props: router => ({
            slug: router.params.slug,
        }),
    },
    
    {
        path: "/test-info",
        name: "TestInfo",
        component: TestInfo,
    },
    {
        path: "/mcq-test/",
        name: "MCQ",
        component: MCQ,
        meta: { requiresAuth: true },
    },
    {
        path: "/admin-dashboard",
        name: "adminDashboard",
        component: AdminDashboard,
        meta: { requiresAuth: true },
    },
    {
        path: "/assessment/dashboard",
        name: "AssessmentDashboard",
        component: assesmentMcq,
        meta: { requiresAuth: true },
    },
    {
        path: "/login",
        name: "Login",
        component: LoginView,
    },
    {
        path: "/dashboard",
        name: "DashboardView",
        component: StudentDashboard,
        meta: { requiresAuth: true },
    },
    {
        path: "/all-universities",
        name: "universities",
        component: UniversityView,
        meta: { requiresAuth: true },
    },
    {
        path: "/all-programs",
        name: "programs",
        component: ProgramView,
        meta: { requiresAuth: true },
    },
    {
        path: "/cp-dashboard-View",
        name: "cpDashboard",
        component: CPdashboardView,
        meta: { requiresAuth: true },
    },
    {
        path: "/ist-dashboard-View",
        name: "istDashboard",
        component: ISTdashboardView,
        meta: { requiresAuth: true },
    },
    {
        path: "/tpo-dashboard-View",
        name: "tpoDashboard",
        component: TPOdashboardView,
        meta: { requiresAuth: true },
    },
    {
        path: "/webinars",
        name: "Webinars",
        component: WebinarsView,
        meta: { requiresAuth: true },
    },
    {
        path: "/profile/edit",
        name: "Profileedit",
        component: ProfileEdit,
        meta: { requiresAuth: true },
    },
    {
        path: "/dashboard/batch-view",
        name: "userbatch",
        component: UserBatchView,
        meta: { requiresAuth: true },
    },
    {
        path: "/dashboard/enrollment-view",
        name: "enrollment",
        component: EnrollmentDashboard,
        meta: { requiresAuth: true },
    },
    {
        path: "/dashboard/thank-you/:options",
        name: "Thankyou",
        component: ThankyouView,
        meta: { requiresAuth: true },
        props: router =>  ({
            options : router.params.options,
        })
    },
    {
        path: "/email/communication-view",
        name: "Communication",
        component: Communication,
    },
    {
        path: "/email/email-edit-view",
        name: "EmailEditorApp",
        component: EmailEditor,
    },
    {
        path: "/admin/staff-enrollment",
        name: "StaffEnrollment",
        component: StaffEnrollment,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    // if (to.params.title) {
    //     document.title = ` ${to.params.title}`
    // } else {
    //     document.title = ` ${to.name}`
    // }
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.isAuthenticated) {
            if (to != '/') {
                sessionStorage.setItem('to_url', to.path);
            }
            next('/')
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router;