<template lang="">
<div>
    <Navigation />
    <div class="w-full max-w-7xl mx-auto" style="height: calc(100vh - 80px)">    
        <div class="overflow-hidden border rounded-lg shadow-md mt-10">
            <h1 class="py-4">Assessment 1 (MCQ)</h1>
            <table class="min-w-full border-t">
                <thead class="bg-white border-b">
                    <tr class="">
                        <th scope="col" class="text-base font-medium text-gray-900 px-6 py-4">
                            #
                        </th>
                        <th scope="col" class="text-base font-medium text-gray-900 px-6 py-4">
                            Name
                        </th>
                        <th scope="col" class="text-base font-medium text-gray-900 px-6 py-4">
                            Email
                        </th>
                        <th scope="col" class="text-base font-medium text-gray-900 px-6 py-4">
                            Date
                        </th>
                        <th scope="col" class="text-base font-medium text-gray-900 px-6 py-4">
                            Mark
                        </th>
                        <th scope="col" class="text-base font-medium text-gray-900 px-6 py-4">
                            Submited
                        </th>
                        
                    </tr>
                </thead>
                <tbody> 
                    <tr v-for="(el, index) in this.queryVal" :key="el" class="bg-gray-100 border-b">
                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {{ index + 1 }}
                        </td>
                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {{ el.user.display_name }}
                        </td>
                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {{ el.user.email }}
                        </td>
                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {{ new Date(el.finished_at).toLocaleDateString('en-IN', {day: 'numeric',month: 'long',year: 'numeric'}) }}
                        </td>
                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {{ el.score }}
                        </td>
                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {{ el.completed }} 
                        </td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</template>

<script>
import Navigation from "@/components/Navigation.vue"
import axios from "axios"
export default {
    name: "assessmentDashboardView",
    data() {
        return {
            queryVal : '',
            test : 'maendran'

        }
    },
    components: {
        Navigation,
    },
    created(){
        axios.get(`${process.env.VUE_APP_API}/quiz-taker-details-all/list/`)
        .then(r=>{
            console.log(r,'res')
            if(r.data.status==200){
                this.queryVal = r.data.data
                console.log(this.queryVal)
            }
        })
    }
}
</script>

<style lang="">
    
</style>
