<template>
<div class="w-full h-full bg-gray-100">
    <Navigation />
    <div class="w-full max-w-7xl mx-auto" style="height: calc(100vh - 80px)">
        <div class="flex w-full h-80 my-1 bg-gray-100 px-2 hidden">
            <div class="w-1/3 py-2">
                <canvas id="myPieChart"></canvas>
            </div>
        </div>
        <div class="mt-4 flex justify-end">
            <div class="border px-4 py-1 border-gray-400 rounded-md" @click="this.is_popup = true">
            <button>Upload user list</button>
            </div>
        </div>
        <div v-if="this.is_popup == true" class="fixed z-50 bg-white min-h-screen w-full top-0 left-0 bg-opacity-70 flex justify-center items-center">
            <div class="max-w-2xl w-full border h-[200px] rounded-md shadow-xl flex flex-col items-start justify-center p-10 bg-white relative border-gray-300">
                <div class="absolute top-4 right-4 cursor-pointer" @click="this.is_popup = false">
                    <img src="../../assets/close.png" alt="" class="h-6">
                </div>
                <form @submit.prevent="fileUpload" class="flex flex-col gap-4 items-start">
                    <label for="fileUpload_csv">File Upload</label>
                    <input type="file" id="fileUpload_csv" class="border w-full rounded-md" accept=".csv">
                    <div class="flex gap-2">
                        <p class="text-xs text-gray-400">Input filed format with sample data</p>
                        <a href="http://api.lots.learn.online/media/lesson_mdfile/student_example.csv" target="_blank">
                        <img src="../../assets/download.png" alt="download" class="h-5 cursor-pointer">
                        </a>
                    </div>
                    <div class="flex gap-4 items-center">
                        <button v-if="this.ld_btn != true" class="border px-2 py-1 text-sm rounded-md hover:border-primary" type="submit">Submit</button>
                        <button v-else class="border px-2 py-1 text-sm rounded-md">Loading..</button>
                        <div v-if="this.message.text != ''">
                            <p class="text-sm font-medium text-white" :class="this.message.type=='error' ? ' text-red-600' : ' text-green-700' ">{{ this.message.text }}</p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="overflow-scroll border rounded-lg shadow-md mt-10 relative">
            <div class="flex items-center justify-between px-4">
                <h1 class="py-4 text-start px-4">User Info</h1>
                <p>Your reference ID : {{ this.userInfo.data.user.staff_profile.slug}}</p>
            </div>
            <!-- <ShareView /> -->
            <table class="min-w-full">
                <thead class="bg-white border-b">
                    <tr class="">
                        <th scope="col" class="text-base font-medium text-gray-900 px-6 py-4">
                            #
                        </th>
                        <th scope="col" class="text-base font-medium text-gray-900 px-6 py-4">
                            Name
                        </th>
                        <th scope="col" class="text-base font-medium text-gray-900 px-6 py-4">
                            Email
                        </th>
                        <th scope="col" class="text-base font-medium text-gray-900 px-6 py-4">
                            Universities
                        </th>
                        <th scope="col" class="text-base font-medium text-gray-900 px-6 py-4">
                            Qualification
                        </th>
                        <th scope="col" class="text-base font-medium text-gray-900 px-6 py-4">
                            City
                        </th>
                        <th scope="col" class="text-base font-medium text-gray-900 px-6 py-4">
                            Joined date 
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(el, index) in this.userRegisterList" :key="el" class="bg-gray-100 border-b">
                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {{ index + 1 }}
                        </td>
                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {{ el.display_name }}
                        </td>
                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {{ el.email }}
                        </td>
                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {{ el.user_data_filed[0].Universities_list_data.name }}
                        </td>
                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {{ el.user_data_filed[0].Highest_qualification_data.name}}
                        </td>
                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {{ el.user_data_filed[0].city_data.name }}
                        </td>
                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {{ this.dateFormat(el.date_joined) }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
import Chart from "chart.js/auto";
import axios from "axios";
import ShareView from "@/components/shareView.vue";
import convertdate from '@/services/DateConvert.js';
import {
    mapActions,
    mapGetters
} from "vuex";
export default {
    data() {
        return {
            userRegisterList: '',
            ld_btn : false,
            is_popup : false
        };
    },
    computed: mapGetters(["userInfo", "isAuthenticated"]),
    components: {
        Navigation,
        ShareView
    },
    created() {
        this.userInfoGet()
    },
    methods: {
        dateFormat(el){
             return convertdate.dateFormatDDMMYYYY(el);
        },
        async userInfoGet(){
           await axios.get(`${process.env.VUE_APP_API}/profile_all/?user_data_filed__referens__handler_type=ist&Key=${this.userInfo.data.token}`)
            .then(resp=>{
                this.userRegisterList = resp.data.data
            })
        },
        fileUpload(el){
            this.ld_btn = true
            let bodyval = new FormData()
            bodyval.append("my_file", el.target.fileUpload_csv.files[0])
            axios.post(`${process.env.VUE_APP_API}/student/data_upload/?Key=${this.userInfo.data.token}`,bodyval)
            .then(resp=>{
                console.log(resp.status==200)
                if(resp.status==200){
                    this.message.text = 'Data uploaded successfully'
                    this.message.type='success'
                }else{
                    this.message.text = 'check the fields'
                    this.message.type='error'
                }
            }).catch(error =>{
                console.log(error)
            })
            setTimeout(()=>{
                this.ld_btn = false
                this.message.text=''
                this.message.type=''
            },3000)
        }
    },
};
</script>