<template>
    <div class="flex gap-2 h-full">
    <div class="w-1/2 flex flex-col gap-2 px-2">
      <div class="flex flex-col text-start h-full">
        <textarea
          name=""
          id=""
          cols="30"
          rows="20"
          v-model="content"
          class="form-control block px-3 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding focus:ring-0 focus:border-primary border-[#e5e7eb] rounded shadow-md focus:outline-none w-full h-full"
        ></textarea>
      </div>
    </div>
    <div class="h-full">
      <div v-html="content"></div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            content: `<table align="center" style="margin:auto; width:600px;background-color: white;border:solid 1px #D1D5DB;">
        <tr>
            <td style="padding: 20px;">
                <img src="https://demo.api.maggana.com/media/email/email03/headlogo.png" style="width:200px;">
            </td>
        </tr>
        <tr>
            <td>
                <!-- Image Map Generated by http://www.image-map.net/ -->
                <img src="https://demo.api.maggana.com/media/email/email03/bg.png" usemap="#image-map" style="width:600px;">

                <map name="image-map">
                    <area target="_blank" alt="map" title=" " href="https://lots.learn.online/" coords="40,195,180,230"
                        shape="rect">
                </map>
            </td>
        </tr>
        <tr>
            <td style="padding:5px 30px;font-size: 14px;font-weight: 500;">
                <p style="font-weight: bold;">Hello (Name),</p>
                <p>Your Learn.Online Test for scholarship has expired.</p>
                <p>But Wait!</p>
                <p>Here's a chance for you to reactivate it now and avail up to 50% scholarship on Online Degree
                    Programs. </p>
                <p>Hurry Up! This is a limited-period offer!</p>
                <p style="text-align: center;">
                    <a href="https://lots.learn.online/" target="_blank">
                        <button
                            style="padding: 10px 15px;background-color:#231f20;border-radius: 10px;color:white;
                    text-align: center;font-weight: bold;border-radius: 30px;font-size: 18px;cursor: pointer;;">ACTIVATE
                            NOW</button>
                    </a>
                </p>
            </td>
        </tr>

        <tr style="border-top: 1px solid #876c20;">
            <td>
                <table style="border-collapse: collapse;width: 100%;">
                    <tr style="background-color: #face4b;padding: 0px 10px;text-align: center;">
                        <td style=" width: calc(600px / 3);font-size:15px;padding: 5px 0px;">
                            <div style="vertical-align: middle;border-right:1px solid black;font-weight: 500;"><a
                                    href="tel:+917005997005" style="text-decoration:none;color:black"><img
                                        src="https://demo.api.maggana.com/media/email/email03/call.png" style="width: 20px;vertical-align: middle;padding:5px;">+91 70059
                                    97005</a>
                            </div>
                        </td>
                        <td style=" width: calc(600px / 3);font-size:15px;padding: 5px 0px;">
                            <div style="vertical-align: middle;border-right:1px solid black;font-weight: 500;"><a
                                    href="https://lots.learn.online/" style="text-decoration:none;color:black"><img
                                        src="https://demo.api.maggana.com/media/email/email03/globe.png"
                                        style="width: 20px;vertical-align: middle;padding:5px;">www.lots.learn.online</a>
                            </div>
                        </td>
                        <td style=" width: calc(600px / 3);font-size:15px;padding: 5px 0px;font-weight: 500;">
                            <div style="vertical-align: middle;"><a href="mailto:info@learn.online"
                                    style="text-decoration:none;color:black"><img src="https://demo.api.maggana.com/media/email/email03/email.png"
                                        style="width: 20px;vertical-align: middle;padding:5px;">info@learn.online</a>
                            </div>
                        </td>

                    </tr>
                </table>
            </td>
        </tr>
    </table>`
        }
    }
}
</script>

<style>

</style>