<template>
    <div class="flex gap-2 h-full">
    <div class="w-1/2 flex flex-col gap-2 px-2">
      <div class="flex flex-col text-start h-full">
        <textarea
          name=""
          id=""
          cols="30"
          rows="20"
          v-model="content"
          class="form-control block px-3 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding focus:ring-0 focus:border-primary border-[#e5e7eb] rounded shadow-md focus:outline-none w-full h-full"
        ></textarea>
      </div>
    </div>
    <div class="h-full">
      <div v-html="content"></div>
    </div>
  </div>
    
</template>

<script>
export default {
    data(){
        return{
            content: `<table align="center" style="margin:auto; width:600px;background-color: white;border:solid 1px #D1D5DB;">
        <tr>
            <td style="background-color: #face4b;padding:10px">
                <div style="background-image:url(bg1.png);background-repeat: no-repeat;
                    background-position: center;background-size: cover;padding-bottom: 50px;border:1px solid black">
                    <img src="https://demo.api.maggana.com/media/email/email04/logo1.png" style="width:600px">

                    <div style="text-align:center;font-size: 23px;font-weight: 700px;line-height: 50px;">
                        <span style="font-size: 45px;font-weight:bold;">
                            Congratulations!
                        </span> <br>

                        <span style="font-size: 15px;text-align:center">
                            for clearing the Learn.Online Test for scholarship
                        </span><br>

                        <div style="line-height: 25px;">This is to certify that<br>XXX</div><br>

                        <div
                            style="width:355px;background-color:black;height:1px;margin-left:125px;margin-top:-30px;margin-bottom: 15px;">
                        </div>

                        <div style="line-height:30px ;">Unlocked <span
                                style="color:white;font-weight:bold;font-size:35px">50%</span> scholarship from <br>
                            Learn.Online for
                            Online Degree
                            Programs.
                        </div>
                    </div>
                </div>
            </td>
        </tr>

        <tr>
            <td style="background-image:url(https://demo.api.maggana.com/media/email/email04/bg2.png);background-repeat: no-repeat;
                    background-position: center;background-size: cover;padding-bottom: 100px;" align="center">

                <div style="text-align: center;font-weight:600;">
                    <p style="font-size:30px;text-align: center;">Just one more step...</p>
                    <p style="font-size:20px;text-align: center;">Select University & Program</p>
                    <p style="text-align: center;padding: 10px 0px;">
                        <a href="https://lots.learn.online/" target="_blank">
                            <button
                                style="padding: 8px 25px;background-color:#231f20;border-radius: 10px;color:white;
                                        text-align: center;font-weight: bold;border-radius: 30px;font-size: 22px;cursor: pointer;;">
                                SELECT NOW
                            </button>
                        </a>
                    </p>

                </div>
            </td>
        </tr>


        <tr>
            <td>
                <table style="border-collapse: collapse;width: 100%;">
                    <tr style="background-color: #face4b;padding: 0px 10px;text-align: center;">
                        <td style=" width: calc(600px / 3);font-size:15px;padding: 5px 0px;">
                            <div style="vertical-align: middle;border-right:1px solid black;font-weight: 500;"><a
                                    href="tel:+917005997005" style="text-decoration:none;color:black"><img
                                        src="https://demo.api.maggana.com/media/email/email04/call.png" style="width: 20px;vertical-align: middle;padding:5px;">+91 70059
                                    97005</a>
                            </div>
                        </td>
                        <td style=" width: calc(600px / 3);font-size:15px;padding: 5px 0px;">
                            <div style="vertical-align: middle;border-right:1px solid black;font-weight: 500;"><a
                                    href="https://lots.learn.online/" style="text-decoration:none;color:black"><img
                                        src="https://demo.api.maggana.com/media/email/email04/globe.png"
                                        style="width: 20px;vertical-align: middle;padding:5px;">www.lots.learn.online</a>
                            </div>
                        </td>
                        <td style=" width: calc(600px / 3);font-size:15px;padding: 5px 0px;font-weight: 500;">
                            <div style="vertical-align: middle;"><a href="mailto:info@learn.online"
                                    style="text-decoration:none;color:black"><img src="https://demo.api.maggana.com/media/email/email04/email.png"
                                        style="width: 20px;vertical-align: middle;padding:5px;">info@learn.online</a>
                            </div>
                        </td>

                    </tr>
                </table>
            </td>
        </tr>
    </table>`
        }
    }
}
</script>

<style>

</style>