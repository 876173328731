<template>
  <!-- Left Navigation -->
            <div id="navbarContainer" class="-translate-x-80 md:translate-x-0 fixed top-[68px] z-[1000] md:top-0 md:relative md:block h-full w-[320px] min-h-screen overflow-y-auto bg-primary shadow-xl" style="transition: transform 0.3s ease;">
                    <div class="hidden md:flex h-[120px] justify-center items-center">
                        <img src="../assets/logo-black.png" class="w-[120px]" />
                    </div>
                    <div class="flex flex-row md:flex-col items-center gap-4 px-5 mt-5 md:mt-0">
                        <div class="md:w-full flex justify-center">
                            <img v-if="this.userProfiileInfo[0].user_data_filed[0].profile" :src="this.userProfiileInfo[0].user_data_filed[0].profile" class="h-[80px] w-[80px] md:h-[160px] md:w-[160px] rounded-full bg-white" />
                            <img v-else src="../assets/man.png" class="h-[80px] w-[80px] md:h-[160px] md:w-[160px] rounded-full bg-white" />
                        </div>
                        <div class="flex flex-col items-start md:items-center gap-1">
                            <h3 class="text-base font-semibold capitalize" v-if="isAuthenticated" >{{ this.userProfiileInfo[0].display_name }}</h3>
                            <router-link :to="{name:'Profileedit'}">
                            <span class="text-sm text-white font-semibold">Edit profile</span>
                            </router-link>
                        </div>
                    </div>
                    <div class="px-5 py-5 md:py-10 flex flex-col gap-2">
                            <router-link :to="{name:'DashboardView'}">
                                <div class="flex gap-3 items-center justify-start py-3 px-5 text-sm rounded-md cursor-pointer hover:bg-white" :class="this.currentTap=='home' ? 'bg-white' : 'bg-primary'">
                                    <img src="../assets/home.png" class="w-6" />
                                    <h5 class="pt-1">Home</h5>
                                </div>
                            </router-link>
                            <router-link :to="{name:'programs'}">
                                <div class="flex gap-3 items-center justify-start py-3 px-5 text-sm rounded-md cursor-pointer hover:bg-white" :class="this.currentTap=='Programs' ? 'bg-white' : 'bg-primary'">
                                    <img src="../assets/code.png" class="w-6" />
                                    <h5 class="pt-1">Programs</h5>
                                </div>
                            </router-link>
                            <router-link :to="{name:'universities'}">
                                <div class="flex gap-3 items-center justify-start py-3 px-5 text-sm rounded-md cursor-pointer hover:bg-white" :class="this.currentTap=='Universities' ? 'bg-white' : 'bg-primary'">
                                    <img src="../assets/school.png" class="w-6" />
                                    <h5 class="pt-1">Universities</h5>
                                </div>
                            </router-link>
                                <div class="flex gap-3 items-center justify-start py-3 px-5 text-sm rounded-md cursor-pointer hover:bg-white" @click="logOut" :class="this.currentTap=='logout' ? 'bg-white' : 'bg-primary'">
                                    <img src="../assets/logout.png" class="w-6" />
                                    <h5 class="pt-1">Sign out</h5>
                                </div>
                    </div>
            </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios"
export default {
    data(){
        return{
        }
    },
    props:['currentTap'],
    computed: mapGetters(["userInfo", "isAuthenticated","userProfiileInfo"]),
    async created(){
        
    },
    methods :{
        ...mapActions(["logOutUser"]),
        async logOut() {
        await this.logOutUser();
        if (this.isAuthenticated == false) {
            this.$router.push({ name: "Login" });
         }
    },
    
    }
}
</script>

<style>

</style>