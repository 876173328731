<template>
  <div class="w-full h-full">
    <Navigation />
    <div class="min-h-screen max-w-7xl mx-auto">
      <div
        v-if="this.profileDetails.length > 0"
        class="p-4 flex flex-col gap-4 relative"
      >
        <h2 class="text-start font-semibold pl-6 text-lg">
          Edit <span class="text-primary font-medium">Profile</span>
        </h2>
        <div
          class="border border-gray-100 min-h-[550px] shadow-lg rounded-md p-3 md:p-10 flex flex-col gap-10"
        >
          <!-- flash message -->
          <div
            v-if="this.message.text != ''"
            class="fixed top-[100px] right-14"
          >
            <h4
              class="pl-2 pr-8 py-1 text-white rounded-md relative"
              :class="
                this.message.type == 'error' ? 'bg-red-600' : 'bg-green-600'
              "
            >
              {{ this.message.text }}
              <span
                @click="(this.message.text = ''), (this.message.type = '')"
                class="text-white absolute right-2 cursor-pointer"
                >X</span
              >
            </h4>
          </div>
          <!-- 1 -->
          <div
            class="flex flex-col md:flex-row md:justify-start md:items-end gap-4"
          >
            <div>
              <img
                v-if="this.profileDetails[0].user_data_filed[0].profile"
                :src="this.profileDetails[0].user_data_filed[0].profile"
                class="h-[80px] w-[80px] md:h-[160px] md:w-[160px] rounded-full bg-white"
              />
              <img
                v-else
                src="../../assets/man.png"
                class="h-[80px] w-[80px] md:h-[160px] md:w-[160px] rounded-full bg-white"
              />
            </div>
            <form action="" @submit.prevent="profileImgUpdate">
              <div class="flex flex-col gap-2 justify-start text-start text-xs">
                <input
                  type="file"
                  id="img_upload"
                  hidden
                  accept="image/png, image/jpeg, image/jpg, image/webp"
                  @change="img_sub = true"
                />
                <label
                  for="img_upload"
                  class="bg-primary inline w-24 py-1.5 px-2 rounded-md font-medium"
                  >Choose a file</label
                >
                <p class="font-medium">
                  Acceptable format:jpg,png only <br />
                  Max file size is 500kb & min size 70kb
                </p>
                <div>
                  <button
                    v-if="img_sub == true"
                    class="px-4 py-1 bg-primary rounded-md"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
          <!-- 2 -->
          <form @submit.prevent="this.userProfileUpdate">
            <div class="flex flex-col gap-4">
              <h2 class="capitalize text-start font-semibold text-lg">
                Account information
              </h2>
              <!-- Account Information -->
              <div
                class="flex flex-col lg:flex-row gap-4 lg:justify-start lg:py-10"
              >
                <!-- 1 -->
                <div class="w-full lg:w-1/2 flex flex-col gap-6 pr-10">
                  <div
                    class="flex flex-col gap-1 items-start md:gap-0 md:flex-row md:justify-start"
                  >
                    <label
                      for="first_name"
                      class="text-sm md:text-base text-start w-[40%]"
                      >First Name</label
                    >
                    <div class="w-[60%]">
                      <div class="relative w-[320px]">
                        <input
                          disabled
                          v-model="this.profileDetails[0].display_name"
                          id="first_name"
                          type="text"
                          class="border border-gray-300 rounded-md h-8 md:h-10 w-[320px] text-sm md:text-base pr-6 focus:outline-none focus:border-0 focus:ring-1 focus:ring-[#ffd300]"
                        />
                        <img
                          src="../../assets/edit-icon.png"
                          alt=""
                          class="absolute top-3 right-3 cursor-pointer"
                          @click="editProfileInfo"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex flex-col gap-1 items-start md:gap-0 md:flex-row md:justify-start"
                  >
                    <label
                      for="phone_number"
                      class="text-sm md:text-base text-start w-[40%]"
                      >Phone Number</label
                    >
                    <div class="w-[60%]">
                      <div class="relative w-[320px]">
                        <input
                          disabled
                          v-model="this.profileDetails[0].phone"
                          maxlength="10"
                          onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                          id="phone_number"
                          type="text"
                          class="border border-gray-300 rounded-md h-8 md:h-10 w-[320px] text-sm md:text-base pr-6 focus:outline-none focus:border-0 focus:ring-1 focus:ring-[#ffd300]"
                        />
                        <img
                          src="../../assets/edit-icon.png"
                          alt=""
                          class="absolute top-3 right-3 cursor-pointer"
                          @click="editProfileInfo"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex flex-col gap-1 items-start md:gap-0 md:flex-row md:justify-start"
                  >
                    <label
                      for="email_id"
                      class="text-sm md:text-base text-start w-[40%]"
                      >E-mail</label
                    >
                    <div class="w-[60%]">
                      <div class="relative w-[320px]">
                        <input
                          disabled
                          v-model="this.profileDetails[0].email"
                          id="email_id"
                          type="text"
                          class="border border-gray-300 rounded-md h-8 md:h-10 w-[320px] text-sm md:text-base pr-6 focus:outline-none focus:border-0 focus:ring-1 focus:ring-[#ffd300]"
                        />
                        <img
                          src="../../assets/edit-icon.png"
                          alt=""
                          class="absolute top-3 right-3 cursor-pointer hidden"
                          @click="editProfileInfo"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 2 -->
                <div
                  class="w-full lg:w-1/2 flex flex-col gap-6 md:pr-10 lg:pl-10"
                >
                  <div
                    class="flex flex-col gap-1 items-start md:gap-1 md:flex-row md:ite md:justify-start"
                  >
                    <label
                      for="user_university"
                      class="text-sm md:text-base text-start w-[40%]"
                      >College/University</label
                    >
                    <!-- University drop down -->
                    <div class="w-[60%]">
                      <div class="relative w-[320px]">
                        <input v-if="this.profileDetails[0].user_data_filed[0].Universities_list_data"
                          type="text"
                          name=""
                          id="user_university_model" disabled
                          v-model="
                            this.profileDetails[0].user_data_filed[0]
                              .Universities_list_data.name
                          "
                          class="border border-gray-300 rounded-md h-8 md:h-10 w-[320px] text-sm md:text-base pr-6 focus:outline-none focus:border-0 focus:ring-1 focus:ring-[#ffd300]"
                        />
                        <input v-else
                          type="text"
                          name="" value="Null" disabled
                          id="user_university_model"
                          class="border border-gray-300 rounded-md h-8 md:h-10 w-[320px] text-sm md:text-base pr-6 focus:outline-none focus:border-0 focus:ring-1 focus:ring-[#ffd300]"
                        />
                        <img
                          src="../../assets/edit-icon.png"
                          alt=""
                          class="absolute top-3 right-3 cursor-pointer"
                          @click="editUniversity"
                        />
                        <input
                          list="user_university"
                          class="hidden pl-2 border border-gray-300 rounded-md h-10 w-[320px] pr-6 focus:outline-none focus:border-0 focus:ring-1 focus:ring-[#ffd300]"
                          id="uniersityselected"
                          placeholder=" College/Universities*"
                        />
                        <datalist class="" id="user_university">
                          <option
                            v-for="university in universityList"
                            :key="university"
                            :value="university.name"
                          >
                            {{ university.name }}
                          </option>
                        </datalist>
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex flex-col gap-1 items-start md:gap-0 md:flex-row md:justify-start"
                  >
                    <label
                      for="highest_qualification"
                      class="text-sm md:text-base w-[40%] text-start"
                      >Highest Qualification</label
                    >
                    <!-- Highest qualification -->
                    <div class="flex flex-col gap-1 w-[60%]">
                      <select v-if="this.profileDetails[0].user_data_filed[0].Highest_qualification_data"
                        name=""
                        id="highest_qualification"
                        v-model="
                          this.profileDetails[0].user_data_filed[0]
                            .Highest_qualification_data.id
                        "
                        class="border border-gray-300 rounded-md h-8 md:h-10 w-[320px] text-sm md:text-base pr-6 focus:outline-none focus:border-0 focus:ring-1 focus:ring-[#ffd300]"
                      >
                        <option
                          v-for="qualification in qualificationList"
                          :key="qualification"
                          :value="qualification.id"
                        >
                          {{ qualification.name }}
                        </option>
                      </select>
                      <select v-else
                        name=""
                        id="highest_qualification"
                        class="border border-gray-300 rounded-md h-8 md:h-10 w-[320px] text-sm md:text-base pr-6 focus:outline-none focus:border-0 focus:ring-1 focus:ring-[#ffd300]"
                      >
                      <option selected disabled>Select Qualification</option>
                        <option
                          v-for="qualification in qualificationList"
                          :key="qualification"
                          :value="qualification.id"
                        >
                          {{ qualification.name }}
                        </option>
                      </select>
                      <div class="flex items-center gap-2 py-2 px-2">
                        <input
                          type="checkbox"
                          id="Qua_completed"
                          v-model="this.profileDetails[0].qualification_status"
                          class="rounded focus:shadow-none focus:outline-none focus:ring-0"
                        />
                        <label for="Qua_completed" class="text-sm">
                          Pursuing</label
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex flex-col gap-1 items-start md:gap-0 md:flex-row md:justify-start"
                  >
                    <label
                      for="gender"
                      class="text-start text-sm md:text-base w-[40%] text-start"
                      >Gender</label
                    >
                    <div class="w-[60%] flex justify-start">
                      <select
                        name="gender"
                        id="genderId"
                        v-model="
                          this.profileDetails[0].user_data_filed[0].gender
                        "
                        class="h-8 md:h-10 border border-gray-200 rounded-md text-sm md:text-base focus:outline-none focus:border-0 focus:ring-1 focus:ring-[#ffd300]"
                      >
                        <option selected disabled value="null">
                          Select gender
                        </option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Third Gender</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <!-- buttons -->
              <div class="flex justify-between mt-4 text-sm font-medium">
                <router-link :to="{ name: 'DashboardView' }">
                  <button type="button" class="bg-primary px-4 py-2 rounded-md">
                    GO BACK
                  </button>
                </router-link>
                <button type="submit" class="bg-primary px-4 py-2 rounded-md">
                  SAVE CHANGES
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      profileDetails: [],
      message: {
        text: "",
        type: "",
      },
      universityList: [],
      qualificationList: [],
      img_sub: false,
      loadFile: "",
      message: {
        text: "",
        type: "",
      },
    };
  },
  components: {
    Navigation,
  },
  computed: mapGetters(["userInfo", "isAuthenticated"]),
  async created() {
    await this.profileInfo();
    await this.collectUniversity();
    await this.collectQualification();
  },
  methods: {
    ...mapActions(["userDetailsGet"]),
    editUniversity(el) {
      el.target.classList.toggle("hidden");
      el.target.previousSibling.classList.toggle("hidden");
      el.target.nextSibling.classList.toggle("hidden");
    },
    async profileInfo() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/profile/?Key=${this.userInfo.data.token}`
        )
        .then((resp) => {
          if (resp.data.status == 200) {
            this.profileDetails = resp.data.data;
          } else {
            this.messages.text = "unexpect error";
            this.messages.type = "error";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async collectUniversity() {
      await axios
        .get(`${process.env.VUE_APP_API}/universities/list/`)
        .then((resp) => {
          this.universityList = resp.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async collectQualification() {
      await axios
        .get(`${process.env.VUE_APP_API}/highestqualification/list/`)
        .then((resp) => {
          this.qualificationList = resp.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editProfileInfo(el) {
      el.target.previousSibling.removeAttribute("disabled");
      el.target.previousSibling.focus();
    },
    async profileImgUpdate() {
      let newImg = new FormData();
      let fileValue = $("#img_upload").prop("files")[0];
      newImg.append("profile", fileValue);
      await axios
        .put(
          `${process.env.VUE_APP_API}/profile_image_update/${this.userInfo.data.user.id}/?Key=${this.userInfo.data.token}`,
          newImg
        )
        .then(async (resp) => {
          if (resp.status == 200) {
            await this.profileInfo();
            await this.userDetailsGet(this.userInfo.data.token);
            this.img_sub = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async userProfileUpdate(el) {
      console.log(el);
      let targetElement = el.target.elements;
      let bodyVal = {
        name: targetElement.first_name.value,
        phone: targetElement.phone_number.value,
        universities: targetElement.uniersityselected.value
          ? targetElement.uniersityselected.value
          : targetElement.user_university_model.value,
        highestqualification: targetElement.highest_qualification.value,
        gender: targetElement.genderId.value,
        qualification: el.target.elements.Qua_completed.checked,
      };
      await axios
        .put(
          `${process.env.VUE_APP_API}/profile_update/${this.userInfo.data.user.id}/?Key=${this.userInfo.data.token}`,
          bodyVal
        )
        .then((resp) => {
          if (resp.data.status == 200) {
            this.message.text = "Profile Updated";
            this.message.type = "success";
          } else {
            this.message.text = "Profile Update Failed";
            this.message.type = "error";
          }
        });
      await this.userDetailsGet(this.userInfo.data.token);
    },
  },
};
</script>

<style></style>
