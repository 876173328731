<template lang="">
    <div class="w-full h-full bg-white">
        <div class="max-w-7xl w-full h-full flex mx-auto">
            <!-- Left Navigation -->
            <LeftNavigation currentTap="home" />
            <!-- Dashboard -->
            <div class="w-full flex flex-col gap-5 max-h-screen overflow-y-auto">
                <!-- 1 -->
                <div class="w-full h-[80px] bg-white p-4 shadow-lg flex justify-between items-center gap-5 sticky top-0 z-50">
                    <div class="visible md:invisible">
                        <img src="../../assets/logo-black.png" class="w-[100px]" />
                    </div>

                    <div class="flex items-center justify-end">
                        <div class="relative pr-3 hidden sm:block">
                            <input type="search" class="rounded-3xl h-18 border-none bg-gray-200 text-sm pl-10 focus:outline-none focus:ring-0" placeholder="Search" />
                            <i class="fa-solid fa-magnifying-glass absolute left-[15px] top-1/2 -translate-y-1/2"></i>
                        </div>                      
                        <img src="../../assets/bell.png" class="w-14 px-3" />
                         <button class="block md:hidden px-3 text-black" type="button" id="navBarIcon">
                            <i class="fa-solid fa-bars text-3xl"></i>
                         </button>
                    </div>
                </div>
                <div class="mt-4 mx-4 relative block sm:hidden">
                            <input type="search" class="rounded-3xl w-full h-18 border-none bg-gray-200 text-sm pl-10 focus:outline-none focus:ring-0" placeholder="Search" />
                            <i class="fa-solid fa-magnifying-glass absolute left-[15px] top-1/2 -translate-y-1/2"></i>
                </div>
                <div class="flex flex-col sm:flex-row sm:items-center justify-between px-4 gap-5">
                    <h2 class="text-lg font-semibold text-start">Your <span class="text-primary">Webinars</span></h2>

                    <div class="relative border border-solid border-[#6b7280] rounded flex items-center">
                    <div class="bg-primary text-black px-5 py-2 text-sm rounded">Filter</div>
                    <select class="border-none w-full pl-3 pr-10 py-2 text-sm rounded focus:ring-0">
                        <option>Recorded</option>
                        <option>Live</option>
                        <option>Informational Videos</option>
                    </select>
                    </div>
                </div>  


                <!-- section1 -->
                <!-- <div class="md:border md:h-[83vh] md:p-5 mx-4 mb-5 flex flex-col gap-5 h-full overflow-y-auto">
                    <div class="flex flex-col lg:flex-row border">
                             <div class="min-w-[300px] h-[250px] relative overflow-hidden bg-gray-200">
                                <img src="../../assets/news2.png" class="w-full h-full object-contain" />
                                <img src="../../assets/video-play-icon.png" class="w-10 h-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                              </div>
                              <div class="text-start flex flex-col gap-3 p-5">
                                <h1 class="text-black font-bold text-lg">Title</h1>
                                <p class="">Depending on what you are making, it can be a good idea to include screenshots or even a video (you'll frequently see GIFs rather than actual videos). Tools like ttygif can help, but check out Asciinema for a more sophisticated method.</p>
                                <button class="bg-primary text-black px-4 py-2 text-sm rounded scomponents:{
      LeftNavigation
  },elf-start mt-3">Watch Video</button>
                              </div>
                    </div>
                </div> -->


                <!-- section2 -->
                <div class="md:border md:h-[83vh] md:p-5 mx-4 mb-5 flex flex-col lg:flex-row gap-10 h-full">
                    <div class="w-full lg:w-7/12 flex flex-col gap-5">
                        <div class="min-w-[300px] min-h-[200px] max-h-[300px] relative overflow-hidden bg-gray-200">
                            <img src="../../assets/news2.png" class="w-full h-full object-contain" />
                            <img src="../../assets/video-play-icon.png" class="w-10 h-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                        </div>
                         <div class="flex flex-col gap-2">
                                <h1 class="text-black font-bold text-base">Title</h1>
                                <p class="text-sm">
                                    Depending on what you are making, it can be a good idea to include screenshots or even a video.
                                </p>
                          </div>
                          <div class="flex flex-col gap-3">
                                 <h2 class="text-lg font-semibold text-start bg-white sticky top-0 z-10">Upcoming <span class="text-primary">Webinars</span></h2>
                                 <div class="flex flex-row overflow-auto">
                                    <div class="flex flex-col items-center gap-5 min-w-[300px] border-r">
                                        <div class="w-10/12 h-[150px] relative overflow-hidden bg-gray-200">
                                            <img src="../../assets/news2.png" class="w-full h-full object-contain" />
                                            <img src="../../assets/video-play-icon.png"
                                                class="w-10 h-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                                        </div>
                                        <div class="text-center flex flex-col items-center gap-2 px-5">
                                            <h1 class="text-black font-bold text-sm">Title</h1>
                                            <p class="text-xs">Depending on what you are making, it can be a good idea to include screenshots or even a video.</p>
                                            <button class="bg-primary text-black px-4 py-2 text-xs rounded mt-3">Get remainder</button>
                                        </div>
                                    </div>
                                    <div class="flex flex-col items-center gap-5 min-w-[300px] border-r">
                                        <div class="w-10/12 h-[150px] relative overflow-hidden bg-gray-200">
                                            <img src="../../assets/news2.png" class="w-full h-full object-contain" />
                                            <img src="../../assets/video-play-icon.png"
                                                class="w-10 h-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                                        </div>
                                        <div class="text-center flex flex-col items-center gap-2 px-5">
                                            <h1 class="text-black font-bold text-sm">Title</h1>
                                            <p class="text-xs">Depending on what you are making, it can be a good idea to include screenshots or even a video.</p>
                                            <button class="bg-primary text-black px-4 py-2 text-xs rounded mt-3">Get remainder</button>
                                        </div>
                                    </div>
                                    <div class="flex flex-col items-center gap-5 min-w-[300px]">
                                        <div class="w-10/12 h-[150px] relative overflow-hidden bg-gray-200">
                                            <img src="../../assets/news2.png" class="w-full h-full object-contain" />
                                            <img src="../../assets/video-play-icon.png"
                                                class="w-10 h-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                                        </div>
                                        <div class="text-center flex flex-col items-center gap-2 px-5">
                                            <h1 class="text-black font-bold text-sm">Title</h1>
                                            <p class="text-xs">Depending on what you are making, it can be a good idea to include screenshots or even a video.</p>
                                            <button class="bg-primary text-black px-4 py-2 text-xs rounded mt-3">Get remainder</button>
                                        </div>
                                    </div>
                                 </div>
                          </div>
                    </div>
                    <div class="w-full lg:w-5/12 overflow-y-auto">
                        <h2 class="text-lg font-semibold text-start pb-3 bg-white sticky top-0 z-10">Recommended <span class="text-primary">Webinars</span></h2>
                        <div class="flex flex-row gap-5 pb-5 border-b">
                            <div class="min-w-[120px] h-[100px] relative overflow-hidden bg-gray-200">
                                <img src="../../assets/news2.png" class="w-full h-full object-contain" />
                                <img src="../../assets/video-play-icon.png"
                                    class="w-10 h-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                            </div>
                            <div class="text-start flex flex-col gap-2">
                                <h1 class="text-black font-bold text-sm">Title</h1>
                                <p class="text-xs">
                                    Depending on what you are making, it can be a good idea to include screenshots or even a video.
                                </p>
                            </div>
                        </div>
                        <div class="flex flex-row gap-5 py-5 border-b">
                            <div class="min-w-[120px] h-[100px] relative overflow-hidden bg-gray-200">
                                <img src="../../assets/news2.png" class="w-full h-full object-contain" />
                                <img src="../../assets/video-play-icon.png"
                                    class="w-10 h-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                            </div>
                            <div class="text-start flex flex-col gap-2">
                                <h1 class="text-black font-bold text-sm">Title</h1>
                                <p class="text-xs">
                                    Depending on what you are making, it can be a good idea to include screenshots or even a video.
                                </p>
                            </div>
                        </div>
                        <div class="flex flex-row gap-5 py-5 border-b">
                            <div class="min-w-[120px] h-[100px] relative overflow-hidden bg-gray-200">
                                <img src="../../assets/news2.png" class="w-full h-full object-contain" />
                                <img src="../../assets/video-play-icon.png"
                                    class="w-10 h-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                            </div>
                            <div class="text-start flex flex-col gap-2">
                                <h1 class="text-black font-bold text-sm">Title</h1>
                                <p class="text-xs">
                                    Depending on what you are making, it can be a good idea to include screenshots or even a video.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!-- section3 -->
                <!-- <div class="md:border md:h-[83vh] md:p-5 mx-4 mb-5 flex flex-col items-center gap-5 h-full">
                    <div class="min-w-[300px] min-h-[250px] max-h-[350px] relative overflow-hidden bg-gray-200">
                        <img src="../../assets/news2.png" class="w-full h-full object-contain" />
                        <img src="../../assets/video-play-icon.png"
                            class="w-10 h-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                    </div>
                    <div class="text-center flex flex-col items-center gap-2 px-5">
                        <h1 class="text-black font-bold text-base sm:text-lg">Title</h1>
                        <p class="text-sm sm:text-base">
                          Depending on what you are making, it can be a good idea to include screenshots or even a video.
                        </p>
                        <button class="bg-primary text-black px-4 py-2 text-xs sm:text-sm rounded mt-3">Click to Watch</button>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import LeftNavigation from "@/components/leftNavigation.vue";
export default {
  data() {
    return {};
  },
  components:{
      LeftNavigation
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style>
</style>