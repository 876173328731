<template>
  <div class="w-full h-full bg-white">
    <Navigation />
    <div
      class="relative flex w-full h-full maxwidthscreen mx-auto"
      style="min-height: calc(100vh - 80px)"
    >
      <LeftNavigation />
      <div class="w-auto h-full" style="width: calc(100vw - 60px)">
        <div class="h-full p-4">
          <!-- Select Options -->
          <div class="mt-10">
            <ul
              class="flex justify-evenly border border-gray-200 shadow-lg rounded-t-2xl font-medium cursor-pointer"
            >
              <li
                class="p-4 border-r w-[20%] relative rounded-tl-2xl"
                :class="[
                  this.selectOption == 'summary'
                    ? 'bg-primary selectOptions'
                    : '',
                ]"
              >
                Summary
              </li>
              <li
                class="p-4 border-r w-[20%] relative rounded-tl-2xl"
                :class="[
                  this.selectOption == 'Leads_details'
                    ? 'bg-primary selectOptions'
                    : '',
                ]"
              >
                Leads
              </li>
              <li
                class="p-4 border-r w-[20%] relative rounded-tl-2xl"
                :class="[
                  this.selectOption == 'college_wise'
                    ? 'bg-primary selectOptions'
                    : '',
                ]"
              >
                Colleges
              </li>
              <li
                class="p-4 border-r w-[20%] relative rounded-tl-2xl"
                :class="[
                  this.selectOption == 'location'
                    ? 'bg-primary selectOptions'
                    : '',
                ]"
              >
                Location
              </li>
              <li
                class="p-4 border-r w-[20%] relative rounded-tr-2xl"
                :class="[
                  this.selectOption == 'incentive'
                    ? 'bg-primary selectOptions'
                    : '',
                ]"
              >
                Incentive
              </li>
            </ul>
          </div>
          <Transition>
            <div
              v-if="this.is_popup == true"
              class="fixed z-50 bg-white min-h-screen w-full top-0 left-0 bg-opacity-90 flex justify-center items-center"
            >
              <div
                class="max-w-2xl w-full border h-[320px] rounded-md shadow-lg flex flex-col items-start justify-center p-10 bg-white relative border-gray-300"
              >
                <div
                  class="absolute top-4 right-4 cursor-pointer"
                  @click="this.is_popup = false"
                >
                  <img src="../../assets/close.png" alt="" class="h-6" />
                </div>
                <form
                  ref="fileUploadFiled"
                  @submit.prevent="fileUpload"
                  class="flex flex-col gap-5 items-start w-full"
                >
                  <div class="flex flex-col items-start w-[80%] gap-2">
                    <label
                      for="batch_name"
                      class="bulkUpload text-base font-medium"
                      >Batch Name</label
                    >
                    <input
                      type="text"
                      id="batch_name"
                      class="border border-gray-300 w-full rounded-md h-7 focus:border-0 focus:ring-[#FEC520]"
                      required
                    />
                  </div>
                  <div class="flex flex-col items-start gap-2">
                    <label
                      for="fileUpload_csv"
                      class="bulkUpload text-base font-medium"
                      >File Upload
                    </label>
                    <input
                      type="file"
                      id="fileUpload_csv"
                      class="border w-full rounded-md"
                      accept=".csv"
                      required
                    />
                    <div class="flex gap-2">
                      <p class="text-xs text-gray-400">
                        Input field format with sample data
                      </p>
                      <a
                        href="https://api.lots.learn.online/media/lesson_mdfile/student_example.csv"
                        target="_blank"
                      >
                        <img
                          src="../../assets/download.png"
                          alt="download"
                          class="h-5 cursor-pointer"
                        />
                      </a>
                    </div>
                  </div>
                  <div class="flex gap-4 items-center">
                    <button
                      v-if="this.ld_btn != true"
                      class="border px-2 py-1 text-sm font-medium rounded-md bg-[#FEC52D] hover:text-white"
                      type="submit"
                    >
                      Submit
                    </button>
                    <button
                      v-else
                      class="border px-2 py-1 text-sm rounded-md bg-[#FEC52D]"
                    >
                      Loading..
                    </button>
                    <div v-if="this.message.text != ''">
                      <p
                        class="text-sm font-medium text-white"
                        :class="
                          this.message.type == 'error'
                            ? ' text-red-600'
                            : ' text-green-700'
                        "
                      >
                        {{ this.message.text }}
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Transition>

          <div
            v-if="this.userCountVal.isData"
            class="flex flex-col w-full px-2 mt-10 items-center border border-b-0 bg-white py-6 rounded-t-md shadow-md"
          >
            <circleChart
              :chart1="this.userCountVal.allUser"
              :chart2="this.userCountVal.allUserVerified"
              :chart3="this.userCountVal.exams_done"
              :chart4="this.userCountVal.students_applied_elective"
              :chart5="this.userCountVal.enrollment"
              :chart6="this.userCountVal.downloadbrochure"
            />
          </div>
          <div
            class="flex w-full px-2 mt-10 items-center border border-b-0 bg-white py-6 rounded-t-md shadow-md"
          >
            <div class="flex justify-between items-center gap-2 w-full">
              <div class="flex px-6 w-52 items-center">
                <h1 v-if="select_val == 'all_user'">
                  Total count:
                  <span class="py-4 font-semibold">{{
                    this.userRegisterList.total_objects
                  }}</span>
                </h1>
                <h1 v-else-if="select_val == 'cp'">
                  Total count:
                  <span class="py-4 font-semibold">{{
                    this.cpList.length
                  }}</span>
                </h1>
                <!-- exam result -->
                <h1 v-else>
                  Total count:
                  <span class="py-4 font-semibold">{{
                    this.examsResultsList.count
                  }}</span>
                </h1>
              </div>
              <div class="flex justify-end items-center gap-2 w-full">
                <div class="flex gap-2 items-center">
                  <router-link :to="{ name: 'userbatch' }">
                    <button
                      class="bg-primary py-2 text-sm font-normal text-gray-700 bg-clip-padding rounded shadow-md w-40"
                    >
                      Batch details
                    </button>
                  </router-link>
                  <router-link :to="{ name: 'enrollment' }">
                    <button
                      class="bg-primary py-2 text-sm font-normal text-gray-700 bg-clip-padding rounded shadow-md w-40"
                    >
                      Enrollment
                    </button>
                  </router-link>
                </div>
                <div class="hidden">
                  <button
                    class="bg-primary py-2 text-sm font-normal text-gray-700 bg-clip-padding rounded shadow-md w-40"
                  >
                    Export to Excel
                  </button>
                </div>
                <div class="relative">
                  <button
                    class="bg-primary py-2 text-sm font-normal text-gray-700 bg-clip-padding rounded shadow-md w-36 flex gap-2 justify-around px-2 items-center"
                    @click="this.filterList = !this.filterList"
                  >
                    <img
                      src="../../assets/filter.png"
                      alt="filter-icon"
                      class="h-4"
                    />
                    Filter columns
                  </button>
                  <Transition>
                    <ul
                      v-if="
                        this.filterList == true && this.select_val == 'all_user'
                      "
                      class="absolute bg-white min-w-36 border flex flex-col gap-2 w-52"
                      style="z-index: 5000"
                    >
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="checkboxFilter"
                          checked
                          disabled
                          value="name"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="name_chk"
                        />
                        <label for="name_chk">Name</label>
                      </li>
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="checkboxFilter"
                          checked
                          disabled
                          value="email"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="email_chk"
                        />
                        <label for="email_chk">Email</label>
                      </li>
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="checkboxFilter"
                          checked
                          value="university"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="university_chk"
                        />
                        <label for="university_chk">University</label>
                      </li>
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="checkboxFilter"
                          checked
                          value="qualification"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="qualification_chk"
                        />
                        <label for="qualification_chk">Qualifications</label>
                      </li>
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="checkboxFilter"
                          checked
                          value="state"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="state_chk"
                        />
                        <label for="state_chk">State</label>
                      </li>
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="checkboxFilter"
                          checked
                          value="city"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="city_chk"
                        />
                        <label for="city_chk">City</label>
                      </li>
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="checkboxFilter"
                          checked
                          value="contact"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="contact_chk"
                        />
                        <label for="contact_chk">Contact</label>
                      </li>
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="checkboxFilter"
                          checked
                          value="source"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="source_chk"
                        />
                        <label for="source_chk">Source</label>
                      </li>
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="checkboxFilter"
                          checked
                          value="joined"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="joined_chk"
                        />
                        <label for="medium_chk">joined date</label>
                      </li>
                    </ul>
                    <ul
                      v-else-if="
                        this.filterList == true &&
                        this.select_val == 'exam_result'
                      "
                      class="absolute bg-white min-w-36 border flex flex-col gap-2 w-52"
                      style="z-index: 5000"
                    >
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="checkboxFilterExam"
                          checked
                          disabled
                          value="name"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="name_chk"
                        />
                        <label for="name_chk">Name</label>
                      </li>
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="checkboxFilterExam"
                          checked
                          disabled
                          value="email"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="email_chk"
                        />
                        <label for="email_chk">Email</label>
                      </li>
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="checkboxFilterExam"
                          checked
                          value="mark"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="mark_chk"
                        />
                        <label for="mark_chk">Mark</label>
                      </li>
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="checkboxFilterExam"
                          checked
                          value="scholarship"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="scholarship_chk"
                        />
                        <label for="scholarship_chk">Scholarship</label>
                      </li>
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="checkboxFilterExam"
                          checked
                          value="coupon"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="coupon_chk"
                        />
                        <label for="coupon_chk">Coupon</label>
                      </li>
                    </ul>
                    <ul
                      v-else-if="
                        this.filterList == true && this.select_val == 'applied'
                      "
                      class="absolute bg-white min-w-36 border flex flex-col gap-2 w-52"
                      style="z-index: 5000"
                    >
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="fieldFilterapplyele"
                          checked
                          disabled
                          value="name"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="name_chk"
                        />
                        <label for="name_chk">Name</label>
                      </li>
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="fieldFilterapplyele"
                          checked
                          disabled
                          value="email"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="email_chk"
                        />
                        <label for="email_chk">Email</label>
                      </li>
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="fieldFilterapplyele"
                          checked
                          value="phone"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="mark_chk"
                        />
                        <label for="phone_chk">Phone</label>
                      </li>
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="fieldFilterapplyele"
                          checked
                          value="elective"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="scholarship_chk"
                        />
                        <label for="elective_chk">Elective</label>
                      </li>
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="fieldFilterapplyele"
                          checked
                          value="university"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="coupon_chk"
                        />
                        <label for="university_chk">University</label>
                      </li>
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="fieldFilterapplyele"
                          checked
                          value="date"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="coupon_chk"
                        />
                        <label for="date_chk">Date</label>
                      </li>
                    </ul>
                    <ul
                      v-else-if="
                        this.filterList == true &&
                        this.select_val == 'downloadbr'
                      "
                      class="absolute bg-white min-w-36 border flex flex-col gap-2 w-52"
                      style="z-index: 5000"
                    >
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="fieldFilterdownload"
                          checked
                          disabled
                          value="name"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="name_chk"
                        />
                        <label for="name_chk">Name</label>
                      </li>
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="fieldFilterdownload"
                          checked
                          disabled
                          value="email"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="email_chk"
                        />
                        <label for="email_chk">Email</label>
                      </li>
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="fieldFilterdownload"
                          checked
                          value="phone"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="mark_chk"
                        />
                        <label for="phone_chk">Phone</label>
                      </li>
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="fieldFilterdownload"
                          checked
                          value="elective"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="scholarship_chk"
                        />
                        <label for="elective_chk">Elective</label>
                      </li>
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="fieldFilterdownload"
                          checked
                          value="university"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="coupon_chk"
                        />
                        <label for="university_chk">University</label>
                      </li>
                      <li class="border-b py-2 flex gap-4 px-2 items-center">
                        <input
                          type="checkbox"
                          v-model="fieldFilterdownload"
                          checked
                          value="date"
                          class="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2"
                          id="coupon_chk"
                        />
                        <label for="date_chk">Date</label>
                      </li>
                    </ul>
                  </Transition>
                </div>
                <div class="flex gap-2 items-center">
                  <!-- <label for="fieldFilter text-lg">Filter</label> -->
                  <select
                    name=""
                    id=""
                    @change="userFilterCall"
                    class="form-select appearance-none block py-2 text-sm font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat focus:ring-0 focus:border-primary border-[#e5e7eb] rounded shadow-md focus:outline-none w-64"
                  >
                    <option selected value="All Users" class="text-gray-400">
                      All Users
                    </option>
                    <option value="Direct Users" class="">Direct Users</option>
                    <option value="Raw Users" class="">Raw Users</option>
                    <option value="Exam details" class="">Exam details</option>
                    <option value="Applied elective" class="">
                      Applied Electives
                    </option>
                    <option value="Applied university" class="">
                      Applied Universities
                    </option>
                    <option value="Download Brochure" class="">
                      Download Brochure
                    </option>
                    <option value="Channel Partner" class="">
                      Channel Partner
                    </option>
                  </select>
                </div>
                <div class="flex justify-end">
                  <div
                    class="border px-4 py-1 border-gray-400 rounded-md bg-white"
                    @click="this.is_popup = true"
                  >
                    <button>Upload user list</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="select_val == 'all_user'"
            class="overflow-scroll maxwidthscreen containerHead border border-t-0 shadow-md text-start bg-white"
            style="height: calc(100vh - 80px)"
          >
            <table class="min-w-full bg-white">
              <thead class="bg-primary border-b text-start sticky top-0">
                <tr class="">
                  <th
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    S.No
                  </th>
                  <th
                    v-if="checkboxFilter.includes('name')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Name
                  </th>
                  <th
                    v-if="checkboxFilter.includes('email')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Email
                  </th>
                  <th
                    v-if="checkboxFilter.includes('university')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Universities
                  </th>
                  <th
                    v-if="checkboxFilter.includes('qualification')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Qualification
                  </th>
                  <th
                    v-if="checkboxFilter.includes('state')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    State
                  </th>
                  <th
                    v-if="checkboxFilter.includes('city')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    City
                  </th>
                  <th
                    v-if="checkboxFilter.includes('source')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Source
                  </th>
                  <th
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Reference
                  </th>
                  <th
                    v-if="checkboxFilter.includes('joined')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Registration date
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(el, index) in this.userRegisterList.data"
                  :key="el"
                  class="border-b"
                >
                  <td
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ index + 1 }}
                  </td>
                  <td
                    v-if="checkboxFilter.includes('name')"
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ el.display_name }}
                  </td>
                  <td
                    v-if="checkboxFilter.includes('email')"
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ el.email }}
                  </td>
                  <td
                    v-if="checkboxFilter.includes('university')"
                    class="text-sm text-gray-900 font-normal px-6 py-4"
                  >
                    <div
                      v-if="
                        el.user_data_filed.length > 0 &&
                        el.user_data_filed[0].Universities_list_data
                      "
                    >
                      {{ el.user_data_filed[0].Universities_list_data.name }}
                    </div>
                    <div v-else>Null</div>
                  </td>
                  <td
                    v-if="checkboxFilter.includes('qualification')"
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    <div
                      v-if="
                        el.user_data_filed.length > 0 &&
                        el.user_data_filed[0].Highest_qualification_data
                      "
                    >
                      {{
                        el.user_data_filed[0].Highest_qualification_data.name
                      }}
                    </div>
                    <div v-else>Null</div>
                  </td>
                  <td
                    v-if="checkboxFilter.includes('state')"
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    <div
                      v-if="
                        el.user_data_filed.length > 0 &&
                        el.user_data_filed[0].state_data
                      "
                    >
                      {{ el.user_data_filed[0].state_data.name }}
                    </div>
                    <div v-else>Null</div>
                  </td>
                  <td
                    v-if="checkboxFilter.includes('city')"
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    <div
                      v-if="
                        el.user_data_filed.length > 0 &&
                        el.user_data_filed[0].city_data
                      "
                    >
                      {{ el.user_data_filed[0].city_data.name }}
                    </div>
                    <div v-else>Null</div>
                  </td>
                  <td
                    v-if="checkboxFilter.includes('source')"
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap capitalize"
                  >
                    {{ el.lead_type == "verified" ? "Direct" : "raw" }}
                  </td>
                  <td
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap capitalize"
                  >
                    <div
                      v-if="
                        el.user_data_filed.length > 0 &&
                        el.user_data_filed[0].referens != null
                      "
                    >
                      {{ el.user_data_filed[0].referens.user.display_name }}
                    </div>
                    <div v-else>Null</div>
                  </td>
                  <td
                    v-if="checkboxFilter.includes('joined')"
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ this.dateFormat(el.date_joined) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Pagination -->
            <template v-if="this.userRegisterList">
              <div
                v-if="this.userRegisterList.data.length > 0"
                class="w-full bg-gray-200"
              >
                <ul
                  class="flex py-2 px-6 items-center gap-4 overflow-y-auto"
                  :class="slice_limit > 10 ? 'justify-start' : 'justify-center'"
                >
                  <div
                    v-if="this.slice_start != 0"
                    class="border px-2 bg-white rounded hover:bg-yellow-500 hover:text-white text-xl w-[35px] h-[35px] leading-[40px] cursor-pointer flex items-center"
                    @click="
                      (this.slice_start = this.slice_start - slice_limit),
                        (this.slice_end =
                          this.slice_end % 10 == 0
                            ? this.slice_end - slice_limit
                            : this.slice_end - (this.slice_end % 10))
                    "
                  >
                    <img src="../../assets/backward.png" alt="" />
                  </div>
                  <li
                    v-for="(i, index) in this.userRegisterList.data.slice(
                      this.slice_start,
                      this.slice_end
                    )"
                    :key="i"
                    class="border px-2 bg-white rounded hover:bg-yellow-500 hover:text-white align-middle text-sm w-[35px] h-[35px] leading-[40px] cursor-pointer"
                    :class="[
                      current_position == index + this.slice_start
                        ? 'border-[#ffd300]'
                        : '',
                    ]"
                    @click="selectPage(index)"
                  >
                    {{ index + this.slice_start + 1 }}
                  </li>
                  <template v-if="this.total_pages">
                    <div
                      v-if="this.slice_end < this.total_pages"
                      class="border px-2 bg-white rounded hover:bg-yellow-500 hover:text-white align-middle text-sm w-[35px] h-[35px] leading-[40px] cursor-pointer flex items-center"
                      @click="
                        (this.slice_start = this.slice_start + slice_limit),
                          (this.slice_end =
                            this.slice_end + slice_limit < this.total_pages
                              ? this.slice_end + slice_limit
                              : this.total_pages)
                      "
                    >
                      <img src="../../assets/fast-forward.png" alt="" />
                    </div>
                  </template>
                </ul>
              </div>
            </template>
          </div>
          <div
            v-else-if="select_val == 'exam_result'"
            class="overflow-scroll containerHead border rounded-lg shadow-md text-start"
            style="height: calc(100vh - 80px)"
          >
            <table class="min-w-full bg-white">
              <thead class="bg-primary border-b text-start">
                <tr class="">
                  <th
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    S.No
                  </th>
                  <th
                    v-if="checkboxFilterExam.includes('name')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Name
                  </th>
                  <th
                    v-if="checkboxFilterExam.includes('email')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Email
                  </th>
                  <th
                    v-if="checkboxFilterExam.includes('mark')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Scholarship availed
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(el, index) in this.examsResultsList.data"
                  :key="el"
                  class="border-b"
                >
                  <td
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ index + 1 }}
                  </td>
                  <td
                    v-if="checkboxFilterExam.includes('name')"
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ el.user.display_name }}
                  </td>
                  <td
                    v-if="checkboxFilterExam.includes('email')"
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ el.user.email }}
                  </td>
                  <td
                    v-if="checkboxFilterExam.includes('mark')"
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ el.reservation.scholarship }} %
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-else-if="select_val == 'applied'"
            class="overflow-scroll containerHead border rounded-lg shadow-md text-start"
            style="height: calc(100vh - 80px)"
          >
            <table class="min-w-full bg-white">
              <thead class="bg-primary border-b text-start">
                <tr class="">
                  <th
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    S.No
                  </th>
                  <th
                    v-if="fieldFilterapplyele.includes('name')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Name
                  </th>
                  <th
                    v-if="fieldFilterapplyele.includes('email')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Email
                  </th>
                  <th
                    v-if="fieldFilterapplyele.includes('phone')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Phone
                  </th>
                  <th
                    v-if="fieldFilterapplyele.includes('elective')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Elective
                  </th>
                  <th
                    v-if="fieldFilterapplyele.includes('university')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    University
                  </th>
                  <th
                    v-if="fieldFilterapplyele.includes('date')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Application date
                  </th>
                  <th
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Enrollment
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(el, index) in this.appliedElectiveList"
                  :key="el"
                  class="border-b"
                >
                  <td
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ index + 1 }}
                  </td>
                  <td
                    v-if="fieldFilterapplyele.includes('name')"
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ el.user.display_name }}
                  </td>
                  <td
                    v-if="fieldFilterapplyele.includes('email')"
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ el.user.email }}
                  </td>
                  <td
                    v-if="fieldFilterapplyele.includes('phone')"
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ el.user.phone }}
                  </td>
                  <td
                    v-if="fieldFilterapplyele.includes('elective')"
                    class="text-sm text-gray-900 font-normal px-6 py-4"
                  >
                    {{ el.elective.elective.electives }}
                  </td>
                  <td
                    v-if="fieldFilterapplyele.includes('university')"
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ el.elective.university.name }}
                  </td>
                  <td
                    v-if="fieldFilterapplyele.includes('date')"
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ this.dateFormat(el.created_at) }}
                  </td>
                  <td
                    @click="enrollPopUp(el)"
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    <button class="bg-primary px-2 py-1 rounded-md">
                      Enroll
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-else-if="select_val == 'downloadbr'"
            class="overflow-scroll containerHead border rounded-lg shadow-md text-start"
            style="height: calc(100vh - 80px)"
          >
            <table class="min-w-full bg-white">
              <thead class="bg-primary border-b text-start">
                <tr class="">
                  <th
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    S.No
                  </th>
                  <th
                    v-if="fieldFilterdownload.includes('name')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Name
                  </th>
                  <th
                    v-if="fieldFilterdownload.includes('email')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Email
                  </th>
                  <th
                    v-if="fieldFilterdownload.includes('phone')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Phone
                  </th>
                  <th
                    v-if="fieldFilterdownload.includes('elective')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Elective
                  </th>
                  <th
                    v-if="fieldFilterdownload.includes('university')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    University
                  </th>
                  <th
                    v-if="fieldFilterdownload.includes('date')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Brochure download date
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(el, index) in this.downloadbrochure"
                  :key="el"
                  class="border-b"
                >
                  <td
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ index + 1 }}
                  </td>
                  <td
                    v-if="fieldFilterdownload.includes('name')"
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ el.user.display_name }}
                  </td>
                  <td
                    v-if="fieldFilterdownload.includes('email')"
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ el.user.email }}
                  </td>
                  <td
                    v-if="fieldFilterdownload.includes('phone')"
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ el.user.phone }}
                  </td>
                  <td
                    v-if="fieldFilterdownload.includes('elective')"
                    class="text-sm text-gray-900 font-normal px-6 py-4"
                  >
                    {{ el.elective.elective.electives }}
                  </td>
                  <td
                    v-if="fieldFilterdownload.includes('university')"
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ el.elective.university.name }}
                  </td>
                  <td
                    v-if="fieldFilterdownload.includes('date')"
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ this.dateFormat(el.created_at) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- cp -->
          <div
            v-else-if="select_val == 'cp'"
            class="overflow-scroll containerHead border rounded-lg shadow-md text-start"
            style="height: calc(100vh - 80px)"
          >
            <table class="min-w-full bg-white">
              <thead class="bg-primary border-b text-start">
                <tr class="">
                  <th
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    S.No
                  </th>
                  <th
                    v-if="fieldFilterapplyele.includes('name')"
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Phone
                  </th>
                  <th
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Reference link
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(el, index) in this.cpList"
                  :key="el"
                  class="border-b"
                >
                  <td
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ index + 1 }}
                  </td>
                  <td
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ el.user.display_name }}
                  </td>
                  <td
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ el.user.email }}
                  </td>
                  <td
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ el.user.phone }}
                  </td>
                  <td class="text-sm text-gray-900 font-normal px-6 py-4">
                    {{ this.frontEnd }}/{{ el.user.staff_profile.slug }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Applied University -->
          <div
            v-else-if="select_val == 'applied-university'"
            class="overflow-scroll containerHead border rounded-lg shadow-md text-start"
            style="height: calc(100vh - 80px)"
          >
            <table class="min-w-full bg-white">
              <thead class="bg-primary border-b text-start">
                <tr class="">
                  <th
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    S.No
                  </th>
                  <th
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Phone
                  </th>
                  <th
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    University
                  </th>
                  <th
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Application date
                  </th>
                  <th
                    scope="col"
                    class="text-start text-base font-medium text-gray-900 px-6 py-4"
                  >
                    Enrollment
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(el, index) in this.appliedUniversityList"
                  :key="el"
                  class="border-b"
                >
                  <td
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ index + 1 }}
                  </td>
                  <td
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ el.user.display_name }}
                  </td>
                  <td
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ el.user.email }}
                  </td>
                  <td
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    {{ el.user.phone }}
                  </td>
                  <td class="text-sm text-gray-900 font-normal px-6 py-4">
                    {{ el.university.name }}
                  </td>
                  <td class="text-sm text-gray-900 font-normal px-6 py-4">
                    {{ el.created_at }}
                  </td>
                  <td
                    @click="enrollPopUp(el)"
                    class="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap"
                  >
                    <button class="bg-primary px-2 py-1 rounded-md">
                      Enroll
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- left slide -->
          <div
            class="enrollPopup hidden fixed top-0 right-0 max-w-[460px] w-full min-w-[300px] bg-black h-full"
          >
            <div class="py-[100px] flex flex-col gap-6">
              <h1 class="text-white font-semibold">Student Enrollment</h1>
              <div class="flex flex-col gap-6 relative">
                <button
                  class="absolute -top-10 right-4 z-50 text-white hover:text-red-600"
                  @click="popUpClose"
                >
                  X
                </button>
                <div
                  v-if="this.selectedUser"
                  class="flex flex-col gap-3 w-[80%] mx-auto text-start text-white"
                >
                  <label for="Universities">Student Name</label>
                  <input
                    type="text"
                    disabled
                    class="rounded-md text-gray-600"
                    :value="this.selectedUser.user.display_name"
                  />
                </div>
                <div
                  class="flex flex-col gap-3 w-[80%] mx-auto text-start text-white"
                >
                  <label for="universities">Universities</label>
                  <select
                    name=""
                    id="universities"
                    class="rounded-md text-gray-600"
                    @change="
                      selectedProgram = '';
                      this.elective_list = '';
                    "
                  >
                    <option disabled selected value="">
                      Select Universities
                    </option>
                    <option
                      v-for="university in this.university_list"
                      :key="university"
                      :value="university.id"
                    >
                      {{ university.name }}
                    </option>
                  </select>
                </div>
                <div
                  class="flex flex-col gap-3 w-[80%] mx-auto text-start text-white"
                >
                  <label for="program">Programs</label>
                  <select
                    name=""
                    id="program"
                    ref="programVal"
                    class="rounded-md text-gray-600"
                    v-model="selectedProgram"
                    @change="electiveCall"
                  >
                    <option disabled selected value="">Select Program</option>
                    <option
                      v-for="program in this.program_list"
                      :key="program"
                      :value="program.id"
                    >
                      {{ program.name }}
                    </option>
                  </select>
                </div>
                <div
                  class="flex flex-col gap-3 w-[80%] mx-auto text-start text-white"
                >
                  <label for="elective">Electives</label>
                  <select
                    name=""
                    id="elective"
                    class="rounded-md text-gray-600"
                  >
                    <template
                      v-if="this.elective_list.length > 0 && selectedProgram"
                    >
                      <option
                        v-for="el in this.elective_list"
                        :key="el"
                        :value="el.id"
                      >
                        {{ el.elective.electives }}
                      </option>
                    </template>
                    <template
                      v-else-if="
                        this.elective_list.length == 0 && selectedProgram
                      "
                    >
                      <option disabled value="">Null</option>
                    </template>
                    <template
                      v-else-if="
                        this.elective_list.length == 0 && selectedProgram == ''
                      "
                    >
                      <option disabled value="">Select the Program</option>
                    </template>
                  </select>
                </div>
                <div class="Enroll_submit">
                  <button
                    @click="enrollmentPost"
                    type="submit"
                    class="bg-white px-3 py-1 rounded-md"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
import Chart from "chart.js/auto";
import axios from "axios";
import convertdate from "@/services/DateConvert.js";
import circleChart from "@/components/circleChart.vue";
import LeftNavigation from "@/components/LeftNavigationAdminView.vue";

import { mapActions, mapGetters } from "vuex";
export default {
  name: "LeadDashboardView",
  data() {
    return {
      slice_limit: 10,
      total_pages: 0,
      slice_start: 0,
      slice_end: 0,
      current_position: 0,
      university_list: [],
      program_list: [],
      elective_list: [],
      selectedProgram: "",
      selectedUser: "",
      queryVal: [],
      queryVal_qlf: "",
      dhoverall: {
        total_users: 200,
        total_result: 100,
        qualified_users: 10,
        all_user_users: 15,
      },
      select_val: "all_user",
      userRegisterList: "",
      selectUserStr: "",
      qualified_data: [],
      examsResultsList: "",
      userCountVal: {
        allUser: 0,
        allUserVerified: 0,
        exams_done: 0,
        students_applied_elective: 0,
        downloadbrochure: 0,
        enrollment: 0,
        isData: false,
      },
      is_popup: false,
      ld_btn: false,
      message: {
        text: "",
        type: "",
      },
      selectOption: "summary",
      filterList: false,
      checkboxFilter: [
        "name",
        "email",
        "university",
        "qualification",
        "state",
        "city",
        "contact",
        "source",
        "joined",
      ],
      checkboxFilterExam: ["name", "email", "mark", "scholarship", "coupon"],
      appliedElectiveList: [],
      fieldFilterapplyele: [
        "name",
        "email",
        "phone",
        "elective",
        "university",
        "date",
      ],
      downloadbrochure: [],
      fieldFilterdownload: [
        "name",
        "email",
        "phone",
        "elective",
        "university",
        "date",
      ],
      cpList: [],
      frontEnd: "",
      appliedUniversityList: [],
    };
  },
  computed: mapGetters(["userInfo", "isAuthenticated"]),
  components: {
    Navigation,
    circleChart,
    LeftNavigation,
  },
  async created() {
    await this.userCount();
    await this.userInfoGet();
    this.slice_end = this.slice_limit;
    this.frontEnd = `${process.env.VUE_APP_SITE}`;
  },
  async mounted() {},
  methods: {
    async appliedUniversity() {
      await axios
        .get(`${process.env.VUE_APP_API}/selected-university/list/`)
        .then((resp) => {
          if (resp.status == 200) {
            this.select_val = "applied-university";
            this.appliedUniversityList = resp.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    enrollmentPost() {
      let student_id = JSON.stringify(this.selectedUser.user.id);
      let bodyVal = {
        student: student_id,
        university_id: $("#universities").val(),
        elective_id: $("#elective").val(),
      };
      axios
        .post(
          `${process.env.VUE_APP_API}/enrolment/post/?Key=${this.userInfo.data.token}`,
          bodyVal
        )
        .then((resp) => {
          console.log(resp);
          if (resp.data.status == 200) {
            $(".enrollPopup").show(1000);
            $(".Enroll_submit").append(
              `<p class="EnrollmentStatus py-4 text-sm font-medium text-white"> Student Enrolled successfully</p>`
            );
          } else {
            $(".Enroll_submit").append(
              `<p class="EnrollmentStatus py-4 text-sm font-medium text-white"> Student Enrolled Failed</p>`
            );
          }
          setTimeout(() => {
            $(".EnrollmentStatus").remove();
            $(".enrollPopup").hide(1000);
          }, 3000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    popUpClose(el) {
      $(".enrollPopup").hide(1000);
    },
    enrollPopUp(userDetails) {
      this.selectedUser = userDetails;
      $(".enrollPopup").show(1000);
      this.universityCall();
      this.programCall();
    },
    async universityCall() {
      await axios
        .get(`${process.env.VUE_APP_API}/student/universities_all/list/`)
        .then((resp) => {
          if (resp.status == 200) {
            this.university_list = resp.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async programCall() {
      await axios
        .get(`${process.env.VUE_APP_API}/student/program_name/list/`)
        .then((resp) => {
          if (resp.status == 200) {
            this.program_list = resp.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    electiveCall() {
      let program_id = $("#program").val();
      let university_id = $("#universities").val();

      axios
        .get(
          `${
            process.env.VUE_APP_API
          }/student/universities/test/list/?university__id=${parseInt(
            university_id
          )}&program__id=${parseInt(program_id)}`
        )
        .then((resp) => {
          if (resp.data.status == 200) {
            this.elective_list = resp.data.data;
          } else {
            this.elective_list = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    userFilterCall(el) {
      let selected_val = el.target.value;
      if (selected_val == "All Users") {
        this.userInfoGet();
      } else if (selected_val == "Direct Users") {
        this.verified_student();
      } else if (selected_val == "Raw Users") {
        this.raw_student();
      } else if (selected_val == "Exam details") {
        this.examResults();
      } else if (selected_val == "Applied elective") {
        this.applieduser();
      } else if (selected_val == "Download Brochure") {
        this.downloadBr();
      } else if (selected_val == "Channel Partner") {
        this.cpInfoGet();
      } else if (selected_val == "Applied university") {
        this.appliedUniversity();
      }
    },
    async cpInfoGet() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/staff_profile/?user__staff_profile__handler_type__name=cp_admin&Key=${this.userInfo.data.token}`
        )
        .then((resp) => {
          if (resp.data.status == 200) {
            this.select_val = "cp";
            this.cpList = resp.data.data;
          } else {
            this.select_val = "cp";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async downloadBr() {
      await axios
        .get(`${process.env.VUE_APP_API}/selected-elective-brochure/list/`)
        .then((resp) => {
          if (resp.status == 200) {
            this.select_val = "downloadbr";
            this.downloadbrochure = resp.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async applieduser() {
      await axios
        .get(`${process.env.VUE_APP_API}/selected-elective/list/`)
        .then((resp) => {
          if (resp.status == 200) {
            this.select_val = "applied";
            this.appliedElectiveList = resp.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    dateFormat(el) {
      return convertdate.dateFormatDDMMYYYY(el);
    },
    async userInfoGet(page_no = 1) {
      this.select_val = "all_user";
      await axios
        .get(
          `${process.env.VUE_APP_API}/profile_all/?Permission_access=student&Key=${this.userInfo.data.token}&page=${page_no}`
        )
        .then((resp) => {
          this.selectUserStr = "All Users";
          this.userRegisterList = resp.data;
          this.total_pages = this.userRegisterList.total_pages;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async verified_student() {
      this.select_val = "all_user";
      await axios
        .get(
          `${process.env.VUE_APP_API}/profile_all/?Permission_access=student&lead_type=verified&Key=${this.userInfo.data.token}`
        )
        .then((resp) => {
          this.selectUserStr = "All verify Users";
          this.userRegisterList = resp.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async raw_student() {
      this.select_val = "all_user";
      await axios
        .get(
          `${process.env.VUE_APP_API}/profile_all/?Permission_access=student&lead_type=raw&Key=${this.userInfo.data.token}`
        )
        .then((resp) => {
          this.selectUserStr = "All Raw Users";
          this.userRegisterList = resp.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async examResults() {
      await axios
        .get(`${process.env.VUE_APP_API}/reservation-booking/list/`)
        .then((resp) => {
          this.select_val = "exam_result";
          if (resp.data.status == 200) {
            this.examsResultsList = resp.data;
          } else {
            this.examsResultsList = "";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async userCount() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/student/exam/applied/count/list/?Key=${this.userInfo.data.token}`
        )
        .then((resp) => {
          if (resp.data.status == 200) {
            console.log(resp.data);
            this.userCountVal.allUser = resp.data.user.count;
            this.userCountVal.allUserVerified = resp.data.user.verified_user;
            this.userCountVal.exams_done =
              resp.data.exam_attended_details.count;
            this.userCountVal.students_applied_elective =
              resp.data.total_applied;
            this.userCountVal.downloadbrochure =
              resp.data.brochure_details.total_of_university_elective;
            this.userCountVal.enrollment = resp.data.enrolment;
            this.userCountVal.isData = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fileUpload(el) {
      this.ld_btn = true;
      let bodyVal = new FormData();
      bodyVal.append("batchname", el.target.batch_name.value);
      bodyVal.append("my_file", el.target.fileUpload_csv.files[0]);
      axios
        .post(
          `${process.env.VUE_APP_API}/student/data_upload/?Key=${this.userInfo.data.token}`,
          bodyVal
        )
        .then((resp) => {
          if (resp.status == 200) {
            this.message.text = "Data uploaded successfully";
            this.message.type = "success";
            this.ld_btn = false;
            this.$refs.fileUploadFiled.reset();
          } else {
            this.message.text = "check the fields";
            this.message.type = "error";
            this.ld_btn = false;
          }
        })
        .catch((error) => {
          this.message.text = "Server Error";
          this.message.type = "error";
          this.ld_btn = false;
        });
      setTimeout(() => {
        this.message.text = "";
        this.message.type = "";
      }, 7000);
    },
    selectPage(index) {
      this.current_position = index + this.slice_start;
      this.userInfoGet(this.current_position + 1);
    },
    // nxtQt() {
    //   let nextQuestion = this.current_position + 1;
    //   if (nextQuestion < this.total_pages) {
    //     this.current_position = nextQuestion;
    //   }
    // },
    // pvsQt() {
    //   if (this.current_position > 0) {
    //     let nextQuestion = this.current_position - 1;
    //     if ((nextQuestion) => 0) {
    //       this.current_position = nextQuestion;
    //     }
    //   }
    // },
  },
};
</script>
<style scoped>
.selectOptions::after {
  content: "";
  position: absolute;
  bottom: -17px;
  right: 42%;
  border-top: 27px solid #fec52d;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
}
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.bulkUpload::after {
  content: "*";
  color: red;
}
</style>
