<template>
  <div class="h-full min-h-screen w-12 hidden">
    <!-- Left Navigation Icon -->
    <div
      v-if="is_popup == false"
      class="fixed left-0 top-0 h-full border-l-8 border-l-[#FEC52D] z-50"
    >
      <div class="mt-28 cursor-pointer" @click="is_popup = true">
        <img
          src="../assets/menu.png"
          alt=""
          class="h-16 bg-primary px-2 py-3 border-4 border-l-[#FEC52D] border-r-white border-t-white border-b-white rounded-r-xl"
        />
      </div>
    </div>
    <!-- Left Navigation Options -->
    <Transition>
      <div
        v-if="is_popup"
        class="fixed top-0 left-0 z-50 h-full w-full bg-black bg-opacity-50"
      >
        <div class="flex h-full w-full">
          <div class="h-full w-[15rem] border shadow-lg">
            <ul class="mt-[85px] h-full flex flex-col gap-2 pt-6">
                <router-link :to="{ name: 'adminDashboard' }" class="w-full">
                <li
                class="bg-white p-2 w-[90%] mx-auto rounded-md text-sm font-medium"
                :class="[
                  this.$router.currentRoute._value.name == 'adminDashboard'
                    ? 'bg-primary text-black border-4 border-white'
                    : '',
                ]"
              >
                
                  Overall View
                </li>
            </router-link>
              <router-link :to="{ name: 'StaffEnrollment' }" class="w-full">
              <li
                class="bg-white p-2 w-[90%] mx-auto rounded-md text-sm font-medium"
                :class="[
                  this.$router.currentRoute._value.name == 'StaffEnrollment'
                    ? 'bg-primary text-black border-4 border-white'
                    : '',
                ]"
              >
                
                  Staff enrollment
                </li>
            </router-link>
            <router-link :to="{ name: 'Communication' }" class="w-full">
              <li
                class="bg-white p-2 w-[90%] mx-auto rounded-md text-sm font-medium"
                :class="[
                  this.$router.currentRoute._value.name == 'Communication'
                    ? 'bg-primary text-black border-4 border-white'
                    : '',
                ]"
                >Communication</li
              >
            </router-link>
              <li
                class="bg-white p-2 w-[90%] mx-auto rounded-md text-sm font-medium"
                >Mail Info</li
              >
              <li
                class="bg-white p-2 w-[90%] mx-auto rounded-md text-sm font-medium"
                >Staff Info</li
              >
            </ul>
          </div>
          <div class="h-full border-l-4 border-l-[#FEC52D] z-50">
            <div class="mt-28 cursor-pointer" @click="is_popup = false">
              <img
                src="../assets/menu.png"
                alt=""
                class="h-16 bg-primary px-1.5 py-3 border-4 border-r-[#FEC52D] border-l-white border-t-white border-b-white rounded-l-xl rotate-180"
              />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_popup: false,
    };
  },
};
</script>

<style scoped>
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
