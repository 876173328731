<template lang="">
  <div class="w-full h-full">
    <Navigation />
    <div id="main-page" class="min-h-screen maxwidthscreen mx-auto">
      <!-- banner section start -->
      <div
        id="enquiry-content"
        class="relative md:h-full bg-[#fec52d] overflow-hidden"
      >
        <img
          src="../assets/new_banner_LO_LP.webp"
          alt="banner1"
          class="hidden md:block absolute top-1 w-full h-full object-contain"
        />
        <div
          class="h-full flex flex-col md:flex-row justify-between md:items-center max-w-screen-2xl mx-auto"
        >
          <div
            class="h-[500px] md:h-auto relative flex flex-col justify-center gap-7 z-50"
          >
            <img
              src="../assets/new_banner_lpmobile.webp"
              alt="Banner"
              class="block md:hidden absolute top-0 w-full h-full object-contain"
            />
            <!-- <h1 class="px-4 md:px-0 uppercase text-left text-white text-6xl font-bold z-50">Lots</h1>
                    <h1 class="w-6/12 md:w-auto px-4 md:px-0 text-left text-black text-3xl font-bold z-50">
                        Learn.Online <br class="block lg:hidden"> Test for <br> Scholarship
                    </h1> -->
          </div>
          <div class="p-4 self-center z-50">
            <div class="w-[350px] bg-white px-6 py-4 shadow-lg rounded-xl z-40">
              <h1
                class="border-b-2 border-primary pb-1 text-center font-bold text-lg text-black"
              >
                Enquire Now
              </h1>
              <div v-if="this.messages.message != ''" class="mt-3">
                <p
                  class="rounded-md py-1 text-sm font-medium text-white"
                  :class="
                    this.messages.type == 'error'
                      ? ' bg-red-600'
                      : ' bg-green-700'
                  "
                >
                  {{ this.messages.message }}
                </p>
              </div>
              <form ref="registerForm" @submit.prevent="registerOtpValidate">
                <input
                  type="text"
                  class="form-control block w-full px-3 py-2 text-sm font-normal text-gray-700 bg-white bg-clip-padding focus:ring-0 focus:border-primary border-[#e5e7eb] rounded mt-5 mb-3 shadow-md focus:outline-none"
                  id="F_Name"
                  placeholder="Full name*"
                  required
                />
                <input
                  type="tel"
                  class="form-control block w-full px-3 py-2 text-sm font-normal text-gray-700 bg-white bg-clip-padding focus:ring-0 focus:border-primary border-[#e5e7eb] rounded mb-3 shadow-md focus:outline-none"
                  onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                  id="Phone_no"
                  placeholder="Phone no*"
                  maxlength="10"
                  required
                />
                <input
                  list="university_val"
                  class="form-control block w-full px-3 py-2 text-sm font-normal text-gray-700 bg-white bg-clip-padding focus:ring-0 focus:border-primary border-[#e5e7eb] rounded mb-3 shadow-md focus:outline-none"
                  id="uniersityselected"
                  placeholder="College/Universities*"
                  required
                />
                <datalist class="" id="university_val">
                  <option
                    v-for="university in universitys"
                    :key="university"
                    :value="university.name"
                  >
                    {{ university.name }}
                  </option>
                </datalist>
                <div class="flex gap-4 items-center mb-3">
                  <select
                    id="qualification_val"
                    class="form-select appearance-none block w-full pl-3 pr-10 py-2 text-sm font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat focus:ring-0 focus:border-primary border-[#e5e7eb] rounded shadow-md focus:outline-none"
                    aria-label="Default select example"
                    required
                  >
                    <option selected disabled>Highest Qualification*</option>
                    <option
                      v-for="qualification in qualifications"
                      :key="qualification"
                      :value="qualification.id"
                    >
                      {{ qualification.name }}
                    </option>
                  </select>
                  <div class="flex items-center gap-2 py-2">
                    <input
                      type="checkbox"
                      id="Qua_completed"
                      class="rounded focus:shadow-none focus:outline-none focus:ring-0"
                    />
                    <label for="Qua_completed" class="text-xs"> Pursuing</label>
                  </div>
                </div>
                <div class="flex flex-row gap-3 mb-3">
                  <select
                    id="state_val"
                    class="form-select appearance-none block w-full pl-3 pr-10 pymaxwidthscreen-2 text-sm font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat focus:ring-0 focus:border-primary border-[#e5e7eb] rounded shadow-md focus:outline-none"
                    aria-label="Default select example"
                    @change="selectCity"
                    required
                  >
                    <option selected disabled>Select State*</option>
                    <option
                      v-for="state in states"
                      :key="state"
                      :value="JSON.stringify(state)"
                    >
                      {{ state.name }}
                    </option>
                  </select>
                  <select
                    id="city_val"
                    class="form-select appearance-none block w-full pl-3 pr-10 py-2 text-sm font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat focus:ring-0 focus:border-primary border-[#e5e7eb] rounded shadow-md focus:outline-none"
                    aria-label="Default select example"
                    required
                  >
                    <option selected disabled>Select City*</option>
                    <option
                      v-for="city in citys.city_id"
                      :key="city"
                      :value="city.id"
                    >
                      {{ city.name }}
                    </option>
                  </select>
                </div>

                <div class="relative mb-3">
                  <form @submit.prevent="registerOtpSend">
                    <input
                      type="email"
                      class="form-control block w-full pl-3 pr-[95px] py-2 text-sm font-normal text-gray-700 bg-white bg-clip-padding focus:ring-0 focus:border-primary border-[#e5e7eb] rounded shadow-md focus:outline-none"
                      id="emailId"
                      placeholder="Email ID*"
                      required
                    />

                    <div
                      v-if="this.btn_load_otp != true"
                      class="bg-primary text-xs border-none outline-none absolute top-0 right-0 w-[85px] h-full rounded-tr rounded-br flex items-center justify-center cursor-pointer text-black font-semibold"
                    >
                      <button type="submit">Get OTP</button>
                    </div>
                    <div
                      v-else
                      class="bg-primary text-xs border-none outline-none absolute top-0 right-0 w-[85px] h-full rounded-tr rounded-br flex items-center justify-center cursor-pointer text-black font-semibold"
                    >
                      Loading..
                    </div>
                  </form>
                </div>
                <div class="flex flex-row gap-3">
                  <input
                    type="number"
                    class="flex-1 form-control block w-full px-3 py-2 text-sm font-normal text-gray-700 bg-white bg-clip-padding focus:ring-0 focus:border-primary border-[#e5e7eb] rounded shadow-md focus:outline-none"
                    id="otpval"
                    placeholder="Enter OTP here"
                    required
                  />

                  <button
                    v-if="this.btn_load != true"
                    type="submit"
                    class="bg-primary text-xs border-none outline-none uppercase px-5 py-2 cursor-pointer text-black font-semibold rounded shadow-md"
                  >
                    submit
                  </button>
                  <button
                    v-else
                    class="bg-primary text-xs border-none outline-none uppercase px-5 py-2 cursor-pointer text-black font-semibold rounded shadow-md"
                  >
                    Loading..
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- banner section end -->

      <!-- about section start -->
      <div id="about-content" class="bg-white px-4 py-10">
        <div
          class="flex flex-col-reverse justify-center lg:flex-row lg:justify-between gap-10 max-w-screen-2xl mx-auto text-start"
        >
          <div
            class="min-w-[320px] max-w-[312px] h-[186px] lg:w-4/12 relative self-center"
          >
            <img
              src="../assets/video_thumbnail.webp"
              alt="video_image"
              class="rounded-md w-[312px] h-[186px] cursor-pointer"
              @click="this.videoPopup = true"
            />
            <!-- <img src="../assets/video-play-icon.png" alt="videoPlayicon" class="w-12 h-12 absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 cursor-pointer" /> -->
          </div>
          <div class="w-full lg:w-8/12 flex flex-col">
            <div class="flex flex-col sm:flex-row gap-x-2 gap-y-6 mb-7">
              <strong
                class="yellow_underline relative font-light text-black text-xl uppercase"
              >
                About
                <strong class="ml-2 font-bold text-primary text-xl uppercase"
                  >LOTS -
                </strong>
              </strong>
              <strong class="text-primary text-lg"
                >Learn.Online Test for Scholarship</strong
              >
            </div>
            <p class="text-sm md:text-lg">
              One of a kind scholarship test where Learn.Online allows aspiring
              learners to test their knowledge and aptitude and offer them a
              chance to earn up to 50% scholarship on Online Degree Programs
              from top universities.
            </p>
          </div>
        </div>
      </div>
      <!-- about section end -->

      <!-- card section start -->
      <div class="relative gradient-color py-10 h-[450px]">
        <img
          src="../assets/bg.webp"
          alt="banner2"
          class="hidden md:block absolute top-0 w-full h-full object-cover"
        />
        <div
          class="h-full flex flex-col md:flex-row justify-between max-w-screen-2xl mx-auto text-start"
        >
          <div class="w-full md:w-6/12 px-4 flex flex-col gap-7 z-50 h-[350px]">
            <!-- <div class="flex gap-4">
                        <img src="../assets/ENTRANCE-DATE.png" alt="date-icon" class="w-[30px] self-start">
                        <div class="flex flex-col gap-3">
                            <h1 class="uppercase text-primary text-base font-bold">entrance date</h1>
                            <p class="text-white text-sm">
                                20<sup>th</sup> March to 20<sup>th</sup> April 2023
                            </p>
                        </div>
                    </div> -->

            <div class="flex gap-4">
              <img
                src="../assets/ELIGIBILITY.png"
                alt="eligibility-icon"
                class="w-[30px] self-start"
              />
              <div class="flex flex-col gap-3">
                <h1 class="uppercase text-primary text-base font-bold">
                  eligibility
                </h1>
                <p class="text-white text-sm">
                  Candidates must be students pursuing the final year of their
                  graduation in 2023 or students who have already graduated.
                </p>
              </div>
            </div>

            <div class="flex gap-4">
              <img
                src="../assets/ENTRANCE-FORMAT.png"
                alt="entranceformat-icon"
                class="w-[30px] self-start"
              />
              <div class="flex flex-col gap-3">
                <h1 class="uppercase text-primary text-base font-bold">
                  entrance format
                </h1>
                <p class="text-white text-sm">
                  The scholarship test will be conducted online, in MCQ format
                  and proctored by the Learn.Online team. The test will be
                  conducted in English and will contain Verbal and Aptitude
                  sections.
                </p>
                <p class="text-white text-sm">
                  The test will contain 40 questions and candidates will get 30
                  minutes to finish the scholarship test.
                </p>
                <p class="text-white text-sm">
                  Any discrepancies and unfair means will lead to the
                  disqualification of the candidates.
                </p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-6/12 relative"></div>
        </div>
      </div>
      <!-- card section end -->

      <!-- Why take the start -->
      <div class="bg-white px-4 pt-10">
        <div class="max-w-screen-2xl mx-auto">
          <div
            class="flex flex-col sm:flex-row sm:justify-center gap-x-2 gap-y-6"
          >
            <strong
              class="yellow_underline relative font-light text-black text-xl text-left sm:text-center"
            >
              Why take the
            </strong>
            <strong class="text-primary text-xl text-left sm:text-center">
              Learn.Online Test for Scholarship?
            </strong>
          </div>
          <div
            class="px-[32px] lg:px-0 relative overflow-x-hidden max-w-7xl mx-auto"
          >
            <div
              class="icon absolute top-0 left-0 h-full flex items-center block lg:hidden"
            >
              <i
                id="left"
                class="fa-solid fa-angle-left w-[25px] h-[25px] cursor-pointer text-center rounded-full hover:bg-[#efedfb] flex items-center justify-center"
              ></i>
            </div>
            <ul
              class="tabs-box flex gap-10 overflow-x-hidden scroll-smooth pb-10 pt-24"
            >
              <div
                class="tab relative border-2 rounded-lg shadow-lg px-5 pt-14 pb-10 min-w-[280px] max-w-[350px] cursor-pointer hover:bg-[#efedfb]"
              >
                <div
                  class="w-[80px] h-[80px] bg-primary flex items-center justify-center rounded-lg absolute -top-[50px] left-1/2 -translate-x-1/2"
                >
                  <img src="../assets/entroll.png" alt="evaluate-icon" />
                </div>
                <p class="text-center text-sm">
                  Evaluate and assess your verbal & analytical skills
                </p>
              </div>

              <div
                class="tab relative border-2 rounded-lg shadow-lg px-5 pt-14 pb-10 min-w-[280px] max-w-[350px] cursor-pointer hover:bg-[#efedfb]"
              >
                <div
                  class="w-[80px] h-[80px] bg-primary flex items-center justify-center rounded-lg absolute -top-[50px] left-1/2 -translate-x-1/2"
                >
                  <img
                    src="../assets/Scholarship-test-2.png"
                    alt="benchmark-icon"
                  />
                </div>
                <p class="text-center text-sm">
                  Prove your competence among thousands of aspirants.
                </p>
              </div>

              <div
                class="tab relative border-2 rounded-lg shadow-lg px-5 pt-14 pb-10 min-w-[280px] max-w-[350px] cursor-pointer hover:bg-[#efedfb]"
              >
                <div
                  class="w-[80px] h-[80px] bg-primary flex items-center justify-center rounded-lg absolute -top-[50px] left-1/2 -translate-x-1/2"
                >
                  <img
                    src="../assets/Scholarship-test-3.png"
                    alt="opportunity-icon"
                  />
                </div>
                <p class="text-center text-sm">
                  An opportunity to avail admission into top universities &
                  their programs
                </p>
              </div>

              <div
                class="tab relative border-2 rounded-lg shadow-lg px-5 pt-14 pb-10 min-w-[280px] max-w-[350px] cursor-pointer hover:bg-[#efedfb]"
              >
                <div
                  class="w-[80px] h-[80px] bg-primary flex items-center justify-center rounded-lg absolute -top-[50px] left-1/2 -translate-x-1/2"
                >
                  <img
                    src="../assets/Scholarship-test-4.png"
                    alt="avail icon"
                  />
                </div>
                <p class="text-center text-sm">
                  You can avail up to 50% of scholarships
                </p>
              </div>
            </ul>
            <div
              class="icon absolute top-0 right-0 justify-end h-full flex items-center block lg:hidden"
            >
              <i
                id="right"
                class="fa-solid fa-angle-right w-[25px] h-[25px] cursor-pointer text-center rounded-full hover:bg-[#efedfb] flex items-center justify-center"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <!-- Why take the end -->

      <!-- How to avail the start -->
      <div class="relative">
        <img
          src="../assets/Scholarship-test-bg_new.webp"
          alt="banner3-left"
          class="hidden sm:block w-full h-full object-cover"
        />
        <img
          src="../assets/Scholarship-test-bg.png"
          alt="banner-right"
          class="block sm:hidden w-full h-full object-cover"
        />
        <div
          class="h-full flex flex-col md:flex-row justify-between max-w-screen-2xl mx-auto"
        >
          <div
            class="w-full md:w-6/12 px-4 flex flex-col z-50 absolute left-2 sm:left-[10%] top-2 lg:top-6"
          >
            <h1
              class="white_underline relative text-black font-light text-base lg:text-xl text-left"
            >
              How to avail the
            </h1>
            <h1
              class="mt-5 text-white text-base lg:text-xl text-left font-bold capitalize"
            >
              scholarships and admission <br class="block sm:hidden" />
              into top programs?
            </h1>
          </div>
        </div>
      </div>
      <!-- How to avail the end -->

      <!-- Participating universities start -->
      <div class="bg-white px-4 py-10">
        <div class="max-w-screen-2xl mx-auto">
          <div
            class="flex flex-col sm:flex-row sm:justify-center gap-x-2 gap-y-6"
          >
            <strong
              class="yellow_underline relative font-light text-black text-xl text-left sm:text-center"
            >
              Participating
            </strong>
            <strong class="text-primary text-xl text-left sm:text-center"
              >Universities</strong
            >
          </div>

          <div
            :class="this.slick_var"
            class="w-full mx-auto px-7 mt-7 sm:mt-12 flex"
          >
            <div
              v-for="university in universitiesList"
              :key="university"
              class="relative border-2 rounded-lg shadow-lg mx-2 !flex items-center justify-center"
            >
              <img
                :src="university.logo"
                width="200"
                :alt="university.name + 'logo'"
                class="h-24 object-contain py-2"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Participating universities end -->

      <!-- FAQ -->
      <div id="faq-content" class="bg-[#f6f6f6] px-4 py-10">
        <div class="max-w-screen-2xl mx-auto">
          <div class="flex flex-wrap justify-center">
            <span
              class="yellow_underline relative font-light text-black text-xl"
              >Frequently</span
            >
            <strong class="ml-2 text-primary text-xl">Asked Questions</strong>
          </div>
          <div class="accordion mt-10 bg-trasparent" id="faqs">
            <div
              v-for="(el, index) in this.Faq.slice(this.start, this.end)"
              class="accordion-item border-t-0 border-x-0 border border-primary text-left"
            >
              <h2 class="accordion-header mb-0">
                <button
                  @click="faqCollapse"
                  class="accordion-button relative flex items-center justify-between w-full py-4 px-5 font-semibold text-sm md:text-base text-gray-800 text-left border-0 rounded-none transition focus:outline-none"
                  type="button"
                  :data-target="'#text-' + index"
                >
                  {{ el.question_text }}
                  <img
                    src="../assets/slide-left.png"
                    alt="down-arrow"
                    class="down-arrow"
                  />
                </button>
              </h2>
              <div
                :id="'text-' + index"
                class="accordion-collapse border-0 hidden text-sm md:text-base"
              >
                <div class="accordion-body pb-4 px-5 flex flex-col gap-3">
                  <!-- <p v-html="el.answer_text"></p> -->
                  <pre style="white-space: pre-line; font-family: montserrat">{{
                    el.answer_text
                  }}</pre>
                </div>
              </div>
            </div>
          </div>
          <button
            v-if="this.Faq.length >= this.end"
            @click="this.end = this.end + 5"
            class="font-medium"
          >
            Load more >>
          </button>
          <button
            v-if="this.Faq.length <= this.end"
            @click="this.end = 5"
            class="font-medium"
          >
            Less view >>
          </button>
        </div>
      </div>

      <!-- Why Learn.Online start -->
      <div class="relative bg-[#ededee] py-10">
        <img
          src="../assets/why-learnonline-bg.webp"
          alt="banner4"
          class="hidden lg:block absolute top-0 w-full h-full object-cover"
        />
        <div
          class="h-full flex flex-col lg:flex-row justify-between max-w-screen-2xl mx-auto"
        >
          <div class="w-full lg:w-7/12 px-4 flex flex-col z-50 text-justify">
            <div class="flex flex-wrap gap-x-2">
              <span
                class="yellow_underline relative font-light text-black text-xl"
                >Why</span
              >
              <strong class="text-primary text-xl">Learn.Online?</strong>
            </div>
            <p class="mt-7 mb-3 text-sm md:text-base">
              Till now the world knew and followed the traditional ways of
              learning and working. People would first study, gain higher
              education and then start working. There was no other way. Many had
              to make tough choices between either gaining a higher education or
              earning a living for themselves and supporting their families.
            </p>
            <p class="mb-3 text-sm md:text-base">
              Learn.Online challenges that notion and opens up a whole new
              dimension for learners where they get a chance to learn and earn
              at the same time. They get the opportunity to continue with their
              higher education studies with the top universities, at affordable
              prices, from wherever they want and at their convenient pace.
            </p>
            <p class="text-sm md:text-base">
              With the onset of digital transformation, Learn.Online presents
              the best option for all those who seek higher education without
              compromising their professional life.
            </p>
          </div>
        </div>
      </div>
      <!-- Why Learn.Online end -->
      <section class="w-full bg-white pt-6 bg-white">
        <div class="w-full 2xl:w-7/12 mx-auto relative"></div>
        <div
          class="z-50 chat w-11/12 md:w-96 h-auto fixed right-2 md:right-5 lg:bottom-5 bg-white rounded-xl shadow-2xl lg:z-50 overflow-hidden border border-gray-300 bg-white"
          style="display: none; bottom: 6.5rem"
        >
          <div
            class="w-full text-white py-1 flex flex-row justify-center items-center px-4 rounded-t-md border-b bg-[#000] h-[100px]"
          >
            <img src="./../assets/logo2.png" style="width: 120px" />
            <!-- <h3 class="text-center text-2xl font-sans font-bold">LOTSY</h3> -->
            <!-- <h3 class="text-center">JOSH</h3> -->
            <!-- <button @click="minimize" -->
            <!-- class="h-6 w-6 flex flex-row items-center justify-center rounded-full shadow-xl text-3xl font-bold bg-[#ffcf2d] hover:bg-[#fff] text-[#01215c]"> -->
            <!-- - -->
            <!--  -->
            <!-- </button> -->
          </div>
          <!-- <div class="tooltipmsg hidden">Your, session will be close in 1 minute</div> -->
          <!---from here-->

          <!-- chatbot body messsage starts -->

          <div
            class="p-2 h-[60vh] overflow-y-auto chatbackroundimage"
            id="chatbox_messages"
          >
            <div class="flex flex-row items-start justify-start gap-2 pb-4">
              <img src="./../assets/lotsy.gif" alt="" width="50" />
              <div
                class="rounded-bl-xl rounded-r-xl my-1 px-3 py-1.5 bg-white border shadow-xl text-xs min-w-auto w-64 text-left"
              >
                Hi, I'm Lotsy, your virtual assistant for Learn Online. How can
                I help You? <br />
                Kindly share your mobile number with us, and we'll be happy to
                reach out and provide you with the details you're looking
                for!Come'on drop in those digits! <br />
              </div>
            </div>

            <div class="flex flex-col items-center m-2">
              <input
                type="text"
                :name="this.idinput"
                :id="`AboutLearnOnline-` + this.idinput"
                value="AboutLearnOnline"
                style="display: none"
              />
              <button
                name="button"
                @click="callchatbot(`AboutLearnOnline-` + this.idinput)"
                class="w-72 w-40 hover:bg-gradient-to-r from-[#f9c12a] via-[#000] to-[#000] text-[#000] m-2 hover:text-white px-2 border-2 py-0.5 rounded-md overflow-hidden transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-100 hover:bg-[#000] duration-300"
              >
                About Learn Online
              </button>

              <br />
              <input
                type="text"
                :name="this.idinput"
                :id="`AboutLOTS-` + this.idinput"
                value="AboutLOTS"
                style="display: none"
              />
              <button
                name="button"
                @click="callchatbot(`AboutLOTS-` + this.idinput)"
                class="w-72 w-40 hover:bg-gradient-to-r from-[#f9c12a] via-[#000] to-[#000] text-[#000] m-2 hover:text-white px-2 border-2 py-0.5 rounded-md overflow-hidden transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-100 hover:bg-[#000] duration-300"
              >
                About LOTS
              </button>

              <br />
              <input
                type="text"
                :name="this.idinput"
                :id="`Scholarship-` + this.idinput"
                value="Scholarship"
                style="display: none"
              />
              <button
                name="button"
                @click="callchatbot(`Scholarship-` + this.idinput)"
                class="w-72 w-40 hover:bg-gradient-to-r from-[#f9c12a] via-[#000] to-[#000] text-[#000] m-2 hover:text-white px-2 border-2 py-0.5 rounded-md overflow-hidden transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-100 hover:bg-[#000] duration-300"
              >
                Available Scholarships
              </button>

              <br />

              <input
                type="text"
                :name="this.idinput"
                :id="`Universities-` + this.idinput"
                value="Universities"
                style="display: none"
              />
              <button
                name="button"
                @click="callchatbot(`Universities-` + this.idinput)"
                class="w-72 w-40 hover:bg-gradient-to-r from-[#f9c12a] via-[#000] to-[#000] text-[#000] m-2 hover:text-white px-2 border-2 py-0.5 rounded-md overflow-hidden transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-100 hover:bg-[#000] duration-300"
              >
                Universities
              </button>
              <br />
              <input
                type="text"
                :name="this.idinput"
                :id="`Electives-` + this.idinput"
                value="Electives"
                style="display: none"
              />
              <button
                name="button"
                @click="callchatbot(`Electives-` + this.idinput)"
                class="w-72 w-40 hover:bg-gradient-to-r from-[#f9c12a] via-[#000] to-[#000] text-[#000] m-2 hover:text-white px-2 border-2 py-0.5 rounded-md overflow-hidden transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-100 hover:bg-[#000] duration-300"
              >
                Electives
              </button>

              <br />
              <input
                type="text"
                :name="this.idinput"
                :id="`Programs-` + this.idinput"
                value="Programs"
                style="display: none"
              />
              <button
                name="button"
                @click="callchatbot(`Programs-` + this.idinput)"
                class="w-72 w-40 hover:bg-gradient-to-r from-[#f9c12a] via-[#000] to-[#000] text-[#000] m-2 hover:text-white px-2 border-2 py-0.5 rounded-md overflow-hidden transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-100 hover:bg-[#000] duration-300"
              >
                Programs
              </button>

              

              <br />
              <input
                type="text"
                :name="this.idinput"
                :id="`Online-` + this.idinput"
                value="Online"
                style="display: none"
              />
              <button
                name="button"
                @click="callchatbot(`Online-` + this.idinput)"
                class="w-72 w-40 hover:bg-gradient-to-r from-[#f9c12a] via-[#000] to-[#000] text-[#000] m-2 hover:text-white px-2 border-2 py-0.5 rounded-md overflow-hidden transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-100 hover:bg-[#000] duration-300"
              >
                Online same as On Campus
              </button>

              
            </div>
          </div>

          <!-- chatbot body messsage end -->

          <!-- chatbot send button start -->

          <div class="flex flex-col">
            <div class="flex flex-row gap-2 px-2">
              <input
                class="w-full flex p-2 mb-2 bg-white border border-black rounded-xl"
                type="text"
                id="chatbot_input"
                name="chatbot_input"
                @keyup.enter="callchatbot('chatbot_input')"
              />
              <button
                class="w-9 p-1 mt-1 bg-[#000] h-8 bottom-[-2] rounded-md"
                type="button"
                @click="callchatbot('chatbot_input')"
              >
                <img src="./../assets/send-new.png" />
              </button>
            </div>

            <div
              class="w-full flex flex-row justify-center items-center bg-[#000] py-2"
            >
              <a
                href="https://lots.learn.online/"
                rel="noopener noreferrer"
                target="_blank"
                title="LEARN ONLINE"
                class="w-full text-center items-center text-xs font-semibold"
                style="color: #ffca2c"
              >
                LEARN ONLINE
              </a>
            </div>
          </div>

          <!-- chatbot send button end -->
        </div>

        <!-- <div class="bottom-tooltip hide">
          <div class="tooltip-content">
            <span>X</span>
            Hi, I'm JOSH
          </div>
        </div> -->
        <img
          @click="maximize"
          class="chatImg hidden-mobile fixed bottom-5 right-5 z-50 w-16 h-16 animate-bounce rounded-full p-1 bg-[#000] shadow-2xl border-2 border-[#fec52d] overflow-hidden cursor-pointer"
          aria-label="Open chat with JOSH"
          src="./../assets/lotsy.gif"
        />
        <img
          @click="minimize"
          class="closeBtn fixed bottom-10 right-5 z-50 w-12 h-12 rounded-full p-1 bg-[#fec52d] shadow-2xl border-2 border-black overflow-hidden cursor-pointer animate-bounce"
          style="display: none"
          src="./../assets/downarrow.png"
        />
        <!-- {% if 'chatbot' in request.path %} hidden {% endif %}  -->
      </section>
      <!-- Our webinars section start -->
      <div class="bg-white px-4 py-10">
        <div class="max-w-screen-2xl mx-auto hidden">
          <div class="flex flex-wrap sm:justify-center gap-x-2">
            <span
              class="yellow_underline relative font-light text-black text-xl"
              >Our</span
            >
            <strong class="text-primary text-xl">Webinars</strong>
          </div>

          <div
            class="scroll w-full flex flex-row md:justify-center gap-10 mt-10 overflow-x-auto"
          >
            <div class="relative min-w-[320px] max-w-[350px] cursor-pointer">
              <div class="w-full h-[200px] relative overflow-hidden">
                <img
                  src="../assets/news1.png"
                  alt="webinar image"
                  class="rounded-md"
                />
                <img
                  src="../assets/video-play-icon.png"
                  alt="play-icon"
                  class="w-10 h-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                />
              </div>
              <h1 class="text-center font-bold uppercase mt-5 mb-2">
                Our Webinars
              </h1>
              <p class="text-center text-gray-500">
                With the onset of digital transformation, Learn.Online presents
                the best option.
              </p>
            </div>

            <div class="relative min-w-[320px] max-w-[350px] cursor-pointer">
              <div class="w-full h-[200px] relative overflow-hidden">
                <img src="../assets/news2.png" class="rounded-md" />
                <img
                  src="../assets/video-play-icon.png"
                  class="w-10 h-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                />
              </div>
              <h1 class="text-center font-bold uppercase mt-5 mb-2">
                Our Webinars
              </h1>
              <p class="text-center text-gray-500">
                With the onset of digital transformation, Learn.Online presents
                the best option.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Our webinars section end -->

      <!-- Popup Video -->
      <div
        v-if="this.videoPopup"
        class="fixed h-full w-full bg-black bg-opacity-90 top-0 left-0 flex justify-center items-center"
        style="z-index: 5000"
      >
        <div class="max-w-5xl w-full h-full max-h-[600px] mx-auto relative">
          <div
            class="absolute -top-4 -right-8"
            @click="this.videoPopup = false"
          >
            <img src="../assets/close.png" class="h-6 w-6" />
          </div>
          <video
            src="https://api.lots.learn.online/media/videos/Lp_video.mp4"
            controls
            type="video/mp4"
            class="max-w-5xl w-full h-full max-h-[600px]"
          ></video>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  <div id="loadingSpinner" class="hidden">
    <div class="dotted_loading flex justify-center gap-1 mt-5">
      <div
        class="bg-gradient-to-r from-[#01215c] via-[#834255] to-[#fcb21f] p-1 w-2 h-2 rounded-full animate-bounce blue-circle"
      ></div>
      <div
        class="bg-gradient-to-r from-[#01215c] via-[#834255] to-[#fcb21f] p-1 w-2 h-2 rounded-full animate-bounce green-circle"
      ></div>
      <div
        class="bg-gradient-to-r from-[#01215c] via-[#834255] to-[#fcb21f] p-1 w-2 h-2 rounded-full animate-bounce red-circle"
      ></div>
    </div>
  </div>
</template>
<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/FooterView.vue";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      states: "",
      idinput: "",
      citys: "",
      universitys: "",
      qualifications: "",
      btn_load: false,
      videoPopup: false,
      messages: {
        message: "",
        type: "",
      },
      btn_load_otp: false,
      slick_var: "responsive",
      universitiesList: [],
      Faq: [],
      start: 0,
      end: 5,
    };
  },
  components: {
    Navigation,
    Footer,
  },
  props: ["slug"],
  computed: mapGetters(["userInfo", "isAuthenticated", "loginError"]),
  async created() {
    this.stateList();
    this.qualificationList();
    await this.universityList();
    this.slikCall();
    this.populate_chat_data();
    this.faqCall();
    var coll = document.getElementsByClassName("collapsible");
    var i;

    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var content = this.nextElementSibling;
        if (content.style.display === "block") {
          content.style.display = "none";
        } else {
          content.style.display = "block";
        }
      });
    }
  },

  mounted() {
    const tabsBox = document.querySelector(".tabs-box"),
      arrowIcons = document.querySelectorAll(".icon i");

    let isDragging = false;

    const handleIcons = (scrollVal) => {
      let maxScrollableWidth = tabsBox.scrollWidth - tabsBox.clientWidth;
      arrowIcons[0].parentElement.style.display =
        scrollVal <= 0 ? "none" : "flex";
      arrowIcons[1].parentElement.style.display =
        maxScrollableWidth - scrollVal <= 1 ? "none" : "flex";
    };

    arrowIcons.forEach((icon) => {
      icon.addEventListener("click", () => {
        let scrollWidth = (tabsBox.scrollLeft +=
          icon.id === "left" ? -320 : 320);
        handleIcons(scrollWidth);
      });
    });

    const dragging = (e) => {
      if (!isDragging) return;
      tabsBox.classList.add("dragging");
      tabsBox.scrollLeft -= e.movementX;
      handleIcons(tabsBox.scrollLeft);
    };

    const dragStop = () => {
      isDragging = false;
      tabsBox.classList.remove("dragging");
    };

    tabsBox.addEventListener("mousedown", () => (isDragging = true));
    tabsBox.addEventListener("mousemove", dragging);
    document.addEventListener("mouseup", dragStop);
  },
  methods: {
    ...mapActions(["register", "userDetailsGet"]),

    minimize() {
      document.getElementsByClassName("chat")[0].style.display = "none";
      document.getElementsByClassName("chatImg")[0].style.display = "block";
      document.getElementsByClassName("closeBtn")[0].style.display = "none";
    },
    maximize() {
      // closeBtn
      document.getElementsByClassName("closeBtn")[0].style.display = "block";
      document.getElementsByClassName("chat")[0].style.display = "block";
      document.getElementsByClassName("chatImg")[0].style.display = "none";
      // populate_chat_data()

      // console.log("scrolling down 2..")

      $("#chatbox_messages").animate(
        {
          scrollTop: $("#chatbox_messages")[0].scrollHeight + 10000,
        },
        2000
      );
    },

    form_Cantact(id) {
      console.log(`name-${id}`);
      name = document.getElementById(`name-${id}`).value;
      phone = document.getElementById(`phone-${id}`).value;
      email = document.getElementById(`email-${id}`).value;

      //  console.log(name, phone, email);

      // console.log(collection.exampleInput71)

      let data = {
        name: name,
        phone: phone,
        email: email,
        csrfmiddlewaretoken: "{{ csrf_token }}",
      };
      console.log(data, "dajhsdjkla");
      axios
        .post(`${process.env.VUE_APP_API}/formsubmitted/`, data)
        .then((resp) => {
          console.log(resp);
          var chatbody = document.getElementById(`chatbox_messages`);
          let node = document.createElement('div')
          node.classList.add('w-full','flex', 'justify-center');
          node.innerHTML =`<div class="flex flex-row items-start justify-start gap-2 pb-4">
                                        <img src="@/assets/lotsy.gif" alt="" width="50" class="rounded-full">
                                            <div class="rounded-bl-xl rounded-r-xl my-1 px-3 py-1.5 bg-white border shadow-xl text-xs min-w-auto w-64 text-left">
                                               ${resp.data.chat_data}
                                            </div>
                                </div>`;
          chatbody.appendChild(node)
          // chatbody.innerHTML += `<div class="flex flex-row items-start justify-start gap-2 pb-4">
          //                               <img src="@/assets/lotsy.gif" alt="" width="50" class="rounded-full">
          //                                   <div class="rounded-bl-xl rounded-r-xl my-1 px-3 py-1.5 bg-white border shadow-xl text-xs min-w-auto w-64 text-left">
          //                                      ${resp.data.chat_data}
          //                                   </div>
          //                       </div>`;
        })
        .catch((error) => {
          console.log(error);
        });

      $("#chatbox_messages").animate(
        {
          scrollTop: $("#chatbox_messages")[0].scrollHeight + 10000,
        },
        2000
      );
    },

    callchatbot(id) {
      var user_input_element = document.getElementById(id);
      var user_querry = user_input_element.value;
      user_input_element.value = "";

      var chatbody = document.getElementById(`chatbox_messages`).lastElementChild;
      let newform = new FormData();
      newform.append("fname", user_querry);
      let data = {
        csrfmiddlewaretoken: "{{ csrf_token }}",
      };
      axios
        .post(`${process.env.VUE_APP_API}/ajax_chatbot_json/`, newform, data)
        .then((resp) => {
          console.log("mytest::", resp.data.chat_data);

          //starts

          if (
            resp.data.chat_data.text !=
            "Slang Word Detected You will be blocked!!"
          ) {
            let position = user_querry.search("/");

            if (position != -1) {

              user_querry = user_querry.replace("/", "");
              console.log(user_querry);
            }
            console.log(chatbody)
            let node = document.createElement('div')
            node.classList.add('w-full','flex', 'justify-end');
            node.innerHTML =`<div class="flex flex-row items-start justify-end gap-2 pt-4 ">
                                   <div class="text-right rounded-br-full rounded-l-full px-3 py-1 bg-[#ffcf2d] border shadow-xl text-sm w-auto !max-w-7/12 place-self-end text-right gap-2 break-all">
                                   ${user_querry}
                                   </div>
                                   <i class="fas fa-user p-1 bg-white border-2 border-[#000] text-[#000] rounded-full"></i>
                            </div>`;
            chatbody.appendChild(node)

          }
          else {
            var slang_response = "*******************************";
            let node = document.createElement('div')
            node.classList.add('w-full','flex', 'justify-start');
            node.innerHTML =`<div class="flex flex-row items-start justify-end gap-2 pt-4 ">
                                      <div class="text-right rounded-br-full rounded-l-full px-3 py-1 bg-[#ffcf2d] border shadow-xl text-sm w-auto !max-w-7/12 place-self-end text-right gap-2 break-all">
                                      ${slang_response}
                                      </div>
                                      <i class="fas fa-user p-1 bg-white border-2 border-[#000] text-[#000] rounded-full"></i>
                               </div>`;
            chatbody.appendChild(node)


            let chatbot_input = document.getElementById("chatbot_input");
            console.log("chat bot input disabled,chatbot_input");
            chatbot_input.disabled = true;
          }
          //end

          if (resp.data.chat_data.text) {
            var text = resp.data.chat_data.text;
            var type_data = typeof text;
            let image = require("@/assets/lotsy.gif");
            if (type_data == "string") {
              let node = document.createElement('div')
              node.classList.add('w-full','flex', 'justify-start');
              node.innerHTML =`<div class="flex flex-row items-start justify-start gap-2 pb-4">
                                <img src="${image}" alt="" width="50" class="rounded-full">

                                <div class="rounded-bl-xl rounded-r-xl my-1 px-3 py-1.5 bg-white border shadow-xl text-xs min-w-auto w-64 text-left">
                                  ${resp.data.chat_data.text}
                                </div>
                              </div>`;
              chatbody.appendChild(node)

            } else {
              console.log("else");
              var text_data = resp.data.chat_data.text;

              text_data.forEach((element) => {
                let text_display = document.createElement('div')
                text_display.classList.add('w-full','flex', 'justify-end');
                text_display.innerHTML = `<div class="flex flex-row items-start justify-start gap-2 pb-4">
                                                              <img src="${image}" alt="" width="50" class="rounded-full">

                                                              <div class="bg-gray-50 rounded-md p-1.5 my-2 border border-green-200 text-xs md:text-sm">
                                                                 <p class="py-1 text-[#000]"><span class="font-semibold p-2">${
                                                                   Object.entries(
                                                                     element
                                                                   )[0][0]
                                                                 }:</span> ${
                  Object.entries(element)[0][1]
                } <br></p>
                                                              </div>
                                                        </div>`;
                                                        chatbody.appendChild(text_display)
              });

              
            }
          }
          if (resp.data.chat_data.urls != "None") {
            var urls_data = resp.data.chat_data.urls;

            urls_data.forEach((element) => {
              let url = document.createElement('div')
              url.innerHTML = `<a href="${
                Object.entries(element)[0][1]
              }" class="py-1 text-[#000]" target="_blank">
                                                            <button type="submit" name="button"
                                                            class=" w-72 w-40 border  hover:bg-gradient-to-r from-[#f9c12a] via-[#000] to-[#000] text-[#000] hover:text-white px-2  border-2 py-0.5 rounded-md overflow-hidden transition ease-in-out delay-100  hover:-translate-y-1 hover:scale-100 hover:bg-gradient-to-r from-[#f9c12a] via-[#000] to-[#000] duration-300">
                                                            ${
                                                              Object.entries(
                                                                element
                                                              )[0][0]
                                                            } </button>
                                                       </a>`;
                                                      //  url.classList.add('w-full','flex', 'justify-end');
                                                       chatbody.appendChild(url)
            });

            
          }

          if (resp.data.chat_data.buttons != "None") {
            var button_data = resp.data.chat_data.buttons;
            var button_buttons = " ";
            button_data.forEach((element, index) => {
              var idinput = "f_name-" + String(Math.random());
              console.log(idinput, "Id input");
              let button = document.createElement('div')
              // button.classList.add('w-full','flex', 'justify-end');
              button.innerHTML = `<input type="text" id="${
                this.idinput
              }" name="${this.idinput}" value="${
                Object.entries(element)[1][1]
              }" style="display:none">
                <button type="submit" name="button" @click="callchatbot('${
                  this.idinput
                }')"
                 class="w-full border  hover:bg-gradient-to-r from-[#f9c12a] via-[#000] to-[#000] text-[#000] hover:text-white px-2 my-2 border-2 py-0.5 rounded-md overflow-hidden transition ease-in-out delay-100  hover:-translate-y-1 hover:scale-100 hover:bg-gradient-to-r from-[#f9c12a] via-[#000] to-[#000] duration-300">
                 ${Object.entries(element)[0][1]}</button>
                `;
                chatbody.appendChild(button)
            });
            

            // node.innerHTML += button;
          }

          if (
            resp.data.chat_data.intent == "callme" ||
            resp.data.chat_data.intent == "connecttoagent"
          ) {
            var num = String(Math.random());
            // console.log("random number: ",num);
            let node = document.createElement('div')
            // node.classList.add('w-full','flex', 'justify-end');
            node.innerHTML =`<div id="callbackform" class="block p-6 m-6 rounded-lg  bg-gradient-to-r from-[#f9c12a] via-[#000] to-[#000] max-w-md flex flex-col justify-center ">
                                      <div class="form-group mb-6">
                                         <input type="text" class="form-control block w-full px-3 py-1 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-3 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" name="name"
                                                       id="name-${num}" placeholder="Name" v-if=(this.isAuthenticated) :value="this.userInfo.data.user.display_name" >
                                      </div>


                                    <div class="form-group mb-6">
                                          <input type="tel" class="form-control block  w-full px-3 py-1 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                       name="phone" id="phone-${num}" placeholder="Phone Number" v-if=(this.isAuthenticated)  :value="this.userInfo.data.user.phone">
                                    </div>

                                    <div class="form-group mb-6">
                                          <input type="email" class="form-control block  w-full px-3 py-1 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                        name="email" id="email-${num}" placeholder="Email address" v-if=(this.isAuthenticated) :value="this.userInfo.data.user.email" >
                                    </div>


                                    <button id="chatbot-form" @click="form_Cantact(${num})" class=" justify-center w-20 px-3 py-1 bg-[#fff] text-black font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-500 active:shadow-lg transition duration-150 ease-in-out">
                                                        Submit</button>

                              </div>`;
            chatbody.appendChild(node)
          }
        });

      // $("#chatbox_messages").animate(
      //   {
      //     scrollTop: $("#chatbox_messages")[0].scrollHeight + 10000,
      //   },
      //   2000);
    },

    collapse(el) {
      document.getElementById("answer_" + el).classList.toggle("hidden");
      if (
        document.getElementById("answer_" + el).classList.contains("hidden")
      ) {
        document.getElementById("arrow_" + el).style.transform = "rotate(0deg)";
      } else {
        document.getElementById("arrow_" + el).style.transform =
          "rotate(180deg)";
      }
    },

    populate_chat_data() {
      var chatbody = document.getElementById(`chatbox_messages`);
      // let csrftoken =
      //   getCookie('csrftoken');
      let data = {
        csrfmiddlewaretoken: "{{ csrf_token }}",
      };

      axios
        .get(`${process.env.VUE_APP_API}/get_chatbot_json/`, data)
        .then((resp) => {
          this.idinput = "f_name-" + String(Math.random());
          console.log(this.idinput, "populate_chat-data");
          if (resp.data.response == "None") {
                console.log("Display default");
                var display_default = "";
                var display_default1 =
                  "Kindly share your mobile number with us, and we'll be happy to reach out and provide you with the details you're looking for!Come'on drop in those digits!";
            } else {
                  for (var i = 0; i < data.response.timestamp.length; i++) {
                      var user_querry = data.response.user_request[i];
                      let position = user_querry.search("/");
                      if (position != -1) {
                        user_querry = user_querry.replace("/", "");
                          }
                      if (data.response.save != 1) {
                        let node = document.createElement('div')
                        node.innerHTML =` <div class="flex flex-row items-start justify-end gap-2 pt-4 ">
                                             <div class="text-right rounded-br-full rounded-l-full px-3 py-1 bg-[#ffcf2d] border shadow-xl text-sm w-auto !max-w-7/12 place-self-end text-right gap-2 break-all">
                                             ${user_querry}
                                             </div>
                                             <i class="fas fa-user p-1 bg-white border-2 border-[#000] text-[#000] rounded-full"></i>
                                            </div>`;
                        chatbody.appendChild(node)

                      } else {
                        var text = "  Slang Words Detected";
                        let node = document.createElement('div')
                        node.innerHTML = ` <div class="flex flex-row items-start justify-end gap-2 pt-4 ">
                                              <div class="text-right rounded-br-full rounded-l-full px-3 py-1 bg-[#ffcf2d] border shadow-xl text-sm w-auto !max-w-7/12 place-self-end text-right gap-2 break-all">
                                             ${text}
                                              </div>
                                              <i class="fas fa-user p-1 bg-white border-2 border-[#000] text-[#000] rounded-full"></i>
                                             </div>`;
                        chatbody.appendChild(node)
                      }
                      //for chatbot  in chatbot                      
                      var chatbot_response = data.response.josh_reply[i];
                      //for text
                      let image = require("@/assets/lotsy.gif");
                      if (chatbot_response.text) {
                        var text = chatbot_response.text;
                        var type_data = typeof text;

                        if (type_data == "string") {
                          let node = document.createElement('div')
                          node.innerHTML =`<div class="flex flex-row items-start justify-start gap-2 pb-4 ">
                                                      <img src="${image}" alt="" width="50" class="rounded-full">
                                                      <div class="rounded-bl-xl rounded-r-xl my-1 px-3 py-1.5 bg-white border shadow-xl text-xs min-w-auto w-64 text-left">
                                                      ${text}
                                                      </div>
                                                      </div>`;
                          chatbody.appendChild(node)
                          } else {
                            // console.log("text array is empty");
                            var text_display = " ";

                            text.forEach((element) => {
                              let text_display = document.createElement('div')

                              text_display += `<div class="flex flex-row items-start justify-start gap-2 pb-4 ">
                                                                  <img src="${image}" alt="" width="50" class="rounded-full">

                                                                  <div class="bg-gray-50 rounded-md p-1.5 my-2 border border-green-200 text-xs md:text-sm">
                                                                  <p class="py-1 text-[#110b7c] font-mono italic"><span class="font-semibold p-2">${
                                                                    Object.entries(
                                                                      element
                                                                    )[0][0]
                                                                  }:</span> ${
                                Object.entries(element)[0][1]
                              } <br></p>
                                                                  </div>
                                                                </div>`;
                                                                chatbody.appendChild(text_display)
                            });
                          }

                            //for urls

                            if (chatbot_response.urls) {
                              var urls = chatbot_response.urls;

                              var url_buttons = " ";
                              urls.forEach((element) => {
                                let url_buttons = document.createElement('div')
                                url_buttons += `<a href="${
                                  Object.entries(element)[0][1]
                                }" class="py-1 text-[#000]" target="_blank">
                                                              <button type="submit" name="button"
                                                              class=" w-72 w-40 border  hover:bg-gradient-to-r from-[#f9c12a] via-[#000] to-[#000] text-[#000] hover:text-white px-2  border-2 py-0.5 rounded-md overflow-hidden transition ease-in-out delay-100  hover:-translate-y-1 hover:scale-100 hover:bg-gradient-to-r from-[#f9c12a] via-[#000] to-[#000] duration-300">
                                                              ${
                                                                Object.entries(element)[0][0]
                                                              } </button>
                                                              </a>`;
                                                              chatbody.appendChild(url_buttons)
                              });


                              node.innerHTML += url_buttons;
                            }

              //for buttons
              if (chatbot_response.buttons) {
                var button_buttons = " ";
                var buttons = chatbot_response.buttons;
                buttons.forEach((element, index) => {
                  var idinput = "f_name-" + String(Math.random());
                  let button_buttons = document.createElement('div')
                  button_buttons += `<input type="text" id="${
                    this.idinput
                  }" name="${this.idinput}" value="${
                    Object.entries(element)[1][1]
                  }" style="display:none">
                                                         <button type="submit" name="button" @click="callchatbot('${
                                                           this.idinput
                                                         }')"
                                                         class="w-full  border  hover:bg-gradient-to-r from-[#f9c12a] via-[#000] to-[#000] text-[#000] hover:text-white px-2 my-2 border-2 py-0.5 rounded-md overflow-hidden transition ease-in-out delay-100  hover:-translate-y-1 hover:scale-100 hover:bg-gradient-to-r from-[#f9c12a] via-[#000] to-[#000] duration-300">
                                                         ${
                                                           Object.entries(
                                                             element
                                                           )[0][1]
                                                         } </button>`;
                                                         chatbody.appendChild(button_buttons)
                });

                node.innerHTML += button_buttons;
              }
                      }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });

      $("#chatbox_messages").animate(
        {
          scrollTop: $("#chatbox_messages")[0].scrollHeight + 10000,
        },
        2000
      );
    },

    faqCollapse(e) {
      var accordionCollapse = $(".accordion-collapse");
      var downArrow = $(".accordion-button img");
      var accordionItem = $(e.target).parents(".accordion-item");
      var isCollapsed = accordionItem.find(".accordion-button img");
      var dataTarget = accordionItem
        .find(".accordion-button")
        .attr("data-target");

      if (isCollapsed.hasClass("down-arrow")) {
        downArrow.addClass("down-arrow").removeClass("up-arrow");
        accordionCollapse.hide("slow");

        isCollapsed.removeClass("down-arrow").addClass("up-arrow");
        $(dataTarget).show("slow");
      } else {
        isCollapsed.addClass("down-arrow").removeClass("up-arrow");
        $(dataTarget).hide("slow");
      }
    },
    async faqCall() {
      // faqquestion/list/
      await axios
        .get(`${process.env.VUE_APP_API}/faqquestion/list/`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.Faq = resp.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async universityList() {
      await axios
        .get(`${process.env.VUE_APP_API}/student/universities_all/list/`)
        .then((resp) => {
          this.universitiesList = resp.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await axios
        .get(`${process.env.VUE_APP_API}/universities/list/`)
        .then((resp) => {
          this.universitys = resp.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async qualificationList() {
      await axios
        .get(`${process.env.VUE_APP_API}/highestqualification/list/`)
        .then((resp) => {
          this.qualifications = resp.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async stateList() {
      await axios
        .get(`${process.env.VUE_APP_API}/state/list/`)
        .then((resp) => {
          this.states = resp.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectCity(el) {
      this.citys = JSON.parse(el.target.value);
    },
    registerOtpSend() {
      this.btn_load_otp = true;
      let email = document.querySelector("#emailId").value;
      let bodyVal = {
        email: email,
        name: $("#F_Name").val(),
      };
      axios
        .post(`${process.env.VUE_APP_API}/register/otp/send/`, bodyVal)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.messages.message = "OTP sent";
            this.messages.type = "success";
          } else {
            this.messages.message = "OTP send Failed";
            this.messages.type = "error";
          }
          setTimeout(() => {
            this.messages.message = "";
            this.messages.type = "";
          }, 3000);
          this.btn_load_otp = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async registerOtpValidate(el) {
      this.btn_load = true;
      let otpVal = el.target.elements.otpval.value;
      let email = document.querySelector("#emailId").value;
      var mailValidated;
      let bodyVal = {
        user_email: email,
        entered_otp: otpVal,
      };
      await axios
        .post(`${process.env.VUE_APP_API}/register/otp/validate/`, bodyVal)
        .then((resp) => {
          mailValidated = resp.data.status;
        })
        .catch((error) => {
          console.log(error);
        });

      if (mailValidated == true) {
        let disply_name = el.target.elements.F_Name.value;
        let phone_no = el.target.elements.Phone_no.value;
        let university_val = el.target.elements.uniersityselected.value;
        let qualification_val = el.target.elements.qualification_val.value;
        let state_val = el.target.elements.state_val.value;
        let city_val = el.target.elements.city_val.value;
        let headerval = {
          display_name: disply_name,
          phone: phone_no,
          universities: university_val,
          highestqualification: parseInt(qualification_val),
          state: JSON.parse(state_val).id,
          city: parseInt(city_val),
          email: email,
          Permission_access: "student",
          referens: this.slug.length == 0 ? null : this.slug,
          qualification_status: el.target.elements.Qua_completed.checked,
        };
        await this.register(headerval);
        if (this.isAuthenticated == true) {
          await this.userDetailsGet(this.userInfo.data.token);
          this.btn_load = false;
          if (this.userInfo.data.user.Permission_access == "cp") {
            this.$router.push({ name: "cpDashboard" });
          } else if (this.userInfo.data.user.Permission_access == "tpo") {
            this.$router.push({ name: "tpoDashboard" });
          } else if (this.userInfo.data.user.Permission_access == "admin") {
            this.$router.push({ name: "adminDashboard" });
          } else if (this.userInfo.data.user.Permission_access == "ist") {
            this.$router.push({ name: "istDashboard" });
          } else if (this.userInfo.data.user.Permission_access == "student") {
            this.$router.push({ name: "DashboardView" });
          }
        } else {
          this.btn_load = false;
          if (this.loginError == "Account already exists") {
            this.messages.message = "Account already exists";
            this.messages.type = "error";
          }
        }

        // await axios
        //   .post(`${process.env.VUE_APP_API}/register/`, headerval)
        //   .then((resp) => {
        //     if (resp.data.status == 200) {
        //       this.messages.message = "Account created";
        //       this.messages.type = "success";
        //       setTimeout(() => {
        //         this.$refs.registerForm.reset();
        //         this.$router.push({
        //           name: "Login",
        //         });
        //       }, 3000);
        //     } else {
        //       this.messages.message = "Account already exists";
        //       this.messages.type = "error";
        //     }

        //     this.btn_load = false;
        //   });
        setTimeout(() => {
          this.messages.message = "";
          this.messages.type = "";
        }, 3000);
      } else {
        this.messages.message = "Incorrect OTP";
        this.messages.type = "error";
        this.btn_load = false;
        setTimeout(() => {
          this.messages.message = "";
          this.messages.type = "";
        }, 3000);
      }
    },
    slikCall() {
      $("#navBarIcon").on("click", function () {
        $("#navbarContainer").toggleClass("open");
      });

      $(`.${this.slick_var}`).slick({
        infinite: true,
        autoplay: true,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    },
  },
};
</script>
<style>
.responsive .slick-prev:before {
  content: url(../assets/slide-left.png);
}

.responsive .slick-next:before {
  content: url(../assets/slide-right.png);
}

.down-arrow {
  transition: transform 0.3s ease;
  transform: rotate(-90deg);
  width: 25px;
}

.up-arrow {
  transition: transform 0.3s ease;
  transform: rotate(90deg);
  width: 25px;
}

.icon:first-child {
  display: none;
}

.tabs-box.dragging {
  scroll-behavior: auto;
  cursor: grab;
}

.tabs-box.dragging .tab {
  user-select: none;
  pointer-events: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 1024px) {
  .refer-icon {
    display: none !important;
  }
}
.chatbackroundimage {
  background: url("@/assets/bgpattern.png") repeat center center;
}
</style>
