<template lang="">          
    <div class="w-full flex flex-wrap justify-center items-center gap-y-10">
    <div class="chartContainer relative flex flex-col h-[260px]">
		<div class="" id="chart1">
		</div>
		<div class="w-full text-center absolute bottom-[20px] left-1/2 -translate-x-1/2 font-semibold pt-5 text-sm chartTitle" id="chart1Title">
            Overall database
		</div>
	</div>
	<div class="chartContainer relative flex flex-col h-[260px]">
		<div class="" id="chart2">
		</div>
		<div class="w-full text-center absolute bottom-[20px] left-1/2 -translate-x-1/2 font-semibold pt-5 text-sm chartTitle" id="chart2Title">
            Sign up
		</div>        
	</div>
	<div class="chartContainer relative flex flex-col h-[260px]">
		<div class="" id="chart3">
		</div>
		<div class="w-full text-center absolute bottom-[20px] left-1/2 -translate-x-1/2 font-semibold pt-5 text-sm chartTitle" id="chart3Title">
            Test taken
		</div>        
	</div>
    <div class="chartContainer relative flex flex-col h-[260px]">
		<div class="" id="chart4">
		</div>
		<div class="w-full text-center absolute bottom-[20px] left-1/2 -translate-x-1/2 font-semibold pt-5 text-sm chartTitle" id="chart4Title">
            Applications
		</div>        
	</div>
    <div class="relative flex flex-col h-[260px]">
		<div class="" id="chart5">
		</div>
		<div class="w-full text-center absolute bottom-[20px] left-1/2 -translate-x-1/2 font-semibold pt-5 text-sm chartTitle" id="chart5Title">
            Enrollments
		</div>        
	</div>
	<div class="relative flex flex-col h-[260px]">
		<div class="" id="chart6">
		</div>
		<div class="w-full text-center absolute bottom-[20px] left-1/2 -translate-x-1/2 font-semibold pt-2 text-sm chartTitle" id="chart6Title">
            Brochure downloads
		</div>
	</div>
    </div>
            <div class="w-full flex fex-row gap-10 justify-between border-t py-10">
                 <div class="relative w-8/12 flex justify-center gap-10">
                    <div class="relative flex flex-col gap-8 justify-between">
                        <button class="w-[80px] bg-[#fdd406] text-black py-1.5 rounded border-none font-semibold">
                            100%
                        </button>
                         <button class="w-[80px] bg-[#60c4b8] text-black py-1.5 rounded border-none font-semibold">
                            {{ Math.round((this.chart2/this.chart1)*100) }} %  
                        </button>
                         <button class="w-[80px] bg-[#56a9de] text-black py-1.5 rounded border-none font-semibold">
                            {{ Math.round((this.chart3/this.chart1)*100) }} %
                        </button>
                         <button class="w-[80px] bg-[#f38468] text-black py-1.5 rounded border-none font-semibold">
                            {{ Math.round((this.chart4/this.chart1)*100) }} %
                        </button>
                         <button class="w-[80px] bg-[#eb607d] text-black py-1.5 rounded border-none font-semibold">
                            {{ Math.round((this.chart5/this.chart1)*100) }} %
                        </button>
                    </div>
                    <img src="../assets/funnel-pointers.png" width=""/>
                    <img src="../assets/funnelNew.png" width="300"/>
                    <img src="../assets/funnel-pointers.png" width=""/>
                    <div class="text-start relative flex flex-col justify-between">
                        <strong class="text-[#fdd406]">{{ this.chart1 }}</strong>
                        <!-- <img src="../assets/down-arrow.png" width="30" style="transform:rotate(-180deg);"/> -->
                        <strong class="text-[#d1d2d4]">{{ Math.round((this.chart2/this.chart1)*100) }} %  </strong>
                        <!-- <img src="../assets/down-arrow.png" width="30"/> -->
                         <strong class="text-[#60c4b8]">{{ this.chart2 }}</strong>
                          <strong class="text-[#d1d2d4]">{{ Math.round((this.chart3/this.chart2)*100) }} %  </strong>
                          <strong class="text-[#56a9de]">{{ this.chart3 }}</strong>
                           <strong class="text-[#d1d2d4]">{{ Math.round((this.chart4/this.chart3)*100) }} %</strong>
                          <strong class="text-[#f38468]">{{ this.chart4 }}</strong>
                           <strong class="text-[#d1d2d4]">{{ Math.round((this.chart5/this.chart4)*100) }} %</strong>
                          <strong class="text-[#eb607d]">{{ this.chart5 }}</strong>
                    </div>
                 </div>
                 <div class="relative w-4/12 hidden">
				 	<select name="" id="" class="form-select appearance-none block  py-2 text-sm font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat focus:ring-0 focus:border-primary border-[#e5e7eb] rounded shadow-md focus:outline-none w-64">
                    	<option class="text-gray-400" selected default>Source</option>
                    	<option value="" class="">Market Data</option>
                    	<option value="" class="">Channel partners</option>
                    	<option value="" class="">Outreach</option>
                    	<option value="" class="">TPO</option>
                	</select>
                 </div>
            </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ApexCharts from 'apexcharts'
import { faDumbbell } from '@fortawesome/free-solid-svg-icons';

export default {
  data() {
    return {
		Rchart1 : '',
		Rchart2 : '',
		Rchart3 : '',
		Rchart4 : '',
		Rchart5 : '',
		Rchart6 : '',
	};
  },
    props: ['chart1','chart2','chart3','chart4','chart5','chart6'],
  computed: mapGetters([]),
  created() {
  },
  mounted() {
	var options1 = {
		chart: {
			height: 260,
            width: 200,
			type: 'radialBar'
		},
		series: [this.chart1],
		colors: ["#fdd406"],
		labels: ["Progress"],
        plotOptions: {
			radialBar: {
				startAngle: -125,
				endAngle: 125,
				offsetX: 0,
				offsetY: 0,
				hollow: {
					margin: 0,
					size: '60%',
					background: 'transparent',
				},
				track: {
					show: true,
					startAngle: -125,
					endAngle: 125,
					background: '#d1d2d4',
					strokeWidth: '50%',
					opacity: 1,
					margin: 0,
				},
				dataLabels: {
					show: true,
					name: {
						show: false,
					},
					value: {
						show: true,
						fontSize: '26px',
						fontFamily: undefined,
						fontWeight: 900,
						color: '#fdd406',
						offsetY: 0,
						formatter: function (val) {
							return val
						}
					},
				}
			}
		},
		fill: {
			type: "gradient",
			gradient: {
				shade: "dark",
				type: "horizontal",
				gradientToColors: ["#fdd406"],
				stops: [0, 100]
			}
		},
	};    

    var options2 = {
		chart: {
			height: 260,
            width: 200,
			type: 'radialBar'
		},
		series: [this.chart2],
		colors: ["#60c4b8"],
		labels: ["Progress"],
        plotOptions: {
			radialBar: {
				startAngle: -125,
				endAngle: 125,
				offsetX: 0,
				offsetY: 0,
				hollow: {
					margin: 0,
					size: '60%',
					background: 'transparent',
				},
				track: {
					show: true,
					startAngle: -125,
					endAngle: 125,
					background: '#d1d2d4',
					strokeWidth: '50%',
					opacity: 1,
					margin: 0,
				},
				dataLabels: {
					show: true,
					name: {
						show: false,
					},
					value: {
						show: true,
						fontSize: '26px',
						fontFamily: undefined,
						fontWeight: 900,
						color: '#60c4b8',
						offsetY: 0,
						formatter: function (val) {
							return val
						}
					},
				}
			}
		},
		fill: {
			type: "gradient",
			gradient: {
				shade: "dark",
				type: "horizontal",
				gradientToColors: ["#60c4b8"],
				stops: [0, 100]
			}
		},
	};

     var options3 = {
		chart: {
			height: 260,
            width: 200,
			type: 'radialBar'
		},
		series: [this.chart3],
		colors: ["#56a9de"],
		labels: ["Progress"],
        plotOptions: {
			radialBar: {
				startAngle: -125,
				endAngle: 125,
				offsetX: 0,
				offsetY: 0,
				hollow: {
					margin: 0,
					size: '60%',
					background: 'transparent',
				},
				track: {
					show: true,
					startAngle: -125,
					endAngle: 125,
					background: '#d1d2d4',
					strokeWidth: '50%',
					opacity: 1,
					margin: 0,
				},
				dataLabels: {
					show: true,
					name: {
						show: false,
					},
					value: {
						show: true,
						fontSize: '26px',
						fontFamily: undefined,
						fontWeight: 900,
						color: '#56a9de',
						offsetY: 0,
						formatter: function (val) {
							return val
						}
					},
				}
			}
		},
		fill: {
			type: "gradient",
			gradient: {
				shade: "dark",
				type: "horizontal",
				gradientToColors: ["#56a9de"],
				stops: [0, 100]
			}
		},
	};

    var options4 = {
		chart: {
			height: 260,
            width: 200,
			type: 'radialBar'
		},
		series: [this.chart4],
		colors: ["#f38468"],
		labels: ["Progress"],
        plotOptions: {
			radialBar: {
				startAngle: -125,
				endAngle: 125,
				offsetX: 0,
				offsetY: 0,
				hollow: {
					margin: 0,
					size: '60%',
					background: 'transparent',
				},
				track: {
					show: true,
					startAngle: -125,
					endAngle: 125,
					background: '#d1d2d4',
					strokeWidth: '50%',
					opacity: 1,
					margin: 0,
				},
				dataLabels: {
					show: true,
					name: {
						show: false,
					},
					value: {
						show: true,
						fontSize: '26px',
						fontFamily: undefined,
						fontWeight: 900,
						color: '#f38468',
						offsetY: 0,
						formatter: function (val) {
							return val
						}
					},
				}
			}
		},
		fill: {
			type: "gradient",
			gradient: {
				shade: "dark",
				type: "horizontal",
				gradientToColors: ["#f38468"],
				stops: [0, 100]
			}
		},
	};

    var options5 = {
		chart: {
			height: 260,
            width: 200,
			type: 'radialBar'
		},
		series: [this.chart5],
		colors: ["#eb607d"],
		labels: ["Progress"],
        plotOptions: {
			radialBar: {
				startAngle: -125,
				endAngle: 125,
				offsetX: 0,
				offsetY: 0,
				hollow: {
					margin: 0,
					size: '60%',
					background: 'transparent',
				},
				track: {
					show: true,
					startAngle: -125,
					endAngle: 125,
					background: '#d1d2d4',
					strokeWidth: '50%',
					opacity: 1,
					margin: 0,
				},
				dataLabels: {
					show: true,
					name: {
						show: false,
					},
					value: {
						show: true,
						fontSize: '26px',
						fontFamily: undefined,
						fontWeight: 900,
						color: '#eb607d',
						offsetY: 0,
						formatter: function (val) {
							return val
						}
					},
				}
			}
		},
		fill: {
			type: "gradient",
			gradient: {
				shade: "dark",
				type: "horizontal",
				gradientToColors: ["#eb607d"],
				stops: [0, 100]
			}
		},
	};

	var options6 = {
		chart: {
			height: 260,
            width: 200,
			type: 'radialBar'
		},
		series: [this.chart6],
		colors: ["#eb607d"],
		labels: ["Progress"],
        plotOptions: {
			radialBar: {
				startAngle: -125,
				endAngle: 125,
				offsetX: 0,
				offsetY: 0,
				hollow: {
					margin: 0,
					size: '60%',
					background: 'transparent',
				},
				track: {
					show: true,
					startAngle: -125,
					endAngle: 125,
					background: '#d1d2d4',
					strokeWidth: '50%',
					opacity: 1,
					margin: 0,
				},
				dataLabels: {
					show: true,
					name: {
						show: false,
					},
					value: {
						show: true,
						fontSize: '26px',
						fontFamily: undefined,
						fontWeight: 900,
						color: '#eb607d',
						offsetY: 0,
						formatter: function (val) {
							return val
						}
					},
				}
			}
		},
		fill: {
			type: "gradient",
			gradient: {
				shade: "dark",
				type: "horizontal",
				gradientToColors: ["#eb607d"],
				stops: [0, 100]
			}
		},
	};


	this.Rchart1 = new ApexCharts(document.querySelector("#chart1"), options1);
	this.Rchart1.render()

    this.Rchart2 = new ApexCharts(document.querySelector("#chart2"), options2);
	this.Rchart2.render();

    this.Rchart3 = new ApexCharts(document.querySelector("#chart3"), options3);
	this.Rchart3.render();

    this.Rchart4 = new ApexCharts(document.querySelector("#chart4"), options4);
	this.Rchart4.render();

    this.Rchart5 = new ApexCharts(document.querySelector("#chart5"), options5);
	this.Rchart5.render();

	this.Rchart6 = new ApexCharts(document.querySelector("#chart6"), options6);
	this.Rchart6.render();

  },
  methods: {
    
  },
  beforeUnmount() {
			this.Rchart1.destroy()
			this.Rchart2.destroy()
			this.Rchart3.destroy()
			this.Rchart4.destroy()
			this.Rchart5.destroy()
			this.Rchart6.destroy()

  }
};
</script>

<style scoped>
.active-btn{
    border-bottom: 3px solid #fedf23;
}

.chartContainer::before{
content: "";
position: absolute;
top: 0;
right: 0;
width: 1px;
background: #CCC;
height: 75%;
}

.chartTitle::before{
content: "";
position: absolute;
top: 0;
left: 50%;
transform:translateX(-50%);
width: 75%;
background: #CCC;
height: 1px;
}
</style>
