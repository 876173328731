<template>
  <div class="max-w-[335px] w-full fixed min-h-[80px] top-24 right-4 rounded-md flex items-center justify-center" :class="[this.status=='200' ? 'bg-primary' :'bg-red-700 text-white']">
    <div class="font-semibold">{{ this.message }}</div>
  </div>
</template>

<script>
export default {
  props:{
    message:String,
    status:String
  }
};
</script>

<style></style>
