<template lang="">
           <!-- footer section start -->
        <div class="bg-[#2c2928] px-4 py-10">
            <div class="max-w-7xl mx-auto">
                <div class="flex flex-col sm:flex-row justify-between gap-10">
                    <div class="flex-1">
                        <img src="../assets/logo2.png" alt="" class="w-[100px]">
                    </div>
                    <div class="flex-1 flex flex-col gap-3 text-white text-sm text-left">
                        <h1 class="uppercase text-primary font-bold">Quick Links</h1>
                        <p class="cursor-pointer" @click="scrollPosition('about-content',$event)">About us</p>
                        <p class="cursor-pointer" @click="scrollPosition('faq-content',$event)">FAQs</p>
                        <p class="cursor-pointer" @click="scrollPosition('enquiry-content',$event)">Enquiry</p>
                    </div>
                    <div class="flex-1 flex flex-col gap-3 text-white text-sm text-left">
                        <h1 class="uppercase text-primary font-bold">Contact us</h1>
                        <a href="mailto: info@learn.online" target="_blank">info@learn.online</a>
                        <a href="tel: +91 7005987005" target="_blank">+91 7005987005</a>
                    </div>
                    <!-- <div class="hidden xl:block flex-1">
                        <div class="relative">
                            <input type="text" class="form-control block w-full pl-3 pr-10 py-2 text-sm font-normal text-gray-700 bg-white bg-clip-padding rounded focus:outline-none
                        " id="exampleFormControlInput1" placeholder="Search here" />
                            <i
                                class="fa-solid fa-magnifying-glass text-primary absolute right-[10px] top-1/2 -translate-y-1/2"></i>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <p class="bg-primary px-4 py-4 text-sm text-center text-black">&copy; 2023 Learn.Online. All Rights Reserved.</p>
        <!-- footer section end -->
</template>
<script>
export default {
    methods:{
        scrollPosition(Val,el){
        $('html, body').animate({scrollTop: $(`#${Val}`).offset().top - 85}, 1000);
    }
    }
    
}
</script>
<style lang="">
    
</style>